import { SORT_DIRECTION } from '@/components/table';
import { httpService } from '@/core/http';
import {
	CityModel,
	DIETARY_RESTRICTIONS,
	FoodRequestModel,
	FOOD_REQUEST_TYPE,
	HEALTH_BENEFITS,
	HEALTH_CONDITIONS,
	InstitutionModel,
	POSTED_BY,
	PROVIDED_BENEFITS_INFO,
	REQUEST_STATUS,
	SECONDARY_NUTRITION_RISKS,
	YES_NO_UNKNOWN,
} from '@/core/models';
import { constructUrl } from '../utils';

export enum SUPPLEMENT {
	EVERYTHING = 'EVERYTHING',
	BENEFITS_RESTRICTIONS = 'BENEFITS_RESTRICTIONS',
	STATUSES = 'STATUSES',
	CLAIM = 'CLAIM',
	CREATOR = 'CREATOR',
}

interface CreateFoodRequestRequestBody {
	foodRequestTypeId: FOOD_REQUEST_TYPE;
	unpreparedFoodTypeId: string;
	contactPhoneNumber: string;
	contactPhoneNumberSmsCapable: boolean;
	householdSize: number;
	requestDurationDays: number;
	perishableFoodStorageId: YES_NO_UNKNOWN;
	reheatFoodId: YES_NO_UNKNOWN;
	readyToEatId: YES_NO_UNKNOWN;
	urgentNeedId: YES_NO_UNKNOWN;
	dietaryRestrictionIds: DIETARY_RESTRICTIONS[];
	foodAllergyDescription: string;
	dietaryRestrictionOtherDescription: string;
	hasFlexibleDietaryRestrictions: boolean | undefined;
	dietaryRestrictionFlexibilityDescription: string;
	recipientToPickup: boolean;
	name: string;
	phoneNumber: string;
	phoneNumberSmsCapable: boolean;
	recipientPreferredSpokenLanguageIsEnglish: boolean;
	recipientPreferredSpokenLanguage: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	stateId: string;
	postalCode: string;
	notes: string;
	healthConditionIds: HEALTH_CONDITIONS[];
	healthConditionOtherDescription: string;
	secondaryNutritionalRiskIds: SECONDARY_NUTRITION_RISKS[];
	receivingBenefitId: YES_NO_UNKNOWN;
	healthBenefitIds: HEALTH_BENEFITS[];
	healthBenefitOtherDescription: string;
	providedBenefitsInfoTypeId: PROVIDED_BENEFITS_INFO | undefined;
	providedBenefitsInfoOtherDescription: string | undefined;
	recipientsUnderTwentyOne: YES_NO_UNKNOWN;
	recipientsOverSixty: YES_NO_UNKNOWN;
	householdAnnualIncome: number;
	recipientHasHealthInsurance: YES_NO_UNKNOWN;
	healthInsuranceProvider: string;
	healthSystemIds: string[];
	healthSystemOtherDescription: string;
	deliveryPickupName: string;
	deliveryPickupPhoneNumber: string;
	deliveryPickupPhoneNumberSmsCapable: boolean;
	deliveryPickupAddressLine1: string;
	deliveryPickupAddressLine2: string;
	deliveryPickupCity: string;
	deliveryPickupStateId: string;
	deliveryPickupPostalCode: string;
	deliveryPickupWindow: string;
	deliveryPickupNotes: string;
	lowIncome?: boolean;
	linkedFoodRequestId?: string | null;
}

interface UpdateFoodRequestRequestBody extends CreateFoodRequestRequestBody {}

interface CreateFoodRequestResponseBody {
	foodRequest: FoodRequestModel;
}

export interface GetFoodRequestQueryParameters {
	recipientNameQuery?: string;
	postedById?: POSTED_BY[];
	typeId?: FOOD_REQUEST_TYPE[];
	statusId?: REQUEST_STATUS[];
	forPickup?: boolean;
	cityId?: string[];
	postalCode?: string[];
	dietaryRestrictionId?: DIETARY_RESTRICTIONS[];
	healthBenefitId?: HEALTH_BENEFITS[];
	healthSystemId?: string[];
	underTwentyOne?: boolean;
	overSixty?: boolean;
	lowIncome?: boolean;
	hasInsurance?: boolean;
	page?: number;
	supplement?: SUPPLEMENT[];
	sortOrder?: SORT_DIRECTION;
}

interface GetFoodRequestsResponseBody {
	results: {
		displayStartCount: number;
		displayStartCountDescription: string;
		displayEndCount: number;
		displayEndCountDescription: string;
		totalCount: number;
		totalCountDescription: string;
		foodRequests: FoodRequestModel[];
	};
	totalRequestsCount: number;
}

interface GetFoodRequestByIdResponseBody {
	foodRequest: FoodRequestModel;
}

interface ClaimFoodRequestByIdResponseBody {
	foodRequest: FoodRequestModel;
}

interface ChangeFoodRequestStatusByIdResponseBody {
	foodRequest: FoodRequestModel;
}

export interface OpenRequestsOverview {
	unpreparedMealsRequestsCount: number;
	preparedMealsRequestsCount: number;
	deliveryOnlyRequestsCount: number;
}
export interface RequestsPostedOverview {
	openRequestsCount: number;
	requestsClaimedCount: number;
	requestsFulfilledCount: number;
}
interface GetFoodRequestsOverviewResponseBody {
	foodRequests: FoodRequestModel[];
	openRequests: OpenRequestsOverview;
	requestsPosted: RequestsPostedOverview;
}

interface GetFoodRequestFiltersResponseBody {
	filters: {
		healthSystems: InstitutionModel[];
		cities: CityModel[];
	};
}

interface GetMatchingInstitutionsResponseBody {
	institutions?: InstitutionModel[];
	deliveryInstitutions?: InstitutionModel[];
	nonDeliveryInstitutions?: InstitutionModel[];
}

export const foodRequestService = {
	createFoodRequest(data: CreateFoodRequestRequestBody) {
		return httpService.orchestrateRequest<CreateFoodRequestResponseBody>({
			method: 'POST',
			url: '/food-requests',
			data,
		});
	},
	updateFoodRequest(foodRequestId: string, data: UpdateFoodRequestRequestBody) {
		return httpService.orchestrateRequest<CreateFoodRequestResponseBody>({
			method: 'PUT',
			url: `/food-requests/${foodRequestId}`,
			data,
		});
	},
	getOpenFoodRequests(queryParameters?: GetFoodRequestQueryParameters) {
		const url = constructUrl('/food-requests/open', { ...queryParameters, supplement: [SUPPLEMENT.EVERYTHING] });

		return httpService.orchestrateRequest<GetFoodRequestsResponseBody>({
			method: 'GET',
			url,
		});
	},
	getOurFoodRequests(queryParameters?: GetFoodRequestQueryParameters) {
		const url = constructUrl('/food-requests/our-requests', {
			...queryParameters,
			supplement: [SUPPLEMENT.EVERYTHING],
		});

		return httpService.orchestrateRequest<GetFoodRequestsResponseBody>({
			method: 'GET',
			url,
		});
	},
	getFoodRequestById(foodRequestId: string) {
		return httpService.orchestrateRequest<GetFoodRequestByIdResponseBody>({
			method: 'GET',
			url: `/food-requests/${foodRequestId}`,
		});
	},
	claimFoodRequestById(foodRequestId: string) {
		return httpService.orchestrateRequest<ClaimFoodRequestByIdResponseBody>({
			method: 'POST',
			url: '/food-requests/claim',
			data: { foodRequestId },
		});
	},
	changeFoodRequestStatusById(foodRequestId: string, foodRequestStatusId: REQUEST_STATUS) {
		return httpService.orchestrateRequest<ChangeFoodRequestStatusByIdResponseBody>({
			method: 'PUT',
			url: `/food-requests/${foodRequestId}/status`,
			data: { foodRequestStatusId },
		});
	},
	getFoodRequestsOverview() {
		return httpService.orchestrateRequest<GetFoodRequestsOverviewResponseBody>({
			method: 'GET',
			url: '/food-requests/overview',
		});
	},
	getFoodRequestFilters() {
		return httpService.orchestrateRequest<GetFoodRequestFiltersResponseBody>({
			method: 'GET',
			url: '/food-requests/filters',
		});
	},
	getMatchingInstitutions(foodRequestId: string) {
		return httpService.orchestrateRequest<GetMatchingInstitutionsResponseBody>({
			method: 'GET',
			url: `/institutions/match/${foodRequestId}`,
		});
	},
	sendInstitutions(foodRequestId: string, institutionIds: string[]) {
		return httpService.orchestrateRequest({
			method: 'POST',
			url: '/institutions/match',
			data: { foodRequestId, institutionIds },
		});
	},
	declineFoodRequest(foodRequestId: string, data: { declineReason: string }) {
		return httpService.orchestrateRequest<{ foodRequest: FoodRequestModel }>({
			method: 'PUT',
			url: `/food-requests/${foodRequestId}/decline`,
			data,
		});
	},
};
