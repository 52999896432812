import React, { FC, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
	consentModal: {
		width: '90%',
		maxWidth: 700,
		margin: '0 auto',
		'& .modal-content': {
			padding: 30,
		},
	},
});

interface ConsentModalProps extends ModalProps {
	onContinue(): void;
}

export const ConsentModal: FC<ConsentModalProps> = ({ onContinue, ...props }) => {
	const classes = useStyles();
	const history = useHistory();
	const [userAgrees, setUserAgrees] = useState(false);

	return (
		<Modal dialogClassName={classes.consentModal} centered {...props}>
			<Modal.Header>
				<Modal.Title>Recipient Consent</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="mb-6">
					<i>
						Please read the following statement to the recipient and obtain their verbal consent to enter
						personal information into the FAST platform.
					</i>
				</p>
				<blockquote className="mb-6">
					<p>
						Personal information that may be needed to help connect you with food support services will be
						entered into the FAST application. The FAST application is a secure platform designed to protect
						the privacy of your information. Specific information, like your address or phone number, will
						be shared only with an organization who will use it to be sure you receive the food resources
						you need.
					</p>
					<p className="mb-0">
						Do you agree to the following statement: “I understand and consent to provide my personal
						information to the FAST platform.”
					</p>
				</blockquote>
				<p>
					<i>
						<strong>If yes</strong>, please check:
					</i>
				</p>
				<Form.Check
					className="mb-6"
					name="agree"
					id="agree-yes"
					label="The recipient confirms understanding and consents to the above."
					checked={userAgrees}
					onChange={(event) => {
						setUserAgrees(event.currentTarget.checked);
					}}
				/>
				<p>
					<i>
						<strong>If no</strong>, you may end the referral process and discuss alternatives with the food
						recipient.
					</i>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-between align-items-center">
					<Button
						variant="link"
						onClick={() => {
							history.push('/');
						}}
					>
						Cancel Request
					</Button>
					<Button size="sm" onClick={onContinue} disabled={!userAgrees}>
						Continue
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
