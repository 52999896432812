const roboto = {
	fontFamily: "'Roboto', sans-serif",
	weights: {
		regular: 400,
		bold: 700,
	},
	styles: {
		normal: 'normal',
		italic: 'italic',
	},
};

const robotoCondensed = {
	fontFamily: "'Roboto Condensed', sans-serif",
	weights: {
		regular: 400,
		bold: 700,
	},
	styles: {
		normal: 'normal',
		italic: 'italic',
	},
};

const fonts = {
	...{ roboto },
	...{ robotoCondensed },
	sizes: {
		14: '1.4rem',
		15: '1.5rem',
		16: '1.6rem',
		18: '1.8rem',
		20: '2.0rem',
		32: '3.2rem',
	},
	lineHeights: {
		18: '1.8rem',
		19: '1.9rem',
		21: '2.1rem',
		23: '2.3rem',
		24: '2.4rem',
		38: '3.8rem',
	},
};

export enum colors {
	white = '#FFFFFF',
	grayOne = '#F5F4F2',
	grayTwo = '#F7F5F2',
	grayEight = '#E8E9EB',
	grayThree = '#D6D7DA',
	grayFour = '#B9BCC3',
	grayFive = '#767B85',
	graySix = '#4A5454',
	graySeven = '#11141A',

	black = '#000000',

	blueOne = '#3782E9',
	blueTwo = '#1B6FE1',
	blueThree = '#112C6A',
	blueFour = '#062B6E',

	greenOne = '#46C07D',
	greenTwo = '#2AB066',

	yellowOne = '#FEB330',

	redOne = '#E93760',
}

export const theme = {
	...{ fonts },
	...{ colors },
};
