import Color from 'color';
import { theme } from '@/jss';

export const button = {
	'.fast-button': {
		border: 0,
		padding: '18px 35px',
		color: theme.colors.white,
		fontSize: theme.fonts.sizes[18],
		backgroundColor: theme.colors.blueTwo,
		lineHeight: theme.fonts.lineHeights[21],
		fontFamily: theme.fonts.robotoCondensed.fontFamily,
		fontWeight: theme.fonts.robotoCondensed.weights.regular,
		'&:hover': {
			backgroundColor: Color(theme.colors.blueTwo).lighten(0.16).hex(),
		},
		'&:active': {
			backgroundColor: Color(theme.colors.blueTwo).darken(0.16).hex(),
		},
		'&:disabled': {
			cursor: 'default',
			color: theme.colors.grayFive,
			backgroundColor: theme.colors.grayFour,
		},

		'&-secondary': {
			color: theme.colors.blueThree,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.colors.blueThree}`,
			'&:hover': {
				backgroundColor: Color(theme.colors.blueThree).alpha(0.16).string(),
			},
			'&:active': {
				backgroundColor: Color(theme.colors.blueThree).alpha(0.32).string(),
			},
		},

		'&-info': {
			padding: '6px 35px',
			color: theme.colors.blueTwo,
			backgroundColor: 'transparent',
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[19],
			border: `1px solid ${theme.colors.grayThree}`,
			'&:hover': {
				backgroundColor: Color(theme.colors.grayThree).alpha(0.16).string(),
			},
			'&:active': {
				backgroundColor: Color(theme.colors.grayThree).alpha(0.32).string(),
			},
		},

		'&-sm': {
			padding: '12px 20px',
		},

		'&-link': {
			padding: 0,
			color: theme.colors.blueTwo,
			backgroundColor: 'transparent',
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[19],
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
			},
			'&:active': {
				backgroundColor: 'transparent',
			},
			'&:disabled': {
				color: theme.colors.graySix,
				backgroundColor: 'transparent',
				'&:hover': {
					textDecoration: 'none',
					backgroundColor: 'transparent',
				},
			},
		},

		'&-outline-primary': {
			color: theme.colors.blueTwo,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.colors.blueTwo}`,
			'&:hover': {
				backgroundColor: Color(theme.colors.blueTwo).alpha(0.16).string(),
			},
			'&:active': {
				backgroundColor: Color(theme.colors.blueTwo).alpha(0.32).string(),
			},
		},

		'&-outline-danger': {
			color: theme.colors.redOne,
			backgroundColor: 'transparent',
			border: `1px solid ${theme.colors.redOne}`,
			'&:hover': {
				backgroundColor: Color(theme.colors.redOne).alpha(0.16).string(),
			},
			'&:active': {
				backgroundColor: Color(theme.colors.redOne).alpha(0.32).string(),
			},
		},
	},
};
