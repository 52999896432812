import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { REQUEST_STATUS, FoodRequestModel } from '@/core/models';

import { SORT_DIRECTION, TableNoMatch, TablePagination, TableRenderer } from '@/components/table';
import { Filters, RequestCard, TrackRequestsTableBodyRow, TrackRequestsTableHeaderRow } from '@/components';
import { foodRequestService, GetFoodRequestQueryParameters } from '@/core/services';
import { useAccount, useHandleError, useQuery } from '@/hooks';
import { getParsedQuery } from '@/core/utils';

import { ReactComponent as MealIcon } from '@/assets/meal.svg';

export const TrackRequests: FC = () => {
	const history = useHistory<{ statusId?: REQUEST_STATUS }>();
	const handleError = useHandleError();
	const query = useQuery();
	const { updateAccount } = useAccount();

	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [filters, setFilters] = useState<GetFoodRequestQueryParameters>({
		...getParsedQuery(query),
		...(history.location.state?.statusId ? { statusId: [history.location.state.statusId] } : {}),
	});
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION | null | undefined>(undefined);
	const [tableRowData, setTableRowData] = useState<FoodRequestModel[]>([]);
	const [pageSize] = useState(30);
	const [pageIndex, setPageIndex] = useState(0);
	const [totalEntries, setTotalEntries] = useState(0);
	const [totalMatchingEntries, setTotalMatchingEntries] = useState(0);

	useEffect(() => {
		async function fetchFoodRequests() {
			setTableIsLoading(true);

			try {
				const { results, totalRequestsCount } = await foodRequestService
					.getOurFoodRequests({
						...filters,
						page: pageIndex,
						...(sortDirection ? { sortOrder: sortDirection } : {}),
					})
					.fetch();

				setTotalEntries(totalRequestsCount);
				setTotalMatchingEntries(results.totalCount);
				setTableRowData(results.foodRequests);
			} catch (error) {
				handleError(error);
			}

			setTableIsLoading(false);
		}

		fetchFoodRequests();
	}, [filters, handleError, pageIndex, sortDirection]);

	function handleSort(_sortBy: string, sortDirection: SORT_DIRECTION) {
		setSortDirection(sortDirection);
	}

	function handleTableRowClick(rowData: FoodRequestModel) {
		history.push(`/track-requests/${rowData.foodRequestId}`);
	}

	async function handleTableRowStatusChange(rowData: FoodRequestModel, newStatus: REQUEST_STATUS) {
		setTableIsLoading(true);

		try {
			const { foodRequest } = await foodRequestService
				.changeFoodRequestStatusById(rowData.foodRequestId, newStatus)
				.fetch();
			const tableRowDataClone = tableRowData.map((rd) => {
				if (rd.foodRequestId === foodRequest.foodRequestId) {
					return foodRequest;
				}

				return rd;
			});

			setTableRowData(tableRowDataClone);

			await updateAccount();
		} catch (error) {
			handleError(error);
		}

		setTableIsLoading(false);
	}

	return (
		<Container className="pt-9 pb-9">
			<Row>
				<Col>
					<div className="mb-2">
						<h1 className="mb-0 d-inline-block mr-2">Track Our Requests</h1>
						<p className="mb-0 d-inline-block text-roboto-condensed">
							{totalEntries} total
							{Object.keys(filters).length > 0 && <span>, {totalMatchingEntries} matching filters</span>}
						</p>
					</div>
					<hr className="mb-6" />
				</Col>
			</Row>

			<Row>
				<Col lg={3}>
					<Filters showSearchByName filters={filters} onChange={setFilters} />
				</Col>
				<Col lg={9}>
					{totalEntries === 0 && Object.keys(filters).length === 0 ? (
						<Row className="pt-18 pb-18">
							<Col>
								<div className="text-center">
									<MealIcon width={90} height={90} className="mb-5" />
									<h2 className="mb-1">You don't have any requests yet</h2>
									<p className="mb-4">
										After your organization claims or posts a request for food,
										<br />
										you can track and update it here.
									</p>
									<div>
										<Link to="/browse-requests" className="mr-6">
											Browse Open Requests
										</Link>
										<Link to="/post-request/basics">Post A New Request</Link>
									</div>
								</div>
							</Col>
						</Row>
					) : totalEntries === 0 && Object.keys(filters).length > 0 ? (
						<TableNoMatch
							message="There are currently no requests that match this combination of filters."
							showButton
							onResetClick={() => {
								setFilters({});
							}}
						/>
					) : (
						<>
							<TableRenderer<FoodRequestModel>
								className="d-none d-md-block"
								isLoading={tableIsLoading}
								tableRowsData={tableRowData}
								tableHeaderRowRenderer={() => {
									return (
										<TrackRequestsTableHeaderRow
											sortDirection={sortDirection}
											onSort={handleSort}
										/>
									);
								}}
								tableBodyRowRenderer={(rowData) => {
									return (
										<TrackRequestsTableBodyRow
											key={rowData.foodRequestId}
											request={rowData}
											onClick={() => {
												handleTableRowClick(rowData);
											}}
											onStatusChange={(newStatus) => {
												handleTableRowStatusChange(rowData, newStatus);
											}}
										/>
									);
								}}
							/>

							<div className="d-block d-md-none">
								{tableRowData.map((rowData) => {
									return (
										<RequestCard
											key={rowData.foodRequestId}
											className="mb-1"
											foodRequest={rowData}
											onClick={() => {
												handleTableRowClick(rowData);
											}}
											onStatusChange={(newStatus) => {
												handleTableRowStatusChange(rowData, newStatus);
											}}
										/>
									);
								})}
							</div>

							<TablePagination
								size={pageSize}
								page={pageIndex}
								total={totalEntries}
								onClick={setPageIndex}
							/>
						</>
					)}
				</Col>
			</Row>
		</Container>
	);
};
