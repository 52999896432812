import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { FiltersList, FiltersListProps, RequestStatusDot } from '@/components';
import { mediaQueries } from '@/jss';

import { ReactComponent as FilterIcon } from '@/assets/filter-outline.svg';
import { ReactComponent as FilterIconSolid } from '@/assets/filter-solid.svg';

const useStyles = createUseStyles({
	desktopFilters: {
		[mediaQueries.lg]: {
			display: 'none',
		},
	},
	mobileFilterButton: {
		display: 'none',
		[mediaQueries.lg]: {
			display: 'block',
		},
	},
	filtersModal: {
		width: '100%',
		height: '100%',
		margin: '0 auto',
		maxWidth: '100%',
		'& .modal-content': {
			padding: 30,
			minHeight: '100%',
		},
		'& .fast-modal__header': {
			padding: '0 0 30px',
		},
	},
});

interface FiltersProps extends FiltersListProps {}

export const Filters: FC<FiltersProps> = ({ ...props }) => {
	const classes = useStyles();
	const [showMobileFilters, setShowMobileFilters] = useState(false);

	const hasFilters = Object.keys(props.filters).length > 0;

	return (
		<>
			<Modal
				dialogClassName={classes.filtersModal}
				show={showMobileFilters}
				onHide={() => {
					setShowMobileFilters(false);
				}}
			>
				<Modal.Header className="d-flex justify-content-between align-items-center">
					<Modal.Title>Filter Requests</Modal.Title>
					<Button
						variant="link"
						onClick={() => {
							setShowMobileFilters(false);
						}}
					>
						Close
					</Button>
				</Modal.Header>
				<FiltersList {...props} isMobile />
			</Modal>

			<div className="d-flex mb-6 justify-content-between align-items-center">
				<div>
					<div className="mb-1 d-flex">
						<RequestStatusDot className="mt-1" urgent />
						<p className="ml-2 mb-0 text-roboto-condensed">Urgent Request</p>
					</div>
					<div className="d-flex">
						<RequestStatusDot className="mt-1" needsSupport />
						<p className="ml-2 mb-0 text-roboto-condensed">Needs support for more than 1 week</p>
					</div>
				</div>
				<Button
					className={classes.mobileFilterButton}
					variant={hasFilters ? 'primary' : 'outline-primary'}
					onClick={() => {
						setShowMobileFilters(true);
					}}
				>
					{hasFilters ? (
						<FilterIconSolid width={20} height={20} className="mb-1" />
					) : (
						<FilterIcon width={20} height={20} className="mb-1" />
					)}
					<span className="d-flex">
						Filters
						{hasFilters && <span>&nbsp;({Object.keys(props.filters).length})</span>}
					</span>
				</Button>
			</div>

			<div className={classes.desktopFilters}>
				<FiltersList {...props} />
			</div>
		</>
	);
};
