import React, { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useAccount } from '@/hooks';
import { mediaQueries } from '@/jss';
import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

const useStyles = createUseStyles({
	subHeader: {
		padding: '24px 0',
		[mediaQueries.md]: {
			display: 'none',
		},
	},
});

export const SubHeader: FC = () => {
	const classes = useStyles();
	const { account } = useAccount();

	return (
		<header className={classes.subHeader}>
			<Container>
				<Row>
					<Col>
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<Link to="/">
									<FastLogo width={200} height={40.4} />
								</Link>
							</div>
							<nav className="d-flex">
								<p className="mb-0 mr-5">
									<Link
										to="/browse-requests"
										className={
											!!useRouteMatch('/browse-requests') ? 'text-blue-two' : 'text-blue-three'
										}
									>
										Browse Open Requests
									</Link>
								</p>
								<p className="mb-0 mr-5">
									<Link
										to="/post-request/basics"
										className={
											!!useRouteMatch('/post-request') ? 'text-blue-two' : 'text-blue-three'
										}
									>
										Post a New Request
									</Link>
								</p>
								<p className="mb-0">
									<Link
										to="/track-requests"
										className={
											!!useRouteMatch('/track-requests') ? 'text-blue-two' : 'text-blue-three'
										}
									>
										Track Our Requests
										<Badge pill variant="secondary" className="ml-1">
											{account?.institutionRequestsCount || 0}
										</Badge>
									</Link>
								</p>
							</nav>
						</div>
					</Col>
				</Row>
			</Container>
		</header>
	);
};
