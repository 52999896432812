import { httpService } from '@/core/http';
import { InstitutionModel } from '@/core/models';

interface GetInstitutionsResponseBody {
	institutions: InstitutionModel[];
}

export const institutionsService = {
	getInstitutions() {
		return httpService.orchestrateRequest<GetInstitutionsResponseBody>({
			method: 'GET',
			url: '/institutions',
		});
	},
};
