import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

import { useAlert, useQuery, useRequestForm } from '@/hooks';
import { FOOD_REQUEST_TYPE, TRANSPORTATION } from '@/core/models';

import { FadeTransition, RequiredAsterisk, StateSelectOptions } from '@/components';
import { FormButtonsRow, FormSection } from '@/components/request-form';
import { isValidPhoneNumber, isValidZipCode } from '@/core/utils';

export const PickupDelivery: FC = () => {
	const history = useHistory();
	const { showAlert } = useAlert();
	const { formValues, setFormValue, eligibilityAssessmentPageIsEnabled } = useRequestForm();

	const query = useQuery();
	const repostForDeliveryOnly = query.get('repostForDeliveryOnly') === 'true';

	const [pageIsInstantiated, setPageIsInstantiated] = useState(false);

	useEffect(() => {
		if (pageIsInstantiated) {
			return;
		}

		if (formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY) {
			setFormValue('transportationMethod', TRANSPORTATION.DELIVERY);
		}

		setPageIsInstantiated(true);
	}, [formValues.requestType, setFormValue, pageIsInstantiated]);

	return (
		<>
			<Row className="mb-10">
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<Form>
						{repostForDeliveryOnly && (
							<FormSection title="Basics">
								<Form.Group>
									<Form.Label>
										Your phone number <RequiredAsterisk />
									</Form.Label>
									<p>
										The claiming organization will use this number to contact you if more
										information is needed to fulfill the request.
									</p>
									<Form.Control
										as={Cleave}
										options={{ phone: true, phoneRegionCode: 'us' }}
										className="mb-2"
										type="tel"
										value={formValues.phoneNumber}
										onChange={(event) => {
											setFormValue('phoneNumber', event.currentTarget.value);
										}}
									/>
									<Form.Check
										name="receive-messages"
										id="receive-messages--yes"
										type="checkbox"
										label="This number can receive text messages"
										checked={formValues.phoneNumberCanReceiveMessages}
										onChange={(event) => {
											setFormValue('phoneNumberCanReceiveMessages', event.currentTarget.checked);
										}}
									/>
								</Form.Group>
							</FormSection>
						)}
						{formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY && (
							<FormSection title="Pickup Address">
								<Form.Group className="mb-6">
									<Form.Label>
										Pickup location name <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="text"
										value={formValues.pickupLocationName}
										onChange={(event) => {
											setFormValue('pickupLocationName', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										Phone number <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										as={Cleave}
										options={{ phone: true, phoneRegionCode: 'us' }}
										className="mb-2"
										type="tel"
										value={formValues.pickupLocationPhoneNumber}
										onChange={(event) => {
											setFormValue('pickupLocationPhoneNumber', event.currentTarget.value);
										}}
									/>
									<Form.Check
										name="pickup-location-phone-number-can-receive-messages"
										id="pickup-location-phone-number-can-receive-messages--yes"
										type="checkbox"
										label="This number can receive text messages"
										checked={formValues.pickupLocationPhoneNumberCanReceiveMessages}
										onChange={(event) => {
											setFormValue(
												'pickupLocationPhoneNumberCanReceiveMessages',
												event.currentTarget.checked
											);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										Street address for pickup <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="text"
										placeholder="Line 1"
										className="mb-1"
										value={formValues.pickupLocationAddressOne}
										onChange={(event) => {
											setFormValue('pickupLocationAddressOne', event.currentTarget.value);
										}}
									/>
									<Form.Control
										type="text"
										placeholder="Line 2"
										value={formValues.pickupLocationAddressTwo}
										onChange={(event) => {
											setFormValue('pickupLocationAddressTwo', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										City <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="text"
										value={formValues.pickupLocationCity}
										onChange={(event) => {
											setFormValue('pickupLocationCity', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										State <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										as="select"
										value={formValues.pickupLocationState}
										onChange={(event) => {
											setFormValue('pickupLocationState', event.currentTarget.value);
										}}
									>
										<StateSelectOptions />
									</Form.Control>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										Zip Code <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="number"
										min="0"
										value={formValues.pickupLocationZipCode}
										onChange={(event) => {
											setFormValue('pickupLocationZipCode', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>Pick up window</Form.Label>
									<Form.Control
										type="text"
										placeholder="e.g. Monday - Wednesday, 8am to 5pm"
										value={formValues.pickupLocationWindow}
										onChange={(event) => {
											setFormValue('pickupLocationWindow', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Anything the delivery driver should know?</Form.Label>
									<Form.Control
										as="textarea"
										value={formValues.pickupLocationNotes}
										onChange={(event) => {
											setFormValue('pickupLocationNotes', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FormSection>
						)}
						<FadeTransition in={formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY}>
							<FormSection title="Pickup / Delivery">
								<Form.Group>
									<Form.Label>
										Will the recipient pick the food up, or does it need to be delivered?{' '}
										<RequiredAsterisk />
									</Form.Label>
									<Form.Check
										type="radio"
										name="transportation"
										id="transportation--pickup"
										label="Pickup"
										value={TRANSPORTATION.PICKUP}
										checked={formValues.transportationMethod === TRANSPORTATION.PICKUP}
										onChange={() => {
											setFormValue('transportationMethod', TRANSPORTATION.PICKUP);
										}}
										inline
									/>
									<Form.Check
										type="radio"
										name="transportation"
										id="transportation--delivery"
										label="Delivery"
										value={TRANSPORTATION.DELIVERY}
										checked={formValues.transportationMethod === TRANSPORTATION.DELIVERY}
										onChange={() => {
											setFormValue('transportationMethod', TRANSPORTATION.DELIVERY);
										}}
										inline
									/>
								</Form.Group>
							</FormSection>
						</FadeTransition>
						<FadeTransition in={formValues.transportationMethod !== undefined && !repostForDeliveryOnly}>
							<FormSection
								isLast
								title={
									formValues.transportationMethod === TRANSPORTATION.PICKUP
										? 'Contact Information'
										: 'Delivery Address and Contact Information'
								}
								description={
									formValues.transportationMethod === TRANSPORTATION.PICKUP
										? 'The recipient’s name and phone number will only be shown to the organization that claims the request.'
										: 'The recipient’s name, phone number, and street address will only be shown to the organization that claims the request.'
								}
							>
								<Form.Group className="mb-6">
									<Form.Label>
										Recipient’s name <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="text"
										value={formValues.recipientsName}
										onChange={(event) => {
											setFormValue('recipientsName', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										Recipient’s phone number <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										as={Cleave}
										options={{ phone: true, phoneRegionCode: 'us' }}
										className="mb-2"
										type="tel"
										value={formValues.recipientsPhoneNumber}
										onChange={(event) => {
											setFormValue('recipientsPhoneNumber', event.currentTarget.value);
										}}
									/>
									<Form.Check
										name="recipients-receive-messages"
										id="recipients-receive-messages--yes"
										type="checkbox"
										label="This number can receive text messages"
										checked={formValues.recipientsPhoneNumberCanReceiveMessages}
										onChange={(event) => {
											setFormValue(
												'recipientsPhoneNumberCanReceiveMessages',
												event.currentTarget.checked
											);
										}}
									/>
								</Form.Group>
								<Form.Group className="mb-6">
									<Form.Label>
										Preferred spoken language <RequiredAsterisk />
									</Form.Label>
									<Form.Check
										name="recipient-preferred-language"
										id="recipient-preferred-language--english"
										type="radio"
										label="English"
										checked={formValues.recipientsPreferredSpokenLanguageIsEnglish === true}
										onChange={(event) => {
											setFormValue('recipientsPreferredSpokenLanguageIsEnglish', true);
										}}
										inline
									/>
									<Form.Check
										name="recipient-preferred-language"
										id="recipient-preferred-language--other"
										type="radio"
										label="Other"
										checked={formValues.recipientsPreferredSpokenLanguageIsEnglish === false}
										onChange={(event) => {
											setFormValue('recipientsPreferredSpokenLanguageIsEnglish', false);
										}}
										inline
									/>
								</Form.Group>
								<FadeTransition in={formValues.recipientsPreferredSpokenLanguageIsEnglish === false}>
									<Form.Group className="mb-6">
										<Form.Label>
											Please specify the preferred spoken language <RequiredAsterisk />
										</Form.Label>
										<Form.Control
											type="text"
											value={formValues.recipientsPreferredSpokenLanguage}
											onChange={(event) => {
												setFormValue(
													'recipientsPreferredSpokenLanguage',
													event.currentTarget.value
												);
											}}
										/>
									</Form.Group>
								</FadeTransition>
								<FadeTransition in={formValues.transportationMethod === TRANSPORTATION.DELIVERY}>
									<>
										<Form.Group className="mb-6">
											<Form.Label>
												Street address for delivery <RequiredAsterisk />
											</Form.Label>
											<Form.Control
												type="text"
												placeholder="Line 1"
												className="mb-1"
												value={formValues.recipientsAddressOne}
												onChange={(event) => {
													setFormValue('recipientsAddressOne', event.currentTarget.value);
												}}
											/>
											<Form.Control
												type="text"
												placeholder="Line 2"
												value={formValues.recipientsAddressTwo}
												onChange={(event) => {
													setFormValue('recipientsAddressTwo', event.currentTarget.value);
												}}
											/>
										</Form.Group>
										<Form.Group className="mb-6">
											<Form.Label>
												City <RequiredAsterisk />
											</Form.Label>
											<Form.Control
												type="text"
												value={formValues.recipientsCity}
												onChange={(event) => {
													setFormValue('recipientsCity', event.currentTarget.value);
												}}
											/>
										</Form.Group>
										<Form.Group className="mb-6">
											<Form.Label>
												State <RequiredAsterisk />
											</Form.Label>
											<Form.Control
												as="select"
												value={formValues.recipientsState}
												onChange={(event) => {
													setFormValue('recipientsState', event.currentTarget.value);
												}}
											>
												<StateSelectOptions />
											</Form.Control>
										</Form.Group>
									</>
								</FadeTransition>
								<Form.Group className="mb-6">
									<Form.Label>
										Zip Code <RequiredAsterisk />
									</Form.Label>
									<Form.Control
										type="number"
										min="0"
										value={formValues.recipientsZipCode}
										onChange={(event) => {
											setFormValue('recipientsZipCode', event.currentTarget.value);
										}}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Additional notes about this request</Form.Label>
									<Form.Control
										as="textarea"
										value={formValues.additionalNotes}
										onChange={(event) => {
											setFormValue('additionalNotes', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FormSection>
						</FadeTransition>
					</Form>
				</Col>
			</Row>
			<FormButtonsRow
				showLeftButton={repostForDeliveryOnly ? false : true}
				leftButtonTitle="Back"
				leftButtonOnClick={() => {
					history.push(`/post-request/basics${history.location.search}`);
				}}
				rightButtonTitle="Next"
				rightButtonOnClick={() => {
					const recipientsPhoneNumberIsValid = isValidPhoneNumber(formValues.recipientsPhoneNumber);
					const recipientsZipCodeIsValid = isValidZipCode(formValues.recipientsZipCode);
					const pickupLocationPhoneNumberIsValid = isValidPhoneNumber(formValues.pickupLocationPhoneNumber);
					const pickupLocationZipCodeIsValid = isValidZipCode(formValues.pickupLocationZipCode);

					const validations = [
						recipientsPhoneNumberIsValid,
						recipientsZipCodeIsValid,
						...(formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY
							? [pickupLocationPhoneNumberIsValid, pickupLocationZipCodeIsValid]
							: []),
					];

					if (validations.every((thing) => thing)) {
						if (repostForDeliveryOnly) {
							setFormValue('showBreadcrumbs', true);
							history.push(`/post-request/review${history.location.search}`);
						} else {
							history.push(`/post-request/eligibility-assessment${history.location.search}`);
						}
					} else {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">
										<strong>Error:</strong> Phone number or zipcode is invalid.
									</p>
								);
							},
							isDismissible: false,
						});
					}
				}}
				rightButtonDisabled={!eligibilityAssessmentPageIsEnabled}
			/>
		</>
	);
};
