import { createUseStyles } from 'react-jss';

import { theme } from '@/jss';
import { badge, button, form, modal } from '@/jss/bootstrap-styles';

const spacerSize: number = 5;
const iterations: number = 40;

const m: Record<string, Record<string, string>> = {};
const ml: Record<string, Record<string, string>> = {};
const mr: Record<string, Record<string, string>> = {};
const mt: Record<string, Record<string, string>> = {};
const mb: Record<string, Record<string, string>> = {};
const mx: Record<string, Record<string, string>> = {};
const my: Record<string, Record<string, string>> = {};

const p: Record<string, Record<string, string>> = {};
const pl: Record<string, Record<string, string>> = {};
const pr: Record<string, Record<string, string>> = {};
const pt: Record<string, Record<string, string>> = {};
const pb: Record<string, Record<string, string>> = {};
const px: Record<string, Record<string, string>> = {};
const py: Record<string, Record<string, string>> = {};

for (let i: number = 0; i <= iterations; i++) {
	m[`.m-${i}`] = { margin: `${spacerSize * i}px !important` };
	ml[`.ml-${i}`] = { marginLeft: `${spacerSize * i}px !important` };
	mr[`.mr-${i}`] = { marginRight: `${spacerSize * i}px !important` };
	mt[`.mt-${i}`] = { marginTop: `${spacerSize * i}px !important` };
	mb[`.mb-${i}`] = { marginBottom: `${spacerSize * i}px !important` };
	mx[`.mx-${i}`] = { marginLeft: `${spacerSize * i}px !important`, marginRight: `${spacerSize * i}px !important` };
	my[`.my-${i}`] = { marginTop: `${spacerSize * i}px !important`, marginBottom: `${spacerSize * i}px !important` };

	p[`.p-${i}`] = { padding: `${spacerSize * i}px !important` };
	pl[`.pl-${i}`] = { paddingLeft: `${spacerSize * i}px !important` };
	pr[`.pr-${i}`] = { paddingRight: `${spacerSize * i}px !important` };
	pt[`.pt-${i}`] = { paddingTop: `${spacerSize * i}px !important` };
	pb[`.pb-${i}`] = { paddingBottom: `${spacerSize * i}px !important` };
	px[`.px-${i}`] = { paddingLeft: `${spacerSize * i}px !important`, paddingRight: `${spacerSize * i}px !important` };
	py[`.py-${i}`] = { paddingTop: `${spacerSize * i}px !important`, paddingBottom: `${spacerSize * i}px !important` };
}

export const useCustomBootstrapStyles = createUseStyles({
	'@global': {
		'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
			'-webkit-appearance': 'none',
		},

		/* ----------------------------------------------------------- */
		/* bsPrefix overrides */
		/* ----------------------------------------------------------- */
		...badge,
		...button,
		...form,
		...modal,

		/* ----------------------------------------------------------- */
		/* Spacer overrides */
		/* ----------------------------------------------------------- */
		...m,
		...ml,
		...mr,
		...mt,
		...mb,
		...mx,
		...my,
		...p,
		...pl,
		...pr,
		...pt,
		...pb,
		...px,
		...py,

		/* ----------------------------------------------------------- */
		/* Grid overrides */
		/* ----------------------------------------------------------- */
		'.container': {
			maxWidth: 1140,
			width: '100%',
			paddingLeft: 30,
			paddingRight: 30,
		},
		'.column-count-2': {
			columnCount: 2,
		},

		/* ----------------------------------------------------------- */
		/* Misc overrides */
		/* ----------------------------------------------------------- */
		hr: {
			margin: 0,
		},

		'.text-blue-one': {
			color: theme.colors.blueOne,
		},
		'.text-blue-two': {
			color: theme.colors.blueTwo,
		},
		'.text-blue-three': {
			color: theme.colors.blueThree,
		},
		'.text-blue-four': {
			color: theme.colors.blueFour,
		},
		'.text-muted': {
			color: `${theme.colors.graySix} !important`,
		},
		'.text-success': {
			color: `${theme.colors.greenTwo} !important`,
		},
		'.text-danger': {
			color: `${theme.colors.redOne} !important`,
		},
		'.text-dark': {
			color: `${theme.colors.graySeven} !important`,
		},

		'.text-roboto': {
			fontFamily: theme.fonts.roboto.fontFamily,
		},
		'.text-roboto-condensed': {
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
		},

		'.text-underline': {
			textDecoration: 'underline',
		},

		/* ----------------------------------------------------------- */
		/* Allow modals to open on top of eachother */
		/* ----------------------------------------------------------- */
		'.modal-backdrop': {
			visibility: 'hidden !important',
		},
		'.modal.show': {
			backgroundColor: 'rgba(0,0,0,0.5)',
		},
	},
});
