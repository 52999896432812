import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { theme } from '@/jss';

const useTablePaginationStyles = createUseStyles({
	pagination: {
		display: 'flex',
		padding: '14px 0',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	buttonGroup: {
		display: 'flex',
	},
	pageButton: {
		width: 28,
		height: 28,
		margin: '0 5px',
		borderRadius: 16,
		border: '1px solid transparent',
	},
	active: {
		borderColor: theme.colors.graySeven,
		color: `${theme.colors.graySeven} !important`,
		'&:hover': {
			textDecoration: 'none',
		},
	},
});

interface TablePaginationProps {
	page: number;
	size?: number;
	total: number;
	onClick(pageIndex: number): void;
}

export const TablePagination: FC<TablePaginationProps> = React.memo(({ page, size = 20, total, onClick }) => {
	const classes = useTablePaginationStyles();
	const buttonIndices: number[] = Array.from(Array(Math.ceil(total / size)).keys());
	const lastPageIndex = buttonIndices.length - 1 >= 0 ? buttonIndices.length - 1 : 0;

	function getPageButtonsToShow() {
		let pageArray = [];

		if (page > 0 && page < lastPageIndex) {
			pageArray = [page];

			if (buttonIndices[page + 1] !== undefined) {
				pageArray.push(page + 1);
			}
			if (buttonIndices[page - 1] !== undefined) {
				pageArray.unshift(page - 1);
			}

			return pageArray;
		}

		if (page === 0) {
			pageArray = [page];

			if (buttonIndices[page + 1] !== undefined) {
				pageArray.push(page + 1);
			}
			if (buttonIndices[page + 2] !== undefined) {
				pageArray.push(page + 2);
			}

			return pageArray;
		}

		if (page === lastPageIndex) {
			pageArray = [page];

			if (buttonIndices[page - 1] !== undefined) {
				pageArray.unshift(page - 1);
			}
			if (buttonIndices[page - 2] !== undefined) {
				pageArray.unshift(page - 2);
			}

			return pageArray;
		}

		return [];
	}

	function handlePreviousButtonClick() {
		const previousPage = page - 1;

		if (previousPage < 0) {
			return;
		}

		onClick(previousPage);
	}

	function handlePaginationButtonClick(buttonIndex: number) {
		if (buttonIndex === page) {
			return;
		}

		onClick(buttonIndex);
	}

	function handleNextButtonClick() {
		const nextPage = page + 1;

		if (nextPage > lastPageIndex) {
			return;
		}

		onClick(nextPage);
	}

	return (
		<div className={classes.pagination}>
			<Button variant="link" onClick={handlePreviousButtonClick} disabled={page === 0}>
				Previous Page
			</Button>
			<div className={classes.buttonGroup}>
				{getPageButtonsToShow().map((buttonIndex) => {
					return (
						<Button
							key={buttonIndex}
							variant="link"
							className={classNames({
								[classes.pageButton]: true,
								[classes.active]: page === buttonIndex,
							})}
							onClick={() => {
								handlePaginationButtonClick(buttonIndex);
							}}
						>
							{buttonIndex + 1}
						</Button>
					);
				})}
			</div>
			<Button variant="link" onClick={handleNextButtonClick} disabled={page === lastPageIndex}>
				Next Page
			</Button>
		</div>
	);
});

TablePagination.displayName = 'TablePagination';
