import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import Cleave from 'cleave.js/react';

import { useHandleError, useRequestForm } from '@/hooks';

import { FadeTransition, Loader } from '@/components';
import { FormButtonsRow, FormSection, CheckboxList, YesNoUnsureQuestion } from '@/components/request-form';
import {
	healthBenefits,
	healthConditions,
	HEALTH_BENEFITS,
	HEALTH_CONDITIONS,
	InstitutionModel,
	INSTITUTION_TYPE_ID,
	providedBenefitsInfo,
	PROVIDED_BENEFITS_INFO,
	secondaryNutritionRisks,
	SECONDARY_NUTRITION_RISKS,
	YES_NO_UNKNOWN,
} from '@/core/models';
import { foodRequestService } from '@/core/services';
import { getPercentageOfPovertyLine } from '@/core/utils';

export const EligibilityAssessment: FC = () => {
	const handleError = useHandleError();
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);
	const [healthSystems, setHealthSystems] = useState<InstitutionModel[]>([]);
	const { formValues, setFormValue, reviewPageIsEnabled } = useRequestForm();

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);

			try {
				const response = await foodRequestService.getFoodRequestFilters().fetch();

				const healthSystemsToDisplay = cloneDeep(response.filters.healthSystems);
				healthSystemsToDisplay.push({
					institutionId: 'OTHER',
					institutionTypeId: INSTITUTION_TYPE_ID.HEALTH_SYSTEM,
					name: 'Other',
					adminName: 'N/A',
				});

				setHealthSystems(healthSystemsToDisplay);
			} catch (error) {
				handleError(error);
			}

			setIsLoading(false);
		}

		fetchData();
	}, [handleError]);

	return (
		<>
			<Row className="mb-10">
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<Form>
						<FormSection
							isLast
							title="Eligibility Assessment"
							description="Your answers below will allow organizations that serve specific populations to easily identify those recipients."
						>
							<Form.Group className="mb-6">
								<Form.Label className="mb-3">
									Does the recipient have a chronic health condition or disability?{' '}
									<p className="d-inline">Check all that apply.</p>
								</Form.Label>
								<CheckboxList
									name="health-conditions"
									options={Object.values(healthConditions).map((hc) => {
										return {
											value: hc.healthConditionId,
											title: hc.healthConditionTitle,
										};
									})}
									values={formValues.healthConditions}
									onChange={(values) => {
										setFormValue('healthConditions', values as HEALTH_CONDITIONS[]);
									}}
									hasTwoColumns
								/>
							</Form.Group>
							<FadeTransition in={formValues.healthConditions.includes(HEALTH_CONDITIONS.OTHER)}>
								<Form.Group className="mb-6">
									<Form.Label>What is the health condition?</Form.Label>
									<Form.Control
										type="text"
										value={formValues.otherHealthCondition}
										onChange={(event) => {
											setFormValue('otherHealthCondition', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<FadeTransition in={formValues.healthConditions.length > 0}>
								<Form.Group className="mb-6">
									<Form.Label className="mb-3">
										Does the recipient have any of these secondary nutritional risk factors?{' '}
										<p className="d-inline">Check all that apply.</p>
									</Form.Label>
									<CheckboxList
										name="secondary-nutritional-risks"
										options={Object.values(secondaryNutritionRisks).map((snr) => {
											return {
												value: snr.secondaryNutritionRiskId,
												title: snr.secondaryNutritionRiskTitle,
											};
										})}
										values={formValues.secondaryNutritionRisks}
										onChange={(values) => {
											setFormValue(
												'secondaryNutritionRisks',
												values as SECONDARY_NUTRITION_RISKS[]
											);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<Form.Group className="mb-6">
								<Form.Label>Is the recipient receiving benefits?</Form.Label>
								<YesNoUnsureQuestion
									name="receiving-benefits"
									value={formValues.receivingBenefits}
									onChange={(value) => {
										setFormValue('receivingBenefits', value);
									}}
								/>
							</Form.Group>
							<FadeTransition in={formValues.receivingBenefits === YES_NO_UNKNOWN.YES}>
								<Form.Group className="mb-6">
									<Form.Label className="mb-3">
										Which benefits is the recipient receving?{' '}
										<p className="d-inline">Check all that apply.</p>
									</Form.Label>
									<CheckboxList
										name="health-benefits"
										options={Object.values(healthBenefits).map((hb) => {
											return {
												value: hb.healthBenefitId,
												title: hb.healthBenefitTitle,
											};
										})}
										values={formValues.healthBenefits}
										onChange={(values) => {
											setFormValue('healthBenefits', values as HEALTH_BENEFITS[]);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<FadeTransition
								in={
									formValues.receivingBenefits === YES_NO_UNKNOWN.YES &&
									formValues.healthBenefits.includes(HEALTH_BENEFITS.OTHER)
								}
							>
								<Form.Group className="mb-6">
									<Form.Label>What is the health benefit?</Form.Label>
									<Form.Control
										type="text"
										value={formValues.otherHealthBenefit}
										onChange={(event) => {
											setFormValue('otherHealthBenefit', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<FadeTransition
								in={
									formValues.receivingBenefits === YES_NO_UNKNOWN.NO ||
									formValues.receivingBenefits === YES_NO_UNKNOWN.UNKNOWN
								}
							>
								<div className="mb-6 pt-4 pb-4 pl-5 pr-5 bg-white">
									<p className="text-success">
										<strong>This recipient may be eligible for benefits.</strong>
									</p>
									<p>To learn more about signing up, contact:</p>
									<p>Coalition Against Hunger (SNAP only): 215-430-0556</p>
									<p className="mb-4">
										BenePhilly (SNAP and other benefits): 1-844-848-4376 or 1-833-373-5868
									</p>
									<p>
										<strong>Did you provide any of these phone numbers?</strong>
									</p>
									{Object.values(providedBenefitsInfo).map((pbi) => {
										return (
											<Form.Check
												key={pbi.providedBenefitsInfoId}
												className="mb-2"
												type="radio"
												name="provided-benefits-info"
												id={`provided-benefits-info--${pbi.providedBenefitsInfoId}`}
												label={pbi.providedBenefitsInfoTitle}
												value={pbi.providedBenefitsInfoId}
												checked={
													formValues.providedBenefitsInfoTypeId === pbi.providedBenefitsInfoId
												}
												onChange={() => {
													setFormValue(
														'providedBenefitsInfoTypeId',
														pbi.providedBenefitsInfoId
													);
												}}
											/>
										);
									})}
									<FadeTransition
										in={
											(formValues.receivingBenefits === YES_NO_UNKNOWN.NO ||
												formValues.receivingBenefits === YES_NO_UNKNOWN.UNKNOWN) &&
											formValues.providedBenefitsInfoTypeId === PROVIDED_BENEFITS_INFO.OTHER
										}
									>
										<Form.Group className="mt-4">
											<Form.Label>What information did you provide about benefits?</Form.Label>
											<Form.Control
												type="text"
												value={formValues.providedBenefitsInfoOtherDescription}
												onChange={(event) => {
													setFormValue(
														'providedBenefitsInfoOtherDescription',
														event.currentTarget.value
													);
												}}
											/>
										</Form.Group>
									</FadeTransition>
								</div>
							</FadeTransition>
							<Form.Group className="mb-6">
								<Form.Label>Are any recipients under 21?</Form.Label>
								<YesNoUnsureQuestion
									name="under-21"
									value={formValues.under21}
									onChange={(value) => {
										setFormValue('under21', value);
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>Are any recipients over 60?</Form.Label>
								<YesNoUnsureQuestion
									name="over-60"
									value={formValues.over60}
									onChange={(value) => {
										setFormValue('over60', value);
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>What is the recipient’s household income?</Form.Label>
								<Form.Row className="mb-1">
									<Col>
										<p className="mb-0">Annual income</p>
										<Form.Control
											as={Cleave}
											options={{
												prefix: '$ ',
												numeral: true,
												numeralThousandsGroupStyle: 'thousand',
											}}
											type="tel"
											value={formValues.recipientsAnnualIncome}
											onChange={(event) => {
												setFormValue('recipientsAnnualIncome', event.currentTarget.value);
											}}
										/>
									</Col>
									<Col>
										<p className="mb-0">Household size</p>
										<Form.Control
											type="number"
											min="0"
											value={formValues.recipientsHouseholdSize}
											onChange={(event) => {
												setFormValue('recipientsHouseholdSize', event.currentTarget.value);
											}}
										/>
									</Col>
								</Form.Row>
								<p className="mb-0 text-muted">
									<small>
										% of poverty line:{' '}
										{getPercentageOfPovertyLine(
											parseInt(formValues.recipientsHouseholdSize, 10),
											parseFloat(formValues.recipientsAnnualIncome.replace(/[^0-9.-]+/g, ''))
										)}
									</small>
								</p>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>Does the recipient have health insurance?</Form.Label>
								<YesNoUnsureQuestion
									name="has-health-insurance"
									value={formValues.hasHealthInsurance}
									onChange={(value) => {
										setFormValue('hasHealthInsurance', value);
									}}
								/>
							</Form.Group>
							<FadeTransition in={formValues.hasHealthInsurance === YES_NO_UNKNOWN.YES}>
								<Form.Group className="mb-6">
									<Form.Label>Who is their health insurance provider?</Form.Label>
									<Form.Control
										type="text"
										value={formValues.healthInsuranceProvider}
										onChange={(event) => {
											setFormValue('healthInsuranceProvider', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<Form.Group>
								<Form.Label>
									Is the recipient a patient of any of these health systems?{' '}
									<p className="d-inline">Check all that apply.</p>
								</Form.Label>
								{isLoading ? (
									<Loader />
								) : (
									<CheckboxList
										name="health-systems"
										options={healthSystems.map((hs) => {
											return {
												value: hs.institutionId,
												title: hs.name,
											};
										})}
										values={formValues.healthSystems}
										onChange={(values) => {
											setFormValue('healthSystems', values);
										}}
										hasTwoColumns
									/>
								)}
							</Form.Group>
							<FadeTransition in={formValues.healthSystems.includes('OTHER')}>
								<Form.Group className="mt-6">
									<Form.Label>What is the health system?</Form.Label>
									<Form.Control
										type="text"
										value={formValues.otherHealthSystem}
										onChange={(event) => {
											setFormValue('otherHealthSystem', event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
						</FormSection>
					</Form>
				</Col>
			</Row>
			<FormButtonsRow
				leftButtonTitle="Back"
				leftButtonOnClick={() => {
					history.push(`/post-request/pickup-delivery${history.location.search}`);
				}}
				rightButtonTitle="Next"
				rightButtonOnClick={() => {
					history.push(`/post-request/review${history.location.search}`);
				}}
				rightButtonDisabled={!reviewPageIsEnabled}
			/>
		</>
	);
};
