import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

const useStyles = createUseStyles({
	fadeTransitionOuter: {
		overflow: 'hidden',
	},
	'@global': {
		'.opacity-height-transition-enter': {
			opacity: 0,
		},
		'.opacity-height-transition-enter-active': {
			opacity: 1,
			transition: 'opacity 100ms ease-out 100ms, margin 200ms ease-out',
		},
		'.opacity-height-transition-exit': {
			opacity: 1,
		},
		'.opacity-height-transition-exit-active': {
			opacity: 0,
			transition: 'opacity 100ms ease-out, margin 200ms ease-out',
		},
	},
});

type FadeTransitionProps = Partial<CSSTransitionProps> & {
	in?: boolean;
};

export const FadeTransition: FC<FadeTransitionProps> = ({ children, ...props }) => {
	const classes = useStyles();

	function handleEnter(node: HTMLElement) {
		node.style.marginTop = `-${node.offsetHeight}px`;
		node.style.marginBottom = `0px`;
	}

	function handleEntering(node: HTMLElement) {
		node.style.marginTop = '';
		node.style.marginBottom = '';
	}

	function handleExit(node: HTMLElement) {
		node.style.marginTop = '';
		node.style.marginBottom = '';
	}

	function handleExiting(node: HTMLElement) {
		node.style.marginTop = `-${node.offsetHeight}px`;
		node.style.marginBottom = `0px`;
	}

	return (
		<CSSTransition
			{...props}
			timeout={200}
			mountOnEnter={true}
			unmountOnExit={true}
			classNames="opacity-height-transition"
			onEnter={handleEnter}
			onEntering={handleEntering}
			onExit={handleExit}
			onExiting={handleExiting}
		>
			<div className={classes.fadeTransitionOuter}>{children}</div>
		</CSSTransition>
	);
};
