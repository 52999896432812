import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Color from 'color';

import { theme } from '@/jss';

const useTableRowStyles = createUseStyles({
	tableRow: ({ canClick }: { canClick: boolean }) => ({
		backgroundColor: theme.colors.white,
		borderBottom: `1px solid ${theme.colors.grayEight}`,
		cursor: canClick ? 'pointer' : 'auto',
		'&:hover': {
			backgroundColor: canClick ? Color(theme.colors.white).darken(0.08).hex() : theme.colors.white,
		},
	}),
});

interface TableRowProps {
	className?: string;
	tabIndex?: number;
	onClick?(event: React.MouseEvent<HTMLTableRowElement>): void;
}

export const TableRow: FC<TableRowProps> = React.memo(({ className, tabIndex, onClick, children }) => {
	const classes = useTableRowStyles({
		canClick: !!onClick,
	});

	return (
		<tr className={classNames(classes.tableRow, className)} tabIndex={tabIndex} onClick={onClick}>
			{children}
		</tr>
	);
});

TableRow.displayName = 'TableRow';
