import { SORT_DIRECTION, TableHeader, TableRow } from '@/components/table';
import React, { FC } from 'react';

interface TrackRequestsTableHeaderRowProps {
	sortDirection: SORT_DIRECTION | null | undefined;
	onSort(sortBy: string, sortDirection: SORT_DIRECTION): void;
}

export const TrackRequestsTableHeaderRow: FC<TrackRequestsTableHeaderRowProps> = ({ sortDirection, onSort }) => {
	function handleLastUpdateSort(sortDirection: SORT_DIRECTION) {
		onSort('LAST_UPDATE', sortDirection);
	}

	return (
		<TableRow>
			<TableHeader>Type</TableHeader>
			<TableHeader>Posted By</TableHeader>
			<TableHeader>Claimed By</TableHeader>
			<TableHeader sortable onSort={handleLastUpdateSort} sortDirection={sortDirection}>
				Last Update
			</TableHeader>
			<TableHeader>Status</TableHeader>
		</TableRow>
	);
};
