import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { theme } from '@/jss';

const useStyles = createUseStyles({
	tableNoMatch: {
		textAlign: 'center',
		padding: '115px 40px',
		border: `1px solid ${theme.colors.grayThree}`,
	},
});

interface TableNoMatchProps {
	message: string;
	showButton?: boolean;
	onResetClick?(): void;
	className?: string;
}

export const TableNoMatch: FC<TableNoMatchProps> = ({ message, showButton, onResetClick, className }) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.tableNoMatch, className)}>
			<p className="text-muted">{message}</p>
			{showButton && (
				<Button variant="link" onClick={onResetClick}>
					Reset all filters
				</Button>
			)}
		</div>
	);
};
