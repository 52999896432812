export enum TIME_PERIODS {
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	ALL_TIME = 'ALL_TIME',
}

export const timePeriods = {
	[TIME_PERIODS.WEEK]: {
		timePeriodId: TIME_PERIODS.WEEK,
		timePeriodDescription: 'Past week',
	},
	[TIME_PERIODS.MONTH]: {
		timePeriodId: TIME_PERIODS.MONTH,
		timePeriodDescription: 'Past 30 days',
	},
	[TIME_PERIODS.ALL_TIME]: {
		timePeriodId: TIME_PERIODS.ALL_TIME,
		timePeriodDescription: 'All time',
	},
};

export enum REPORT_TYPE_ID {
	REQUESTS = 'REQUESTS',
	USER_ENGAGEMENTS = 'USER_ENGAGEMENTS',
	INSTITUTIONS = 'INSTITUTIONS',
	REQUEST_STATUS_HISTORY = 'REQUEST_STATUS_HISTORY',
	ON_FLEET = 'ON_FLEET',
}

export interface REPORT_TYPE {
	reportTypeId: REPORT_TYPE_ID;
	reportTypeDescription: string;
	reportTypeDefinition: string;
}

export const reportTypes = {
	[REPORT_TYPE_ID.REQUESTS]: {
		reportTypeId: REPORT_TYPE_ID.REQUESTS,
		reportTypeDescription: 'Requests',
		reportTypeDefinition:
			'All food requests for the selected period, optionally limited to a specific organization.',
	},
	[REPORT_TYPE_ID.USER_ENGAGEMENTS]: {
		reportTypeId: REPORT_TYPE_ID.USER_ENGAGEMENTS,
		reportTypeDescription: 'User Engagements',
		reportTypeDefinition: 'All app logins for the selected period.',
	},
	[REPORT_TYPE_ID.INSTITUTIONS]: {
		reportTypeId: REPORT_TYPE_ID.INSTITUTIONS,
		reportTypeDescription: 'Institutions',
		reportTypeDefinition:
			'A report summarizing requests posted by, claimed by, or matched to each organization during the selected period.',
	},
	[REPORT_TYPE_ID.REQUEST_STATUS_HISTORY]: {
		reportTypeId: REPORT_TYPE_ID.REQUEST_STATUS_HISTORY,
		reportTypeDescription: 'Request Status History',
		reportTypeDefinition:
			'Detailed status history of a single request. Requires a request ID, which can be found in the Requests or Institution Requests reports.',
	},
	[REPORT_TYPE_ID.ON_FLEET]: {
		reportTypeId: REPORT_TYPE_ID.ON_FLEET,
		reportTypeDescription: 'Onfleet Import',
		reportTypeDefinition: 'A report of delivery only requests formatted for import into onfleet.',
	},
};
