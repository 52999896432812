import React, { FC, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useAccount, useHandleError } from '@/hooks';
import { AsyncPage, RequestCard } from '@/components';
import { FoodRequestModel, FOOD_REQUEST_TYPE, REQUEST_STATUS } from '@/core/models';
import { foodRequestService, OpenRequestsOverview, RequestsPostedOverview } from '@/core/services';

import { theme } from '@/jss';
import { ReactComponent as SaladIcon } from '@/assets/salad-outline.svg';
import { ReactComponent as AppleIcon } from '@/assets/apple-outline.svg';
import { ReactComponent as TruckIcon } from '@/assets/truck-outline.svg';
import { ReactComponent as GroceriesIcon } from '@/assets/groceries-gray.svg';

const useStyles = createUseStyles({
	noData: {
		textAlign: 'center',
		padding: '100px 40px',
		border: `1px solid ${theme.colors.grayThree}`,
	},
});

export const Dashboard: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const { account } = useAccount();
	const handleError = useHandleError();

	const [foodRequests, setFoodRequests] = useState<FoodRequestModel[]>([]);
	const [openRequests, setOpenRequests] = useState<OpenRequestsOverview>();
	const [requestsPosted, setRequestsPosted] = useState<RequestsPostedOverview>();

	const fetchData = useCallback(async () => {
		const response = await foodRequestService.getFoodRequestsOverview().fetch();

		setFoodRequests(response.foodRequests);
		setOpenRequests(response.openRequests);
		setRequestsPosted(response.requestsPosted);
	}, []);

	function handleRequestTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
		history.push({ pathname: '/post-request/basics', state: { requestType: event.currentTarget.value } });
	}

	async function handleStatusChange(foodRequestId: string, newStatus: REQUEST_STATUS) {
		try {
			const { foodRequest } = await foodRequestService
				.changeFoodRequestStatusById(foodRequestId, newStatus)
				.fetch();

			const foodRequestsClone = foodRequests.map((fr) => {
				if (fr.foodRequestId === foodRequestId) {
					return foodRequest;
				}

				return fr;
			});

			setFoodRequests(foodRequestsClone);
		} catch (error) {
			handleError(error);
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-6">
				<Row>
					<Col>
						<h1 className="mb-2">Dashboard</h1>
						<hr className="mb-6" />
					</Col>
				</Row>
				<Row>
					<Col md={6} className="mb-4">
						<h4>
							{account?.institution.name} Recent {foodRequests.length === 1 ? 'Request' : 'Requests'}
						</h4>
						{foodRequests.length > 0 ? (
							<>
								{foodRequests.map((foodRequest) => {
									return (
										<RequestCard
											key={foodRequest.foodRequestId}
											className="mb-1"
											foodRequest={foodRequest}
											onClick={() => {
												history.push(`/track-requests/${foodRequest.foodRequestId}`);
											}}
											onStatusChange={(newStatus) => {
												handleStatusChange(foodRequest.foodRequestId, newStatus);
											}}
										/>
									);
								})}
								<Button
									variant="info"
									className="w-100"
									onClick={() => {
										history.push({
											pathname: '/track-requests',
											state: { statusId: REQUEST_STATUS.CLAIMED },
										});
									}}
								>
									See all requests
								</Button>
							</>
						) : (
							<div className={classes.noData}>
								<GroceriesIcon width={90} height={90} className="mb-5" />
								<p className="text-muted">
									When you begin posting or claiming requests, you'll have quick access to your most
									recent ones here.
								</p>
							</div>
						)}
					</Col>
					<Col md={6}>
						<Row className="mb-5">
							<Col>
								<h4>Post a Request</h4>
								<div className="d-md-flex">
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1 mb-1"
										id="request-type--prepared-meals"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.PREPARED_MEALS}
											onChange={handleRequestTypeChange}
										/>
										<Form.Check.Label title="">
											<SaladIcon width={45} height={36} className="mb-2" />
											<div>Prepared meals</div>
											<small>(plus the option for delivery)</small>
										</Form.Check.Label>
									</Form.Check>
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1 mb-1"
										id="request-type--unprepared-food"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.UNPREPARED_FOOD}
											onChange={handleRequestTypeChange}
										/>
										<Form.Check.Label title="">
											<AppleIcon width={45} height={36} className="mb-2" />
											<div>Unprepared food</div>
											<small>(plus the option for delivery)</small>
										</Form.Check.Label>
									</Form.Check>
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1"
										id="request-type--just-needs-delivery"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.DELIVERY_ONLY}
											onChange={handleRequestTypeChange}
										/>
										<Form.Check.Label title="">
											<TruckIcon width={45} height={36} className="mb-2" />
											<div>Just need delivery</div>
											<small>(you don't need food)</small>
										</Form.Check.Label>
									</Form.Check>
								</div>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col>
								<div className="mb-1 d-flex justify-content-between">
									<h4 className="mb-0">Requests Posted by {account?.institution.name}</h4>
									<p className="mb-0">
										<Link to="/track-requests">Track All</Link>
									</p>
								</div>
								<hr className="mb-2" />
								<p>{requestsPosted?.openRequestsCount} requests are open</p>
								<p>{requestsPosted?.requestsClaimedCount} requests has been claimed</p>
								<p>{requestsPosted?.requestsFulfilledCount} requests fulfilled to date</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="mb-1 d-flex justify-content-between">
									<h4 className="mb-0">All Open Requests</h4>
									<p className="mb-0">
										<Link to="/browse-requests">Browse All</Link>
									</p>
								</div>
								<hr className="mb-2" />
								<div className="mb-2 d-flex justify-content-between">
									<p className="mb-0">
										{openRequests?.unpreparedMealsRequestsCount} requests for unprepared meals
									</p>
									<p className="mb-0">
										<Link
											to={{
												pathname: '/browse-requests',
												state: { typeId: FOOD_REQUEST_TYPE.UNPREPARED_FOOD },
											}}
										>
											Browse
										</Link>
									</p>
								</div>
								<div className="mb-2 d-flex justify-content-between">
									<p className="mb-0">
										{openRequests?.preparedMealsRequestsCount} requests for prepared meals
									</p>
									<p className="mb-0">
										<Link
											to={{
												pathname: '/browse-requests',
												state: { typeId: FOOD_REQUEST_TYPE.PREPARED_MEALS },
											}}
										>
											Browse
										</Link>
									</p>
								</div>
								<div className="mb-2 d-flex justify-content-between">
									<p className="mb-0">
										{openRequests?.deliveryOnlyRequestsCount} requests are delivery-only
									</p>
									<p className="mb-0">
										<Link
											to={{
												pathname: '/browse-requests',
												state: { typeId: FOOD_REQUEST_TYPE.DELIVERY_ONLY },
											}}
										>
											Browse
										</Link>
									</p>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
