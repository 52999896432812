import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useHandleError } from '@/hooks';
import { AsyncPage } from '@/components';
import { FormButtonsRow, FormSection } from '@/components/request-form';
import { foodRequestService } from '@/core/services';
import { ClaimedByModel, InstitutionModel } from '@/core/models';
import { theme } from '@/jss';

const useStyles = createUseStyles({
	noData: {
		textAlign: 'center',
		padding: '60px 40px',
		border: `1px solid ${theme.colors.grayThree}`,
	},
});

interface OrganizationModel extends InstitutionModel {
	checked: boolean;
}

function OrganizationCheckBox({
	organization,
	onChange,
}: {
	organization: OrganizationModel;
	onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}) {
	return (
		<div key={organization.institutionId} className="mb-1 pt-3 pb-3 pl-3 pr-3 bg-white">
			<Form.Check
				type="checkbox"
				name="organization"
				id={`organization--${organization.institutionId}`}
				label={organization.name}
				value={organization.institutionId}
				checked={organization.checked}
				onChange={onChange}
			/>
		</div>
	);
}

export const Send: FC = () => {
	const classes = useStyles();
	const handleError = useHandleError();
	const history = useHistory<{
		foodRequestId?: string;
		isEdit?: boolean;
		isRepost?: boolean;
		claimedBy?: ClaimedByModel;
	}>();
	const [organizations, setOrganizations] = useState<OrganizationModel[]>([]);
	const [deliveryOrganizations, setDeliveryOrganizations] = useState<OrganizationModel[]>([]);
	const [nonDeliveryOrganizations, setNonDeliveryOrganizations] = useState<OrganizationModel[]>([]);
	const [isSending, setIsSending] = useState(false);

	const fetchData = useCallback(async () => {
		if (!history.location.state?.foodRequestId) {
			history.replace('/post-request/basics');
			return;
		}

		// Don't need to make any calls to get matching institutions if the request is already claimed
		if (history.location.state?.claimedBy) {
			return;
		}

		const {
			institutions,
			deliveryInstitutions,
			nonDeliveryInstitutions,
		} = await foodRequestService.getMatchingInstitutions(history.location.state.foodRequestId).fetch();

		let institutionsWithCheckedProperty: OrganizationModel[] = [];
		let deliveryInstitutionsWithCheckedProperty: OrganizationModel[] = [];
		let nonDeliveryInstitutionsWithCheckedProperty: OrganizationModel[] = [];

		if (institutions) {
			institutionsWithCheckedProperty = institutions.map((institution) => {
				return {
					...institution,
					checked: true,
				};
			});
		}

		if (deliveryInstitutions) {
			deliveryInstitutionsWithCheckedProperty = deliveryInstitutions.map((institution) => {
				return {
					...institution,
					checked: true,
				};
			});
		}

		if (nonDeliveryInstitutions) {
			nonDeliveryInstitutionsWithCheckedProperty = nonDeliveryInstitutions.map((institution) => {
				return {
					...institution,
					checked: true,
				};
			});
		}

		setOrganizations(institutionsWithCheckedProperty);
		setDeliveryOrganizations(deliveryInstitutionsWithCheckedProperty);
		setNonDeliveryOrganizations(nonDeliveryInstitutionsWithCheckedProperty);
	}, [history]);

	async function handleSendAndContinueButtonClick() {
		try {
			setIsSending(true);

			if (!history.location.state?.foodRequestId) {
				throw new Error('No foodRequestId found.');
			}

			const institutionIds = organizations
				.filter((organization) => organization.checked)
				.map((organization) => organization.institutionId);

			const deliveryIds = deliveryOrganizations
				.filter((organization) => organization.checked)
				.map((organization) => organization.institutionId);

			const nonDeliveryIds = nonDeliveryOrganizations
				.filter((organization) => organization.checked)
				.map((organization) => organization.institutionId);

			const allIds = [...institutionIds, ...deliveryIds, ...nonDeliveryIds];

			await foodRequestService.sendInstitutions(history.location.state?.foodRequestId, allIds).fetch();

			if (history.location.state?.isEdit) {
				history.push(`/track-requests/${history.location.state?.foodRequestId}?editSuccess=true`);
			} else {
				history.push(`/track-requests/${history.location.state?.foodRequestId}?postSuccess=true`);
			}
		} catch (error) {
			setIsSending(false);
			handleError(error);
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			{history.location.state?.claimedBy ? (
				<>
					<Row className="mb-10">
						<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
							<FormSection
								title={`Your request will be fulfilled by ${history.location.state?.claimedBy.account.institution.name}`}
								description={`Your request has been assigned to ${history.location.state?.claimedBy.account.institution.name}. You will recieve a notification when the request is fulfilled.`}
								isLast
							/>
						</Col>
					</Row>
					<FormButtonsRow
						showLeftButton={false}
						rightButtonTitle="Finish"
						rightButtonOnClick={() => {
							if (history.location.state?.isEdit) {
								history.push(
									`/track-requests/${history.location.state?.foodRequestId}?editSuccess=true`
								);
							} else {
								history.push(
									`/track-requests/${history.location.state?.foodRequestId}?postSuccess=true`
								);
							}
						}}
					/>
				</>
			) : (
				<>
					<Row className="mb-10">
						<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
							<Form>
								<FormSection
									title="Send Your Request"
									description="These organizations’ capabilities match the details of this request. Would you like to notify them about it?"
									isLast
								>
									{organizations.length > 0 && (
										<div>
											<Form.Group className="mb-6">
												{organizations.map((organization) => {
													return (
														<OrganizationCheckBox
															key={organization.institutionId}
															organization={organization}
															onChange={(event) => {
																const organizationsClone = organizations.map(
																	(organization) => {
																		if (
																			organization.institutionId ===
																			event.currentTarget.value
																		) {
																			organization.checked =
																				event.currentTarget.checked;
																			return organization;
																		}

																		return organization;
																	}
																);

																setOrganizations(organizationsClone);
															}}
														/>
													);
												})}
											</Form.Group>
											<div>
												<Button
													variant="link"
													className="mr-5"
													onClick={() => {
														const organizationsClone = organizations.map((organization) => {
															organization.checked = true;
															return organization;
														});

														setOrganizations(organizationsClone);
													}}
												>
													Select All
												</Button>
												<Button
													variant="link"
													onClick={() => {
														const organizationsClone = organizations.map((organization) => {
															organization.checked = false;
															return organization;
														});

														setOrganizations(organizationsClone);
													}}
												>
													Deselect All
												</Button>
											</div>
										</div>
									)}
									{deliveryOrganizations.length > 0 && (
										<div className="mb-9">
											<h5>Organizations that can do their own deliveries</h5>
											<Form.Group className="mb-6">
												{deliveryOrganizations.map((organization) => {
													return (
														<OrganizationCheckBox
															key={organization.institutionId}
															organization={organization}
															onChange={(event) => {
																const organizationsClone = deliveryOrganizations.map(
																	(organization) => {
																		if (
																			organization.institutionId ===
																			event.currentTarget.value
																		) {
																			organization.checked =
																				event.currentTarget.checked;
																			return organization;
																		}

																		return organization;
																	}
																);

																setDeliveryOrganizations(organizationsClone);
															}}
														/>
													);
												})}
											</Form.Group>
											<div>
												<Button
													variant="link"
													className="mr-5"
													onClick={() => {
														const organizationsClone = deliveryOrganizations.map(
															(organization) => {
																organization.checked = true;
																return organization;
															}
														);

														setDeliveryOrganizations(organizationsClone);
													}}
												>
													Select All
												</Button>
												<Button
													variant="link"
													onClick={() => {
														const organizationsClone = deliveryOrganizations.map(
															(organization) => {
																organization.checked = false;
																return organization;
															}
														);

														setDeliveryOrganizations(organizationsClone);
													}}
												>
													Deselect All
												</Button>
											</div>
										</div>
									)}
									{nonDeliveryOrganizations.length > 0 && (
										<div className="mb-9">
											<h5>Food partners that will coordinate with delivery partners</h5>
											<Form.Group className="mb-6">
												{nonDeliveryOrganizations.map((organization) => {
													return (
														<OrganizationCheckBox
															key={organization.institutionId}
															organization={organization}
															onChange={(event) => {
																const organizationsClone = nonDeliveryOrganizations.map(
																	(organization) => {
																		if (
																			organization.institutionId ===
																			event.currentTarget.value
																		) {
																			organization.checked =
																				event.currentTarget.checked;
																			return organization;
																		}

																		return organization;
																	}
																);

																setNonDeliveryOrganizations(organizationsClone);
															}}
														/>
													);
												})}
											</Form.Group>
											<div>
												<Button
													variant="link"
													className="mr-5"
													onClick={() => {
														const organizationsClone = nonDeliveryOrganizations.map(
															(organization) => {
																organization.checked = true;
																return organization;
															}
														);

														setNonDeliveryOrganizations(organizationsClone);
													}}
												>
													Select All
												</Button>
												<Button
													variant="link"
													onClick={() => {
														const organizationsClone = nonDeliveryOrganizations.map(
															(organization) => {
																organization.checked = false;
																return organization;
															}
														);

														setNonDeliveryOrganizations(organizationsClone);
													}}
												>
													Deselect All
												</Button>
											</div>
										</div>
									)}
									{organizations.length <= 0 &&
										deliveryOrganizations.length <= 0 &&
										nonDeliveryOrganizations.length <= 0 && (
											<div className={classes.noData}>
												<p>
													<strong>Sorry, we couldn't match your request</strong>
												</p>
												<p className="text-muted">
													The details of your request did not match any specific organizations
													on FAST. It can still be picked up by interested organizations on
													the Browse Open Requests page.
												</p>
											</div>
										)}
								</FormSection>
							</Form>
						</Col>
					</Row>
					<FormButtonsRow
						showLeftButton={false}
						rightButtonTitle={
							organizations.filter((organization) => organization.checked).length > 0
								? 'Send Notification'
								: 'Continue'
						}
						rightButtonOnClick={handleSendAndContinueButtonClick}
						rightButtonIsLoading={isSending}
					/>
				</>
			)}
		</AsyncPage>
	);
};
