import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { theme } from '@/jss';
import { ReactComponent as SortNoneIcon } from '@/assets/sort-none.svg';
import { ReactComponent as SortDownIcon } from '@/assets/sort-down.svg';
import { ReactComponent as SortUpIcon } from '@/assets/sort-up.svg';

export enum SORT_DIRECTION {
	ASC = 'ASC',
	DESC = 'DESC',
}

const useTableHeaderStyles = createUseStyles({
	tableHeader: (props: any) => ({
		padding: 0,
		width: props.width ? props.width : 'auto',
		'&.fixed-column': {
			zIndex: 1,
			left: props.fixedOffset ? props.fixedOffset : 0,
		},
		'&:first-child': {
			paddingLeft: 8,
		},
		'&:last-child': {
			paddingRight: 8,
		},
	}),
	tableHeaderContent: (props: any) => ({
		height: 30,
		display: 'flex',
		padding: '0 8px 10px',
		overflow: 'hidden',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		justifyContent: 'flex-start',
		fontSize: theme.fonts.sizes[16],
		lineHeight: theme.fonts.lineHeights[19],
		fontFamily: theme.fonts.robotoCondensed.fontFamily,
		fontWeight: theme.fonts.robotoCondensed.weights.bold,
		width: typeof props.width === 'string' ? 'auto' : props.width,
		...(props.sortable ? { cursor: 'pointer' } : {}),
	}),
});

interface TableHeaderProps {
	width?: number | string;
	fixed?: boolean;
	fixedOffset?: number;
	sortable?: boolean;
	sortDirection?: SORT_DIRECTION | null;
	onSort?(sortDirection: SORT_DIRECTION): void;
	colspan?: number;
	className?: string;
}

export const TableHeader: FC<TableHeaderProps> = React.memo((props) => {
	const classes = useTableHeaderStyles({
		width: props.width,
		fixedOffset: props.fixedOffset,
		sortable: props.sortable,
	});

	function handleSortButtonClick() {
		if (!props.onSort) {
			return;
		}

		if (props.sortDirection === SORT_DIRECTION.ASC) {
			props.onSort(SORT_DIRECTION.DESC);
		} else {
			props.onSort(SORT_DIRECTION.ASC);
		}
	}

	return (
		<th
			className={classNames({
				[classes.tableHeader]: true,
				'fixed-column': props.fixed,
			})}
			colSpan={props.colspan}
		>
			<div
				className={classNames(classes.tableHeaderContent, props.className)}
				onClick={() => {
					if (!props.sortable) {
						return;
					}

					handleSortButtonClick();
				}}
				tabIndex={props.sortable ? 0 : -1}
			>
				{props.children}
				{props.sortable && (
					<>
						{!props.sortDirection && <SortNoneIcon className="ml-2" />}
						{props.sortDirection === SORT_DIRECTION.ASC && <SortDownIcon className="ml-2" />}
						{props.sortDirection === SORT_DIRECTION.DESC && <SortUpIcon className="ml-2" />}
					</>
				)}
			</div>
		</th>
	);
});

TableHeader.displayName = 'TableHeader';
