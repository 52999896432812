import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

import { TableCell, TableRow } from '@/components/table';

interface InvitationTableBodyRowProps {
	emailAddress: string;
	dateInvited: string;
	status: string;
	onRevoke(): void;
}

export const InvitationTableBodyRow: FC<InvitationTableBodyRowProps> = ({
	emailAddress,
	dateInvited,
	status,
	onRevoke,
}) => {
	return (
		<TableRow>
			<TableCell>
				<p className="mb-0 text-roboto-condensed">{emailAddress}</p>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{dateInvited}</small>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{status}</small>
			</TableCell>
			<TableCell>
				<Button variant="link" className="text-danger" onClick={onRevoke}>
					Revoke
				</Button>
			</TableCell>
		</TableRow>
	);
};
