import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useTableHeadStyles = createUseStyles({
	tableHead: {
		'& tr': {
			border: 0,
			backgroundColor: 'transparent !important',
		},
	},
});

export const TableHead: FC = React.memo((props) => {
	const classes = useTableHeadStyles();

	return <thead className={classes.tableHead}>{props.children}</thead>;
});

TableHead.displayName = 'TableHead';
