import { httpService } from '@/core/http';
import { AccountModel, InvitationModel } from '@/core/models';

interface GetAccessTokenRequestBody {
	emailAddress: string;
	password: string;
}

interface GetAccessTokenResponseBody {
	accessToken: string;
	account: AccountModel;
}

interface GetAccountsResponseBody {
	accounts: AccountModel[];
}

interface GetAccountResponseBody {
	account: AccountModel;
}

interface DeleteAccountByIdResponseBody {
	accounts: AccountModel[];
}

interface GetAccountInvitesResponseBody {
	invitations: InvitationModel[];
}

interface getAccountInviteByIdResponseBody {
	invitation: InvitationModel;
}

interface SendAccountInvitesResponseBody {
	invitations: InvitationModel[];
}

interface RevokeAccountInviteResponseBody {
	invitations: InvitationModel[];
}

interface CreateAccountRequestBody {
	invitationId: string;
	name: string;
	password: string;
	confirmPassword: string;
}

interface CreateAccountResponseBody {
	accessToken: string;
	account: AccountModel;
}

interface UpdateAccountRequestBody {
	name?: string;
	emailAddress?: string;
	phoneNumber?: string;
	organizationName?: string;
	currentPassword?: string;
	newPassword?: string;
	confirmNewPassword?: string;
	notificationTypeId?: string;
}

interface UpdateAccountResponseBody {
	account: AccountModel;
}

export const accountService = {
	getAccessToken(data: GetAccessTokenRequestBody) {
		return httpService.orchestrateRequest<GetAccessTokenResponseBody>({
			method: 'POST',
			url: '/accounts/access-token',
			data,
		});
	},
	getAccounts() {
		return httpService.orchestrateRequest<GetAccountsResponseBody>({
			method: 'GET',
			url: `/accounts/`,
		});
	},
	getAccount(accountId: string) {
		return httpService.orchestrateRequest<GetAccountResponseBody>({
			method: 'GET',
			url: `/accounts/${accountId}`,
		});
	},
	deleteAccountById(accountId: string) {
		return httpService.orchestrateRequest<DeleteAccountByIdResponseBody>({
			method: 'DELETE',
			url: `/accounts/${accountId}`,
		});
	},
	getAccountInvites() {
		return httpService.orchestrateRequest<GetAccountInvitesResponseBody>({
			method: 'GET',
			url: '/accounts/invite',
		});
	},
	getAccountInviteById(invitationId: string) {
		return httpService.orchestrateRequest<getAccountInviteByIdResponseBody>({
			method: 'GET',
			url: `/accounts/invite/${invitationId}`,
		});
	},
	sendAccountInvites(emailList: string[]) {
		return httpService.orchestrateRequest<SendAccountInvitesResponseBody>({
			method: 'POST',
			url: '/accounts/invite',
			data: { emailList },
		});
	},
	revokeAccountInvite(invitationId: string) {
		return httpService.orchestrateRequest<RevokeAccountInviteResponseBody>({
			method: 'PUT',
			url: '/accounts/invite/revoke',
			data: { invitationId },
		});
	},
	createAccount(data: CreateAccountRequestBody) {
		return httpService.orchestrateRequest<CreateAccountResponseBody>({
			method: 'POST',
			url: '/accounts',
			data,
		});
	},
	updateAccount(data: UpdateAccountRequestBody) {
		return httpService.orchestrateRequest<UpdateAccountResponseBody>({
			method: 'PUT',
			url: '/accounts/update',
			data,
		});
	},
	sendForgotPasswordEmail(emailAddress: string) {
		return httpService.orchestrateRequest({
			method: 'POST',
			url: '/accounts/forgot-password',
			data: { emailAddress },
		});
	},
	resetPassword(passwordResetToken: string, password: string, confirmPassword: string) {
		return httpService.orchestrateRequest({
			method: 'POST',
			url: '/accounts/reset-password',
			data: { passwordResetToken, password, confirmPassword },
		});
	},
	changeInstitution(data: { institutionId: string }) {
		return httpService.orchestrateRequest<{ account: AccountModel }>({
			method: 'PUT',
			url: '/accounts/institution',
			data,
		});
	},
};
