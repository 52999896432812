import { cloneDeep } from 'lodash';
import React, { FC, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { TransitionGroup } from 'react-transition-group';

import { ReactComponent as SearchIcon } from '@/assets/search-icon.svg';
import { FadeTransition } from './fade-transition';
import { isValidZipCode } from '@/core/utils';

const useStyles = createUseStyles({
	zipCodeModal: {
		width: '90%',
		maxWidth: 260,
		margin: '0 auto',
	},
	searchIcon: {
		top: '50%',
		right: 10,
		position: 'absolute',
		pointerEvents: 'none',
		transform: 'translateY(-50%)',
	},
});

interface ZipCodeModalProps extends ModalProps {
	zipCodes: string[];
	onSave(zipCodes: string[]): void;
}

export const ZipCodeModal: FC<ZipCodeModalProps> = ({ zipCodes, onSave, ...props }) => {
	const classes = useStyles();
	const [inputValue, setInputValue] = useState('');
	const [internalZipCodes, setInternalZipCodes] = useState<string[]>([]);

	function handleOnEnter() {
		setInternalZipCodes(zipCodes);
	}

	function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();

		if (!validateZip()) {
			return;
		}

		const internalZipCodesClone = cloneDeep(internalZipCodes);
		internalZipCodesClone.push(inputValue);

		setInternalZipCodes(internalZipCodesClone);
		setInputValue('');
	}

	function validateZip() {
		if (!isValidZipCode(inputValue) || internalZipCodes.includes(inputValue)) {
			return false;
		}

		return true;
	}

	function handleRemoveButtonClick(zipCode: string) {
		const internalZipCodesClone = cloneDeep(internalZipCodes);
		const indexToRemove = internalZipCodesClone.findIndex((zc) => zc === zipCode);

		internalZipCodesClone.splice(indexToRemove, 1);
		setInternalZipCodes(internalZipCodesClone);
	}

	function handleSaveButtonClick() {
		onSave([...internalZipCodes, ...(validateZip() ? [inputValue] : [])]);
	}

	return (
		<Modal dialogClassName={classes.zipCodeModal} centered {...props} onEnter={handleOnEnter}>
			<Modal.Header>
				<Modal.Title>Find a zip</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleFormSubmit}>
					<Form.Group className={internalZipCodes.length > 0 ? 'mb-6' : 'mb-0'}>
						<div className="position-relative">
							<Form.Control
								type="search"
								value={inputValue}
								onChange={(event) => {
									setInputValue(event.currentTarget.value);
								}}
							/>
							<SearchIcon className={classes.searchIcon} />
						</div>
					</Form.Group>
					<FadeTransition in={internalZipCodes.length > 0}>
						<Form.Group>
							<Form.Label className="mb-2">Showing requests from:</Form.Label>
							<ul className="list-unstyled">
								<TransitionGroup>
									{internalZipCodes.map((zipCode, index) => {
										const isLast = index === internalZipCodes.length - 1;

										return (
											<FadeTransition key={zipCode}>
												<li className={isLast ? 'mb-0' : 'mb-2'}>
													<div className="d-flex align-items-center">
														<p className="mb-0 mr-3">{zipCode}</p>
														<Button
															variant="link"
															className="text-danger"
															onClick={() => {
																handleRemoveButtonClick(zipCode);
															}}
														>
															Remove
														</Button>
													</div>
												</li>
											</FadeTransition>
										);
									})}
								</TransitionGroup>
							</ul>
						</Form.Group>
					</FadeTransition>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-between align-items-center">
					<Button variant="link" onClick={props.onHide}>
						Close
					</Button>
					<Button size="sm" onClick={handleSaveButtonClick}>
						Save
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
