import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';

import {
	FoodRequestModel,
	FOOD_REQUEST_TYPE,
	INSTITUTION_TYPE_ID,
	NOTIFICATION_METHODS,
	REQUEST_STATUS,
	unpreparedFood,
	yesNoUnknown,
	YES_NO_UNKNOWN,
} from '@/core/models';

import { useAccount, useAlert, useHandleError } from '@/hooks';
import { ContactModal, RequestStatusDot, StatusSelect } from '@/components';
import { foodRequestService } from '@/core/services';

interface RequestDetailTemplateProps {
	foodRequest: FoodRequestModel;
	onStatusUpdate(foodRequest: FoodRequestModel): void;
}

export const RequestDetailTemplate: FC<RequestDetailTemplateProps> = ({ foodRequest, onStatusUpdate }) => {
	const { account, updateAccount } = useAccount();
	const { showAlert } = useAlert();
	const handleError = useHandleError();
	const [showContactModal, setShowContactModal] = useState(false);
	const [statusSelectValue, setStatusSelectValue] = useState(foodRequest.foodRequestStatusId as REQUEST_STATUS);

	const [accountToShow, setAccountToShow] = useState<any>();
	const [phoneNumberToShow, setPhoneNumberToShow] = useState('');
	const [phoneNumberToShowSmsCapable, setPhoneNumberToShowSmsCapable] = useState(false);

	useEffect(() => {
		setStatusSelectValue(foodRequest.foodRequestStatusId as REQUEST_STATUS);
	}, [foodRequest.foodRequestStatusId]);

	async function handleUpdateButtonClick() {
		try {
			const response = await foodRequestService
				.changeFoodRequestStatusById(foodRequest.foodRequestId, statusSelectValue)
				.fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							This request is now {response.foodRequest.foodRequestStatusDescription}
						</p>
					);
				},
				isDismissible: false,
			});

			onStatusUpdate(response.foodRequest);

			await updateAccount();
		} catch (error) {
			handleError(error);
		}
	}

	function handleCreatedByContactButtonClick() {
		setAccountToShow(foodRequest.createdBy.account);
		setPhoneNumberToShow(foodRequest.contactPhoneNumberDescription);
		setPhoneNumberToShowSmsCapable(foodRequest.contactPhoneNumberSmsCapable);

		setShowContactModal(true);
	}

	function handleClaimedByContactButtonClick() {
		if (!foodRequest.claimedBy) {
			return;
		}

		const accountCanRecieveSms =
			foodRequest.claimedBy.account.notificationTypeId === NOTIFICATION_METHODS.TEXT ||
			foodRequest.claimedBy.account.notificationTypeId === NOTIFICATION_METHODS.BOTH;

		setAccountToShow(foodRequest.claimedBy.account);
		setPhoneNumberToShow(foodRequest.claimedBy.account.phoneNumberDescription || '');
		setPhoneNumberToShowSmsCapable(accountCanRecieveSms);

		setShowContactModal(true);
	}

	function canViewRecipientDetails() {
		if (!account) {
			return false;
		}

		const userIsMemeberOfCreatingInstitution = account.institution.institutionId === foodRequest.institutionId;
		const userIsMemberOfClaimingInstitution = foodRequest.claimedBy
			? account.institution.institutionId === foodRequest.claimedBy.account.institution.institutionId
			: false;

		if (userIsMemeberOfCreatingInstitution || userIsMemberOfClaimingInstitution) {
			return true;
		}

		return false;
	}

	function isUnavailable<T>(valueToCheck: T, valueToReturn: any) {
		if (account?.institution.institutionTypeId === INSTITUTION_TYPE_ID.DELIVERY) {
			return <p className="mb-0 text-muted font-italic">Unavailable</p>;
		}

		if (valueToCheck) {
			return <p className="mb-0">{valueToReturn}</p>;
		}

		return <p className="mb-0" />;
	}

	return (
		<>
			{accountToShow && (
				<ContactModal
					account={accountToShow}
					phoneNumber={phoneNumberToShow}
					phoneNumberSmsCapable={phoneNumberToShowSmsCapable}
					show={showContactModal}
					onHide={() => {
						setShowContactModal(false);
					}}
				/>
			)}

			<Row>
				<Col md={6} className="mb-8">
					<Row className="mb-4">
						<Col>
							<Form.Label className="mb-0">Status</Form.Label>
						</Col>
						<Col>
							<div className="d-flex">
								<StatusSelect
									large
									value={statusSelectValue}
									onChange={(event) => {
										setStatusSelectValue(event.currentTarget.value as REQUEST_STATUS);
									}}
									disabled={foodRequest.availableStatuses.length === 0}
								>
									<option value={foodRequest.foodRequestStatusId} disabled>
										{foodRequest.foodRequestStatusDescription}
									</option>
									{foodRequest.availableStatuses.map((availableStatus) => {
										return (
											<option
												key={availableStatus.foodRequestStatusId}
												value={availableStatus.foodRequestStatusId}
											>
												{availableStatus.description}
											</option>
										);
									})}
								</StatusSelect>
							</div>
							{foodRequest.availableStatuses.length > 0 && (
								<Button variant="link" className="mt-2" onClick={handleUpdateButtonClick}>
									Update
								</Button>
							)}
						</Col>
					</Row>

					{foodRequest.linkedFoodRequestId && (
						<Row className="mb-4">
							<Col>
								<Form.Label className="mb-0">Linked Request</Form.Label>
							</Col>
							<Col>
								<Link className="mb-0" to={`/track-requests/${foodRequest.linkedFoodRequestId}`}>
									{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.DELIVERY_ONLY
										? 'View Linked Request'
										: 'View Linked Delivery Request'}
								</Link>
							</Col>
						</Row>
					)}

					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Urgent need?</Form.Label>
						</Col>
						<Col>
							<div className="d-flex align-items-center">
								{foodRequest.urgentNeedId === YES_NO_UNKNOWN.YES && (
									<RequestStatusDot size={10} urgent className="mr-2" />
								)}
								<p className="mb-0">{yesNoUnknown[foodRequest.urgentNeedId].yesNoUnknownTitle}</p>
							</div>
						</Col>
					</Row>
					{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.UNPREPARED_FOOD && (
						<Row className="mb-1">
							<Col>
								<Form.Label className="mb-0">Type of unprepared food?</Form.Label>
							</Col>
							<Col>
								{foodRequest.unpreparedFoodTypeId && (
									<p className="mb-0">
										{unpreparedFood[foodRequest.unpreparedFoodTypeId].unpreparedFoodTitle}
									</p>
								)}
							</Col>
						</Row>
					)}
					{foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY && (
						<Row className="mb-1">
							<Col>
								<Form.Label className="mb-0">Pickup or delivery?</Form.Label>
							</Col>
							<Col>
								<p className="mb-0">{foodRequest.recipientToPickup ? 'Pickup' : 'Delivery'}</p>
							</Col>
						</Row>
					)}
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Zip code</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">{foodRequest.recipient.postalCode}</p>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Amount requested</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">
								{foodRequest.recipient.householdSize}{' '}
								{foodRequest.recipient.householdSize === 1 ? 'person' : 'people'},{' '}
								{foodRequest.requestDurationDays / 7}{' '}
								{foodRequest.requestDurationDays / 7 === 1 ? 'week' : 'weeks'}
							</p>
						</Col>
					</Row>
				</Col>
				<Col md={6} className="mb-8">
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Posted on</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">{foodRequest.createdDescription}</p>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Label className="mb-0">Posted by</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">{foodRequest.createdBy.account.institution.name}</p>
							<Button variant="link" onClick={handleCreatedByContactButtonClick}>
								Contact
							</Button>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Claimed on</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">
								{foodRequest.claimedBy ? foodRequest.claimedBy.claimedDateDescription : '--'}
							</p>
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Claimed by</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">
								{foodRequest.claimedBy ? foodRequest.claimedBy.account.institution.name : '--'}
							</p>
							{foodRequest.claimedBy && (
								<Button variant="link" onClick={handleClaimedByContactButtonClick}>
									Contact
								</Button>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col md={6} className="mb-8">
					<h2 className="mb-2">Request Details</h2>
					<hr className="mb-5" />
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Recipient</Form.Label>
						</Col>
						<Col>
							{canViewRecipientDetails() ? (
								<p className="mb-0">{foodRequest.recipient.name}</p>
							) : (
								<p className="text-muted font-italic mb-0">Visible after claiming</p>
							)}
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Label className="mb-0">Phone Number</Form.Label>
						</Col>
						<Col>
							{canViewRecipientDetails() ? (
								<>
									<p className="mb-0">{foodRequest.recipient.phoneNumberDescription}</p>
									<p className="mb-0">
										{foodRequest.recipient.phoneNumberSmsCapable
											? '(receives calls & texts)'
											: '(receives calls only)'}
									</p>
								</>
							) : (
								<p className="text-muted font-italic mb-0">Visible after claiming</p>
							)}
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Label className="mb-0">Preferred Spoken Language</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">
								{foodRequest.recipient.recipientPreferredSpokenLanguageIsEnglish
									? 'English'
									: foodRequest.recipient.recipientPreferredSpokenLanguage}
							</p>
						</Col>
					</Row>

					{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.DELIVERY_ONLY && (
						<>
							<Row className="mb-1">
								<Col>
									<Form.Label className="mb-0">Pickup location</Form.Label>
								</Col>
								<Col>
									<p className="mb-0">{foodRequest.deliveryPickupDetails.name}</p>
								</Col>
							</Row>
							<Row className="mb-4">
								<Col>
									<Form.Label className="mb-0">Pickup phone number</Form.Label>
								</Col>
								<Col>
									<p className="mb-0">{foodRequest.deliveryPickupDetails.phoneNumberDescription}</p>
									<p className="mb-0">
										{foodRequest.deliveryPickupDetails.phoneNumberSmsCapable
											? '(receives calls & texts)'
											: '(receives calls only)'}
									</p>
								</Col>
							</Row>
							<Row className="mb-4">
								<Col>
									<Form.Label className="mb-0">Pickup address</Form.Label>
								</Col>
								<Col>
									<p className="mb-0">
										{foodRequest.deliveryPickupDetails.addressLine1}{' '}
										{foodRequest.deliveryPickupDetails.addressLine2}
									</p>
									<p className="mb-0">
										{foodRequest.deliveryPickupDetails.city},{' '}
										{foodRequest.deliveryPickupDetails.stateId}{' '}
										{foodRequest.deliveryPickupDetails.postalCode}
									</p>
								</Col>
							</Row>
							<Row className="mb-4">
								<Col>
									<Form.Label className="mb-0">Pickup window</Form.Label>
								</Col>
								<Col>
									<p className="mb-0">{foodRequest.deliveryPickupDetails.pickupWindow}</p>
								</Col>
							</Row>
							<Row className="mb-4">
								<Col>
									<Form.Label className="mb-0">Pickup notes</Form.Label>
								</Col>
								<Col>
									<p className="mb-0">{foodRequest.deliveryPickupDetails.notes}</p>
								</Col>
							</Row>
						</>
					)}

					{foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY && (
						<>
							<Row>
								<Col>
									<Form.Label className="mb-0">Dietary Restrictions</Form.Label>
								</Col>
								<Col>
									{foodRequest.dietaryRestrictions && foodRequest.dietaryRestrictions.length > 0 ? (
										<ul className="list-unstyled">
											{foodRequest.dietaryRestrictions.map((dietaryRestriction) => {
												return (
													<li
														key={dietaryRestriction.dietaryRestrictionId}
														className="d-flex align-items-center"
													>
														<RequestStatusDot size={10} urgent />
														<p className="ml-2 mb-0">
															{dietaryRestriction.description}
															{dietaryRestriction.supplementalDescription
																? ` (${dietaryRestriction.supplementalDescription})`
																: ''}
														</p>
													</li>
												);
											})}
										</ul>
									) : (
										isUnavailable(foodRequest.recipient.perishableFoodStorageId, '--')
									)}
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Label className="mb-0">Can accept restricted foods?</Form.Label>
								</Col>
								<Col>
									{isUnavailable(
										foodRequest.hasFlexibleDietaryRestrictions,
										foodRequest.hasFlexibleDietaryRestrictions ? 'Yes' : 'No'
									)}
								</Col>
							</Row>
							{foodRequest.hasFlexibleDietaryRestrictions === true && (
								<Row className="mb-4">
									<Col>
										<Form.Label className="mb-0">Explanation</Form.Label>
									</Col>
									<Col>
										<p className="mb-0">{foodRequest.dietaryRestrictionFlexibilityDescription}</p>
									</Col>
								</Row>
							)}
						</>
					)}

					{!foodRequest.recipientToPickup && (
						<Row className="mb-4">
							<Col>
								<Form.Label className="mb-0">Delivery address</Form.Label>
							</Col>
							<Col>
								{canViewRecipientDetails() ? (
									<>
										<p className="mb-0">
											{foodRequest.recipient.addressLine1} {foodRequest.recipient.addressLine2}
										</p>
										<p className="mb-0">
											{foodRequest.recipient.city}, {foodRequest.recipient.stateId}{' '}
											{foodRequest.recipient.postalCode}
										</p>
									</>
								) : (
									<p className="text-muted font-italic mb-0">{foodRequest.recipient.postalCode}</p>
								)}
							</Col>
						</Row>
					)}

					<Row
						className={foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY ? 'mb-1' : 'mb-4'}
					>
						<Col>
							<Form.Label className="mb-0">Recipient can store food?</Form.Label>
						</Col>
						<Col>
							<div className="d-flex align-items-center">
								{foodRequest.recipient.perishableFoodStorageId === YES_NO_UNKNOWN.NO && (
									<RequestStatusDot size={10} urgent className="mr-2" />
								)}
								{isUnavailable(
									foodRequest.recipient.perishableFoodStorageId,
									yesNoUnknown[foodRequest.recipient.perishableFoodStorageId]?.yesNoUnknownTitle
								)}
							</div>
						</Col>
					</Row>
					{foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY && (
						<>
							<Row className="mb-1">
								<Col>
									{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.PREPARED_MEALS && (
										<Form.Label className="mb-0">Recipient can reheat food?</Form.Label>
									)}
									{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.UNPREPARED_FOOD && (
										<Form.Label className="mb-0">Recipient can cook food?</Form.Label>
									)}
								</Col>
								<Col>
									<div className="d-flex align-items-center">
										{foodRequest.recipient.reheatFoodId === YES_NO_UNKNOWN.NO && (
											<RequestStatusDot size={10} urgent className="mr-2" />
										)}
										{isUnavailable(
											foodRequest.recipient.reheatFoodId,
											yesNoUnknown[foodRequest.recipient.reheatFoodId]?.yesNoUnknownTitle
										)}
									</div>
								</Col>
							</Row>
							{foodRequest.foodRequestTypeId === FOOD_REQUEST_TYPE.UNPREPARED_FOOD && (
								<Row className="mb-4">
									<Col>
										<Form.Label className="mb-0">
											Requesting ready-to-eat food only (e.g. hand fruit, canned foods)?
										</Form.Label>
									</Col>
									<Col>
										<div className="d-flex align-items-center">
											{foodRequest.readyToEatId === YES_NO_UNKNOWN.YES && (
												<RequestStatusDot size={10} urgent className="mr-2" />
											)}
											<p className="mb-0">
												{yesNoUnknown[foodRequest.readyToEatId].yesNoUnknownTitle}
											</p>
										</div>
									</Col>
								</Row>
							)}
						</>
					)}

					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Additional notes</Form.Label>
						</Col>
						<Col>
							<p className="mb-0">{foodRequest.notes ? foodRequest.notes : '--'}</p>
						</Col>
					</Row>
				</Col>
				<Col md={6} className="mb-8">
					<h2 className="mb-2">Other Information</h2>
					<hr className="mb-5" />
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Age under 21?</Form.Label>
						</Col>
						<Col>
							{isUnavailable(
								foodRequest.recipientsUnderTwentyOne,
								yesNoUnknown[foodRequest.recipientsUnderTwentyOne]?.yesNoUnknownTitle
							)}
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Age 60+?</Form.Label>
						</Col>
						<Col>
							{isUnavailable(
								foodRequest.recipientsOverSixty,
								yesNoUnknown[foodRequest.recipientsOverSixty]?.yesNoUnknownTitle
							)}
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Benefits eligibility</Form.Label>
						</Col>
						<Col>
							{foodRequest.healthBenefits && foodRequest.healthBenefits.length > 0 ? (
								<ul className="list-unstyled">
									{foodRequest.healthBenefits.map((healthBenefit) => {
										return (
											<li
												key={healthBenefit.healthBenefitId}
												className="d-flex align-items-center"
											>
												<p className="mb-0">
													{healthBenefit.description}
													{healthBenefit.supplementalDescription
														? ` (${healthBenefit.supplementalDescription})`
														: ''}
												</p>
											</li>
										);
									})}
								</ul>
							) : (
								isUnavailable(foodRequest.recipientsUnderTwentyOne, '--')
							)}
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Household income</Form.Label>
						</Col>
						<Col>
							{isUnavailable(
								foodRequest.householdAnnualIncome,
								`$${foodRequest.householdAnnualIncome ? foodRequest.householdAnnualIncome : '--'}`
							)}
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<Form.Label className="mb-0">Household size</Form.Label>
						</Col>
						<Col>
							{isUnavailable(foodRequest.recipient.householdSize, foodRequest.recipient.householdSize)}
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Chronic health conditions</Form.Label>
						</Col>
						<Col>
							{canViewRecipientDetails() ? (
								<>
									{foodRequest.healthConditions && foodRequest.healthConditions.length > 0 ? (
										<ul className="mb-0 list-unstyled">
											{foodRequest.healthConditions.map((healthCondition) => {
												return (
													<li
														key={healthCondition.healthConditionId}
														className="d-flex align-items-center"
													>
														<p className="mb-0">
															{healthCondition.description}
															{healthCondition.supplementalDescription
																? ` (${healthCondition.supplementalDescription})`
																: ''}
														</p>
													</li>
												);
											})}
										</ul>
									) : (
										<p className="mb-0">--</p>
									)}
								</>
							) : (
								isUnavailable(foodRequest.recipient.healthSystems, 'Visible after claiming')
							)}
						</Col>
					</Row>
					{foodRequest.secondaryNutritionalRisks && (
						<Row className="mb-4">
							<Col>
								<Form.Label className="mb-0">Secondary nutritional risks</Form.Label>
							</Col>
							<Col>
								{canViewRecipientDetails() ? (
									<>
										{foodRequest.secondaryNutritionalRisks.length > 0 ? (
											<ul className="list-unstyled">
												{foodRequest.secondaryNutritionalRisks.map(
													(secondaryNutritionalRisk) => {
														return (
															<li
																key={
																	secondaryNutritionalRisk.secondaryNutritionalRiskId
																}
																className="d-flex align-items-center"
															>
																<p className="mb-0">
																	{secondaryNutritionalRisk.shortDescription}
																</p>
															</li>
														);
													}
												)}
											</ul>
										) : (
											<p className="mb-0">--</p>
										)}
									</>
								) : (
									isUnavailable(foodRequest.secondaryNutritionalRisks, 'Visible after claiming')
								)}
							</Col>
						</Row>
					)}
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Health system</Form.Label>
						</Col>
						<Col>
							{foodRequest.recipient.healthSystems && foodRequest.recipient.healthSystems.length > 0 ? (
								<ul className="list-unstyled">
									{foodRequest.recipient.healthSystems.map((healthSystem) => {
										return (
											<li key={healthSystem.institutionId} className="d-flex align-items-center">
												<p className="mb-0">{healthSystem.name}</p>
											</li>
										);
									})}
								</ul>
							) : (
								isUnavailable(foodRequest.recipient.healthSystems, '--')
							)}
						</Col>
					</Row>
					<Row className="mb-1">
						<Col>
							<Form.Label className="mb-0">Insurance</Form.Label>
						</Col>
						<Col>
							{isUnavailable(
								foodRequest.healthInsuranceProvider,
								foodRequest.healthInsuranceProvider ? foodRequest.healthInsuranceProvider : '--'
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
