import React, { FC, useEffect } from 'react';
import { colors, theme } from '@/jss';

interface BackgroundColorHelper {
	backgroundColor?: colors;
}

export const BackgroundColorHelper: FC<BackgroundColorHelper> = ({ backgroundColor, children }) => {
	useEffect(() => {
		if (backgroundColor) {
			document.body.style.backgroundColor = backgroundColor;
		} else {
			document.body.style.backgroundColor = theme.colors.grayOne;
		}
	}, [backgroundColor]);

	return <>{children}</>;
};
