import React, { FC, useCallback } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { createUseStyles } from 'react-jss';

import { useQuery, useRequestForm } from '@/hooks';
import { AsyncPage, RequiredAsterisk } from '@/components';
import { Basics, EligibilityAssessment, PickupDelivery, Review, Send } from '@/components/request-form';
import {
	DIETARY_RESTRICTIONS,
	FOOD_REQUEST_TYPE,
	HEALTH_BENEFITS,
	HEALTH_CONDITIONS,
	INSTITUTION_TYPE_ID,
	TRANSPORTATION,
} from '@/core/models';
import { foodRequestService } from '@/core/services';

import { mediaQueries } from '@/jss';
import { ReactComponent as BreadcrumbChevron } from '@/assets/breadcrumb-chevron.svg';

const transitionDuration = 200;
const useStyles = createUseStyles({
	titleBar: {
		display: 'flex',
		marginBottom: 10,
		justifyContent: 'space-between',
		[mediaQueries.lg]: {
			display: 'block',
		},
	},
	title: {
		[mediaQueries.lg]: {
			marginBottom: 10,
		},
	},
	'@global': {
		'.slide-fade-enter': {
			opacity: 0,
			transform: 'translateY(48px)',
		},
		'.slide-fade-enter-active': {
			opacity: 1,
			transform: 'translateY(0)',
		},
		'.slide-fade-exit': {
			opacity: 1,
			transform: 'translateY(0)',
		},
		'.slide-fade-exit-active': {
			opacity: 0,
			transform: 'translateY(48px)',
		},
		'.slide-fade-enter-active, .slide-fade-exit-active': {
			transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
		},
	},
});

export const PostRequest: FC = () => {
	const classes = useStyles();
	const history = useHistory<{ requestType?: FOOD_REQUEST_TYPE }>();
	const location = useLocation();

	const { path } = useRouteMatch();
	const isBasicsRoute = !!useRouteMatch(`${path}/basics`);
	const isPickupDeliveryRoute = !!useRouteMatch(`${path}/pickup-delivery`);
	const isEligibilityAssessmentRoute = !!useRouteMatch(`${path}/eligibility-assessment`);
	const isReviewRoute = !!useRouteMatch(`${path}/review`);
	const isSendRoute = !!useRouteMatch(`${path}/send`);

	const query = useQuery();
	const foodRequestId = query.get('foodRequestId');
	const repostAsNew = query.get('repostAsNew') === 'true';
	const repostForDeliveryOnly = query.get('repostForDeliveryOnly') === 'true';

	const {
		formValues,
		setMultipleFormValues,
		basicsPageIsEnabled,
		pickupDeliveryPageIsEnabled,
		eligibilityAssessmentPageIsEnabled,
		reviewPageIsEnabled,
		sendPageIsEnabled,
	} = useRequestForm();

	const fetchData = useCallback(async () => {
		if (!foodRequestId) {
			// This is a new request, no need fetch!
			return;
		}

		const { foodRequest } = await foodRequestService.getFoodRequestById(foodRequestId).fetch();

		if (foodRequest.recipient.healthSystemOtherDescription) {
			const otherHealthSystem = {
				institutionId: 'OTHER',
				institutionTypeId: INSTITUTION_TYPE_ID.HEALTH_SYSTEM,
				name: 'Other',
				adminName: 'N/A',
			};

			if (foodRequest.recipient.healthSystems) {
				foodRequest.recipient.healthSystems.push(otherHealthSystem);
			} else {
				foodRequest.recipient.healthSystems = [otherHealthSystem];
			}
		}

		setMultipleFormValues({
			showBreadcrumbs: repostForDeliveryOnly ? false : true,
			recipientConsent: true,
			requestType: repostForDeliveryOnly ? FOOD_REQUEST_TYPE.DELIVERY_ONLY : foodRequest.foodRequestTypeId,
			unpreparedFoodType: foodRequest.unpreparedFoodTypeId,
			phoneNumber: repostForDeliveryOnly ? '' : foodRequest.contactPhoneNumber,
			phoneNumberCanReceiveMessages: repostForDeliveryOnly ? false : foodRequest.contactPhoneNumberSmsCapable,
			recipientsHouseholdSize: String(foodRequest.recipient.householdSize),
			foodSupportDuration: String(foodRequest.requestDurationDays / 7),
			canStore: foodRequest.recipient.perishableFoodStorageId,
			hasKitchen: foodRequest.recipient.reheatFoodId,
			readyToEat: foodRequest.readyToEatId,
			isUrgent: foodRequest.urgentNeedId,
			recipientHasDietaryRestrictions:
				foodRequest.dietaryRestrictions && foodRequest.dietaryRestrictions.length > 0,
			dietaryRestrictions:
				foodRequest.dietaryRestrictions &&
				foodRequest.dietaryRestrictions.map((dr) => {
					return dr.dietaryRestrictionId;
				}),
			allergy:
				foodRequest.dietaryRestrictions &&
				foodRequest.dietaryRestrictions.find((dr) => {
					return dr.dietaryRestrictionId === DIETARY_RESTRICTIONS.FOOD_ALLERGY;
				})?.supplementalDescription,
			otherDiet:
				foodRequest.dietaryRestrictions &&
				foodRequest.dietaryRestrictions.find((dr) => {
					return dr.dietaryRestrictionId === DIETARY_RESTRICTIONS.OTHER_DIET;
				})?.supplementalDescription,
			hasFlexibleDietaryRestrictions: foodRequest.hasFlexibleDietaryRestrictions,
			dietaryRestrictionFlexibilityDescription: foodRequest.dietaryRestrictionFlexibilityDescription,
			transportationMethod: foodRequest.recipientToPickup ? TRANSPORTATION.PICKUP : TRANSPORTATION.DELIVERY,
			...(foodRequest.deliveryPickupDetails
				? {
						pickupLocationName: foodRequest.deliveryPickupDetails.name,
						pickupLocationPhoneNumber: foodRequest.deliveryPickupDetails.phoneNumber,
						pickupLocationPhoneNumberCanReceiveMessages:
							foodRequest.deliveryPickupDetails.phoneNumberSmsCapable,
						pickupLocationAddressOne: foodRequest.deliveryPickupDetails.addressLine1,
						pickupLocationAddressTwo: foodRequest.deliveryPickupDetails.addressLine2,
						pickupLocationCity: foodRequest.deliveryPickupDetails.city,
						pickupLocationState: foodRequest.deliveryPickupDetails.stateId,
						pickupLocationZipCode: foodRequest.deliveryPickupDetails.postalCode,
						pickupLocationWindow: foodRequest.deliveryPickupDetails.pickupWindow,
						pickupLocationNotes: foodRequest.deliveryPickupDetails.notes,
				  }
				: {}),
			recipientsName: foodRequest.recipient.name,
			recipientsPhoneNumber: foodRequest.recipient.phoneNumber,
			recipientsPhoneNumberCanReceiveMessages: foodRequest.recipient.phoneNumberSmsCapable,
			recipientsPreferredSpokenLanguageIsEnglish: foodRequest.recipient.recipientPreferredSpokenLanguageIsEnglish,
			recipientsPreferredSpokenLanguage: foodRequest.recipient.recipientPreferredSpokenLanguage || '',
			recipientsAddressOne: foodRequest.recipient.addressLine1,
			recipientsAddressTwo: foodRequest.recipient.addressLine2,
			recipientsCity: foodRequest.recipient.city,
			recipientsState: foodRequest.recipient.stateId,
			recipientsZipCode: foodRequest.recipient.postalCode,
			additionalNotes: foodRequest.notes,
			...(foodRequest.healthConditions
				? {
						healthConditions: foodRequest.healthConditions.map((hc) => {
							return hc.healthConditionId;
						}),
						otherHealthCondition: foodRequest.healthConditions.find((hc) => {
							return hc.healthConditionId === HEALTH_CONDITIONS.OTHER;
						})?.supplementalDescription,
				  }
				: {}),
			secondaryNutritionRisks: foodRequest.secondaryNutritionalRisks
				? foodRequest.secondaryNutritionalRisks.map((snr) => {
						return snr.secondaryNutritionalRiskId;
				  })
				: [],

			receivingBenefits: foodRequest.receivingBenefitId,
			providedBenefitsInfoTypeId: foodRequest.providedBenefitsInfoTypeId,
			providedBenefitsInfoOtherDescription: foodRequest.providedBenefitsInfoOtherDescription,
			...(foodRequest.healthBenefits
				? {
						healthBenefits: foodRequest.healthBenefits.map((hb) => {
							return hb.healthBenefitId;
						}),
						otherHealthBenefit: foodRequest.healthBenefits.find((hb) => {
							return hb.healthBenefitId === HEALTH_BENEFITS.OTHER;
						})?.supplementalDescription,
				  }
				: {}),
			under21: foodRequest.recipientsUnderTwentyOne,
			over60: foodRequest.recipientsOverSixty,
			recipientsAnnualIncome: String(foodRequest.householdAnnualIncome),
			hasHealthInsurance: foodRequest.recipientHasHealthInsurance,
			healthInsuranceProvider: foodRequest.healthInsuranceProvider,
			...(foodRequest.recipient.healthSystems
				? {
						healthSystems: foodRequest.recipient.healthSystems.map((hs) => {
							return hs.institutionId;
						}),
						otherHealthSystem: foodRequest.recipient.healthSystemOtherDescription,
				  }
				: {}),
		});
		// eslint-disable-next-line
	}, [foodRequestId]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-6">
				<Row>
					<Col>
						<div className={classes.titleBar}>
							<div className={classes.title}>
								<h1 className="mb-0 d-inline-block mr-2">
									{repostForDeliveryOnly
										? 'Repost for Delivery Only'
										: repostAsNew
										? 'Repost as New Request'
										: foodRequestId
										? 'Edit Your Request'
										: 'Post A New Request'}
								</h1>
								<p className="mb-0 d-inline-block text-roboto-condensed">
									<RequiredAsterisk /> Required
								</p>
							</div>
							{formValues.showBreadcrumbs && (
								<div className="d-flex flex-wrap align-items-center">
									<Button
										variant="link"
										className={isBasicsRoute ? 'text-blue-two' : 'text-blue-three'}
										onClick={() => {
											history.push(`${path}/basics${location.search}`);
										}}
										disabled={!basicsPageIsEnabled}
									>
										Basics
									</Button>
									<BreadcrumbChevron className="ml-2 mr-2" />
									<Button
										variant="link"
										className={isPickupDeliveryRoute ? 'text-blue-two' : 'text-blue-three'}
										onClick={() => {
											history.push(`${path}/pickup-delivery${location.search}`);
										}}
										disabled={!pickupDeliveryPageIsEnabled}
									>
										Pickup/Delivery
									</Button>
									<BreadcrumbChevron className="ml-2 mr-2" />
									<Button
										variant="link"
										className={isEligibilityAssessmentRoute ? 'text-blue-two' : 'text-blue-three'}
										onClick={() => {
											history.push(`${path}/eligibility-assessment${location.search}`);
										}}
										disabled={!eligibilityAssessmentPageIsEnabled}
									>
										Eligibility Assessment
									</Button>
									<BreadcrumbChevron className="ml-2 mr-2" />
									<Button
										variant="link"
										className={isReviewRoute ? 'text-blue-two' : 'text-blue-three'}
										onClick={() => {
											history.push(`${path}/review${location.search}`);
										}}
										disabled={!reviewPageIsEnabled}
									>
										Review
									</Button>
									<BreadcrumbChevron className="ml-2 mr-2" />
									<Button
										variant="link"
										className={isSendRoute ? 'text-blue-two' : 'text-blue-three'}
										onClick={() => {
											history.push(`${path}/send${location.search}`);
										}}
										disabled={!sendPageIsEnabled}
									>
										Send
									</Button>
								</div>
							)}
						</div>
						<hr className="mb-6" />
					</Col>
				</Row>
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={location.key}
						addEndListener={(node, done) => {
							window.scrollTo(0, 0);
							node.addEventListener('transitionend', done, false);
						}}
						timeout={transitionDuration}
						classNames="slide-fade"
					>
						{/* Do not remove this <div>, the aniamtion requires an extra container to function properly */}
						<div>
							<Switch location={location}>
								<Route path={`${path}/`} exact={true} children={<Redirect to={`${path}/basics`} />} />
								<Route
									path={`${path}/basics`}
									exact={true}
									children={<Basics initialRequestType={history.location.state?.requestType} />}
								/>
								<Route path={`${path}/pickup-delivery`} exact={true} children={<PickupDelivery />} />
								<Route
									path={`${path}/eligibility-assessment`}
									exact={true}
									children={<EligibilityAssessment />}
								/>
								<Route path={`${path}/review`} exact={true} children={<Review />} />
								<Route path={`${path}/send`} exact={true} children={<Send />} />
								<Route path={`${path}/*`} children={<Redirect to={`${path}/basics`} />} />
							</Switch>
						</div>
					</CSSTransition>
				</SwitchTransition>
			</Container>
		</AsyncPage>
	);
};
