export enum bootstrapPrefixes {
	'badge' = 'fast-badge',
	'btn' = 'fast-button',
	'form-group' = 'fast-form__group',
	'form-label' = 'fast-form__label',
	'form-control' = 'fast-form__control',
	'form-check' = 'fast-form__check',
	'modal-title' = 'fast-modal__title',
	'modal-header' = 'fast-modal__header',
	'modal-body' = 'fast-modal__body',
	'modal-footer' = 'fast-modal__footer',
}
