import React, { FC, createContext, useState, useRef, useCallback } from 'react';

export type AlertVariant = '' | 'success' | 'warning' | 'danger';

interface AlertConfig {
	children: JSX.Element | (() => JSX.Element) | null;
	variant: AlertVariant;
	duration?: number;
	isDismissible?: boolean;
}

interface AlertContextConfig {
	alertChildren: JSX.Element | (() => JSX.Element) | null;
	alertVariant: AlertVariant;
	alertDuration: number;
	alertIsDismissible: boolean;
	alertIsShowing: boolean;
	showAlert(alertConfig: AlertConfig): void;
	hideAlert(): void;
}

export const AlertContext = createContext({} as AlertContextConfig);

const defaultAlertDuration = 6000;

export const AlertProvider: FC = ({ children }) => {
	const hideTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const [alertChildren, setAlertChildren] = useState<JSX.Element | (() => JSX.Element) | null>(null);
	const [alertVariant, setAlertVariant] = useState<AlertVariant>('');
	const [alertDuration, setAlertDuration] = useState<number>(defaultAlertDuration);
	const [alertIsDismissible, setAlertIsDismissible] = useState(false);
	const [alertIsShowing, setAlertIsShowing] = useState(false);

	const hideAlert = useCallback(() => {
		if (hideTimeoutRef.current) {
			clearTimeout(hideTimeoutRef.current);
		}

		setAlertIsShowing(false);
	}, []);

	const showAlert = useCallback(
		(alertConfig: AlertConfig) => {
			setAlertChildren(alertConfig.children);
			setAlertVariant(alertConfig.variant);
			setAlertDuration(alertConfig.duration ? alertConfig.duration : defaultAlertDuration);
			setAlertIsDismissible(alertConfig.isDismissible !== undefined ? alertConfig.isDismissible : false);
			setAlertIsShowing(true);

			if (hideTimeoutRef.current) {
				clearTimeout(hideTimeoutRef.current);
			}

			hideTimeoutRef.current = setTimeout(() => {
				hideAlert();
			}, alertDuration);
		},
		[alertDuration, hideAlert]
	);

	const value = {
		alertChildren,
		alertVariant,
		alertDuration,
		alertIsDismissible,
		alertIsShowing,
		showAlert,
		hideAlert,
	};

	return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
