import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useHandleError, useQuery } from '@/hooks';

import { LoaderButton } from '@/components';
import { accountService } from '@/core/services';

import { mediaQueries, theme } from '@/jss';
import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

const useStyles = createUseStyles({
	outer: {
		padding: 80,
		backgroundColor: theme.colors.grayOne,
		[mediaQueries.md]: {
			padding: 50,
		},
	},
});

export const ResetPassword: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const handleError = useHandleError();
	const query = useQuery();
	const passwordResetToken = query.get('passwordResetToken');

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();

		try {
			setIsSubmitting(true);

			if (!passwordResetToken) {
				throw new Error('passwordResetToken was not found.');
			}
			await accountService.resetPassword(passwordResetToken, password, confirmPassword).fetch();
			history.push('/sign-in?passwordReset=true');
		} catch (error) {
			setIsSubmitting(false);
			handleError(error);
		}
	}

	return (
		<Container className="pt-20">
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<div className={classes.outer}>
						<div className="mb-2 d-flex justify-content-center">
							<FastLogo />
						</div>
						<h4 className="mb-8 text-center text-blue-three">
							<em>Food Access Support Technology</em>
						</h4>
						<h5 className="mb-2">Reset Password</h5>
						<hr className="mb-2" />
						<p className="mb-6">Enter your new password.</p>
						<Form onSubmit={handleFormSubmit}>
							<Form.Group className="mb-3">
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									value={password}
									onChange={(event) => {
										setPassword(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>Confirm password</Form.Label>
								<Form.Control
									type="password"
									value={confirmPassword}
									onChange={(event) => {
										setConfirmPassword(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<div className="text-center">
								<LoaderButton type="submit" isLoading={isSubmitting}>
									Submit
								</LoaderButton>
							</div>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
