import { TableHeader, TableRow } from '@/components/table';
import React, { FC } from 'react';

export const InvitationTableHeaderRow: FC = () => {
	return (
		<TableRow>
			<TableHeader>Email address</TableHeader>
			<TableHeader>Date invited</TableHeader>
			<TableHeader>Status</TableHeader>
			<TableHeader>Revoke invitation</TableHeader>
		</TableRow>
	);
};
