import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { useContext } from 'react';

import { AccountContext } from '@/contexts';
import { COOKIES, DecodedAccessToken } from '@/core/models';
import { accountService } from '@/core/services';
import { useHandleError } from '@/hooks';

export const useAccount = () => {
	const handleError = useHandleError();
	const accountContext = useContext(AccountContext);

	async function updateAccount() {
		try {
			const accessToken = Cookies.get(COOKIES.ACCESS_TOKEN);

			if (!accessToken) {
				return;
			}

			const decodedAccessToken: DecodedAccessToken = jwtDecode(accessToken);
			const accountResponse = await accountService.getAccount(decodedAccessToken.sub).fetch();

			accountContext.setAccount(accountResponse.account);
		} catch (error) {
			handleError(error);
		}
	}

	return { updateAccount, ...accountContext };
};
