import React, { FC, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ERROR_CODES } from '@/core/http';
import { ErrorDisplay, Loader } from '@/components';

enum DISPLAY_STATES {
	LOADING = 'LOADING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

interface AsyncPageProps {
	fetchData(): void;
	abortFetch?(): void;
}

export const AsyncPage: FC<AsyncPageProps> = ({ children, fetchData, abortFetch }) => {
	const history = useHistory();
	const [fetchPageDataError, setFetchPageDataError] = useState(undefined);
	const [displayState, setDisplayState] = useState(DISPLAY_STATES.LOADING);

	const fetchPageData = useCallback(async () => {
		setDisplayState(DISPLAY_STATES.LOADING);
		setFetchPageDataError(undefined);

		try {
			await fetchData();
			setDisplayState(DISPLAY_STATES.SUCCESS);
		} catch (error) {
			if (error.code === ERROR_CODES.REQUEST_ABORTED) {
				return;
			}

			if (error.code === 'AUTHENTICATION_REQUIRED') {
				return history.replace('/sign-in');
			}

			setFetchPageDataError(error);
			setDisplayState(DISPLAY_STATES.ERROR);
		}
	}, [fetchData, history]);

	useEffect(() => {
		fetchPageData();

		return () => {
			typeof abortFetch === 'function' && abortFetch();
		};
	}, [fetchPageData, abortFetch]);

	function getDisplayState() {
		switch (displayState) {
			case DISPLAY_STATES.LOADING:
				return (
					<div className="pt-20 d-flex justify-content-center align-items-center">
						<Loader />
					</div>
				);
			case DISPLAY_STATES.SUCCESS:
				return children;
			case DISPLAY_STATES.ERROR:
				return (
					<ErrorDisplay
						error={fetchPageDataError}
						showBackButton={true}
						showRetryButton={true}
						onRetryButtonClick={fetchPageData}
					/>
				);
			default:
				return <></>;
		}
	}

	return <>{getDisplayState()}</>;
};
