export enum YES_NO_UNKNOWN {
	YES = 'YES',
	NO = 'NO',
	UNKNOWN = 'UNKNOWN',
}

export const yesNoUnknown = {
	[YES_NO_UNKNOWN.YES]: {
		yesNoUnknownId: YES_NO_UNKNOWN.YES,
		yesNoUnknownTitle: 'Yes',
	},
	[YES_NO_UNKNOWN.NO]: {
		yesNoUnknownId: YES_NO_UNKNOWN.NO,
		yesNoUnknownTitle: 'No',
	},
	[YES_NO_UNKNOWN.UNKNOWN]: {
		yesNoUnknownId: YES_NO_UNKNOWN.UNKNOWN,
		yesNoUnknownTitle: "I don't know",
	},
};
