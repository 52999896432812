import React, { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';

import { AccountProvider, AlertProvider, RequestFormProvider } from '@/contexts';

import { useCustomBootstrapStyles, useGlobalStyles } from '@/jss/hooks';
import { bootstrapPrefixes } from '@/jss/bootstrap-styles';
import { theme } from '@/jss';

import { Alert, BackgroundColorHelper, Footer, Header, PrivateRoute, SubHeader } from '@/components';
import {
	AccountDeleted,
	AccountSettings,
	BrowseRequests,
	Dashboard,
	DownloadReports,
	ForgotPassword,
	InvitationExpired,
	Invitations,
	NoMatch,
	PostRequest,
	PrivacyPolicy,
	RequestDetail,
	ResetPassword,
	SignIn,
	SignUp,
	TermsOfUse,
	TrackRequests,
} from '@/pages';

const routes = [
	{
		path: '/sign-up',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <SignUp />,
		footer: () => <Footer />,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/invitation-expired',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <InvitationExpired />,
		footer: () => null,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/account-deleted',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <AccountDeleted />,
		footer: () => null,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/sign-in',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <SignIn />,
		footer: () => <Footer />,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/forgot-password',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <ForgotPassword />,
		footer: () => <Footer />,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/reset-password',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <ResetPassword />,
		footer: () => <Footer />,
		backgroundColor: theme.colors.blueFour,
	},
	{
		path: '/',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <Dashboard />,
		footer: () => null,
	},
	{
		path: '/browse-requests',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <BrowseRequests />,
		footer: () => null,
	},
	{
		path: '/post-request',
		exact: false,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => {
			return (
				<RequestFormProvider>
					<PostRequest />
				</RequestFormProvider>
			);
		},
		footer: () => null,
	},
	{
		path: '/track-requests',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <TrackRequests />,
		footer: () => null,
	},
	{
		path: '/track-requests/:foodRequestId',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <RequestDetail />,
		footer: () => null,
	},
	{
		path: '/account-settings',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <AccountSettings />,
		footer: () => null,
	},
	{
		path: '/account-settings/invitations',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <Invitations />,
		footer: () => null,
	},
	{
		path: '/download-reports',
		exact: true,
		private: true,
		header: () => <Header />,
		subHeader: () => <SubHeader />,
		main: () => <DownloadReports />,
		footer: () => null,
	},
	{
		path: '/privacy-policy',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <PrivacyPolicy />,
		footer: () => null,
	},
	{
		path: '/terms-of-use',
		exact: true,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <TermsOfUse />,
		footer: () => null,
	},
	{
		path: '*',
		exact: false,
		private: false,
		header: () => null,
		subHeader: () => null,
		main: () => <NoMatch />,
		footer: () => null,
		backgroundColor: theme.colors.blueFour,
	},
];

const AppWithProviders: FC = () => {
	useGlobalStyles();
	useCustomBootstrapStyles();

	return (
		<>
			<Alert />

			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.header />} />;
				})}
			</Switch>
			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.subHeader />} />;
				})}
			</Switch>
			<Switch>
				{routes.map((route, index) => {
					if (route.private) {
						return (
							<PrivateRoute
								key={index}
								path={route.path}
								exact={route.exact}
								children={
									<BackgroundColorHelper backgroundColor={route.backgroundColor}>
										<route.main />
									</BackgroundColorHelper>
								}
							/>
						);
					} else {
						return (
							<Route
								key={index}
								path={route.path}
								exact={route.exact}
								children={
									<BackgroundColorHelper backgroundColor={route.backgroundColor}>
										<route.main />
									</BackgroundColorHelper>
								}
							/>
						);
					}
				})}
			</Switch>
			<Switch>
				{routes.map((route, index) => {
					return <Route key={index} path={route.path} exact={route.exact} children={<route.footer />} />;
				})}
			</Switch>
		</>
	);
};

const App: FC = () => {
	return (
		<BootstrapThemeProvider prefixes={bootstrapPrefixes}>
			<AccountProvider>
				<AlertProvider>
					<Router>
						<AppWithProviders />
					</Router>
				</AlertProvider>
			</AccountProvider>
		</BootstrapThemeProvider>
	);
};

export default App;
