export enum UNPREPARED_FOOD {
	PRODUCE = 'PRODUCE',
	NON_PERISHABLE = 'NON_PERISHABLE',
	MIXED = 'MIXED',
}

export const unpreparedFood = {
	[UNPREPARED_FOOD.PRODUCE]: {
		unpreparedFoodId: UNPREPARED_FOOD.PRODUCE,
		unpreparedFoodTitle: 'Produce',
	},
	[UNPREPARED_FOOD.NON_PERISHABLE]: {
		unpreparedFoodId: UNPREPARED_FOOD.NON_PERISHABLE,
		unpreparedFoodTitle: 'Non-perishables',
	},
	[UNPREPARED_FOOD.MIXED]: {
		unpreparedFoodId: UNPREPARED_FOOD.MIXED,
		unpreparedFoodTitle: 'Both / a mix',
	},
};
