import React, { FC } from 'react';
import { Form } from 'react-bootstrap';

import { YES_NO_UNKNOWN } from '@/core/models';

interface YesNoUnsureQuestionProps {
	name: string;
	value: YES_NO_UNKNOWN | undefined;
	onChange(value: YES_NO_UNKNOWN): void;
}

export const YesNoUnsureQuestion: FC<YesNoUnsureQuestionProps> = ({ name, value, onChange }) => {
	return (
		<>
			<Form.Check
				type="radio"
				name={name}
				id={`${name}--yes`}
				label="Yes"
				value={YES_NO_UNKNOWN.YES}
				checked={value === YES_NO_UNKNOWN.YES}
				onChange={() => {
					onChange(YES_NO_UNKNOWN.YES);
				}}
				inline
			/>
			<Form.Check
				type="radio"
				name={name}
				id={`${name}--no`}
				label="No"
				value={YES_NO_UNKNOWN.NO}
				checked={value === YES_NO_UNKNOWN.NO}
				onChange={() => {
					onChange(YES_NO_UNKNOWN.NO);
				}}
				inline
			/>
			<Form.Check
				type="radio"
				name={name}
				id={`${name}--unknown`}
				label="I don't know"
				value={YES_NO_UNKNOWN.UNKNOWN}
				checked={value === YES_NO_UNKNOWN.UNKNOWN}
				onChange={() => {
					onChange(YES_NO_UNKNOWN.UNKNOWN);
				}}
				inline
			/>
		</>
	);
};
