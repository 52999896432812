import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { RequestStatusDot } from '@/components';

import { theme } from '@/jss';
import { FoodRequestModel, REQUEST_STATUS, YES_NO_UNKNOWN } from '@/core/models';
import { getRestrictionsAndEligibility } from '@/core/utils';
import { StatusSelect } from '@/components';

const useStyles = createUseStyles({
	requestCard: {
		padding: 10,
		backgroundColor: theme.colors.white,
	},
});

interface RequestCardProps {
	className?: string;
	foodRequest: FoodRequestModel;
	onClick(): void;
	onStatusChange(status: REQUEST_STATUS): void;
}

export const RequestCard: FC<RequestCardProps> = ({ className, foodRequest, onClick, onStatusChange }) => {
	const classes = useStyles();

	return (
		<div className={classNames([classes.requestCard, className])} tabIndex={0} onClick={onClick}>
			<div className="mb-2 d-flex justify-content-between">
				<div className="d-flex align-items-center">
					<h5 className="mb-0">{foodRequest.foodRequestTypeIdDescription}</h5>
					{foodRequest.urgentNeedId === YES_NO_UNKNOWN.YES && <RequestStatusDot className="ml-1" urgent />}
					{foodRequest.requestDurationDays > 7 && <RequestStatusDot className="ml-1" needsSupport />}
				</div>
				<StatusSelect
					value={foodRequest.foodRequestStatusId}
					onChange={(event) => {
						onStatusChange(event.currentTarget.value as REQUEST_STATUS);
					}}
				>
					<option value={foodRequest.foodRequestStatusId} disabled>
						{foodRequest.foodRequestStatusDescription}
					</option>
					{foodRequest.availableStatuses.map((availableStatus) => {
						return (
							<option
								key={availableStatus.foodRequestStatusId}
								value={availableStatus.foodRequestStatusId}
							>
								{availableStatus.description}
							</option>
						);
					})}
				</StatusSelect>
			</div>
			<div className="mb-1">
				{getRestrictionsAndEligibility(foodRequest).map((restriction, index) => {
					return (
						<Badge key={`${restriction}-${index}`} pill className="inline">
							{restriction}
						</Badge>
					);
				})}
			</div>
			<small className="mb-0 d-block text-muted">
				{foodRequest.recipient.householdSize} {foodRequest.recipient.householdSize === 1 ? 'person' : 'people'},{' '}
				{foodRequest.requestDurationDays / 7} {foodRequest.requestDurationDays / 7 === 1 ? 'week' : 'weeks'}
			</small>
			<small className="mb-0 d-block text-muted">{foodRequest.recipientToPickup ? 'Pickup' : 'Delivery'}</small>
			<small className="mb-0 d-block text-muted">
				{foodRequest.createdDescription} by {foodRequest.createdBy.account.institution.name}
			</small>
			{foodRequest.claimedBy && (
				<small className="mb-0 d-block text-muted">
					Claimed on {foodRequest.claimedBy.claimedDateDescription}
				</small>
			)}
		</div>
	);
};
