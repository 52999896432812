export enum FOOD_REQUEST_TYPE {
	UNPREPARED_FOOD = 'UNPREPARED_FOOD',
	PREPARED_MEALS = 'PREPARED_MEALS',
	DELIVERY_ONLY = 'DELIVERY_ONLY',
}

export const foodRequestTypes = {
	[FOOD_REQUEST_TYPE.UNPREPARED_FOOD]: {
		requestTypeId: FOOD_REQUEST_TYPE.UNPREPARED_FOOD,
		requestTypeTitle: 'Unprepared food',
	},
	[FOOD_REQUEST_TYPE.PREPARED_MEALS]: {
		requestTypeId: FOOD_REQUEST_TYPE.PREPARED_MEALS,
		requestTypeTitle: 'Prepared meals',
	},
	[FOOD_REQUEST_TYPE.DELIVERY_ONLY]: {
		requestTypeId: FOOD_REQUEST_TYPE.DELIVERY_ONLY,
		requestTypeTitle: 'Just needs delivery',
	},
};
