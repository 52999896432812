import { format } from 'date-fns';

import { RequestFormSchema } from '@/contexts';
import {
	AccountModel,
	dietaryRestrictions,
	DIETARY_RESTRICTIONS,
	FoodRequestModel,
	foodRequestTypes,
	FOOD_REQUEST_TYPE,
	healthBenefits,
	healthConditions,
	HEALTH_BENEFITS,
	HEALTH_CONDITIONS,
	InstitutionModel,
	requestStatus,
	REQUEST_STATUS,
	secondaryNutritionRisks,
	TRANSPORTATION,
	UNPREPARED_FOOD,
	YES_NO_UNKNOWN,
} from '@/core/models';

export function getRestrictionsAndEligibility(foodRequest: FoodRequestModel) {
	const dietaryRestrictions =
		foodRequest.dietaryRestrictions?.map((dr) => {
			return dr.description;
		}) || [];
	const healthBenefits =
		foodRequest.healthBenefits?.map((hb) => {
			return hb.healthBenefitId;
		}) || [];

	const underTwentyOne = foodRequest.recipientsUnderTwentyOne === YES_NO_UNKNOWN.YES;
	const overSixty = foodRequest.recipientsOverSixty === YES_NO_UNKNOWN.YES;
	const healthInsurance = foodRequest.recipientHasHealthInsurance === YES_NO_UNKNOWN.YES;
	const lowIncome = foodRequest.recipient.lowIncome;

	let restrictions = [
		...healthBenefits,
		...dietaryRestrictions,
		...(underTwentyOne ? ['Age Under 21'] : []),
		...(overSixty ? ['Age Over 60'] : []),
		...(healthInsurance ? ['Has Insurance'] : []),
		...(lowIncome ? ['Low Income'] : []),
	];

	const restrictionsTotal = restrictions.length;
	const maxRestrictions = 4;

	if (restrictionsTotal > maxRestrictions) {
		restrictions = restrictions.slice(0, maxRestrictions - 1);
		restrictions.push(`+${restrictionsTotal - (maxRestrictions - 1)} More`);
	}

	return restrictions;
}

export function mapFormValuesToFoodRequest(
	formValues: RequestFormSchema,
	account: AccountModel,
	healthSystems: InstitutionModel[]
) {
	const fauxFoodRequest: FoodRequestModel = {
		foodRequestId: 'xxxx-xxxx-xxxx-xxxx',
		foodRequestTypeId: formValues.requestType || FOOD_REQUEST_TYPE.PREPARED_MEALS,
		foodRequestTypeIdDescription:
			foodRequestTypes[formValues.requestType || FOOD_REQUEST_TYPE.PREPARED_MEALS].requestTypeTitle,
		foodRequestStatusId: REQUEST_STATUS.OPEN,
		foodRequestStatusDescription: requestStatus[REQUEST_STATUS.OPEN].requestStatusTitle,
		institutionId: account.institution.institutionId,
		urgentNeedId: formValues.isUrgent || YES_NO_UNKNOWN.UNKNOWN,
		notes: formValues.additionalNotes,
		contactPhoneNumber: formValues.phoneNumber,
		contactPhoneNumberDescription: formValues.phoneNumber,
		contactPhoneNumberSmsCapable: formValues.phoneNumberCanReceiveMessages,
		requestDurationDays: parseInt(formValues.foodSupportDuration, 10) * 7,
		readyToEatId: formValues.readyToEat || YES_NO_UNKNOWN.UNKNOWN,
		recipientToPickup: formValues.transportationMethod === TRANSPORTATION.PICKUP,
		receivingBenefitId: formValues.receivingBenefits || YES_NO_UNKNOWN.UNKNOWN,
		recipientsUnderTwentyOne: formValues.under21 || YES_NO_UNKNOWN.UNKNOWN,
		recipientsOverSixty: formValues.over60 || YES_NO_UNKNOWN.UNKNOWN,
		householdAnnualIncome: parseFloat(formValues.recipientsAnnualIncome.replace(/[^0-9.-]+/g, '')),
		recipientHasHealthInsurance: formValues.hasHealthInsurance || YES_NO_UNKNOWN.UNKNOWN,
		healthInsuranceProvider: formValues.healthInsuranceProvider,
		unpreparedFoodTypeId: formValues.unpreparedFoodType || UNPREPARED_FOOD.MIXED,
		availableStatuses: [],
		recipient: {
			addressLine1: formValues.recipientsAddressOne,
			addressLine2: formValues.recipientsAddressTwo,
			city: formValues.recipientsCity,
			created: String(new Date()),
			createdDescription: format(new Date(), 'MMM d, h:mma'),
			householdSize: parseInt(formValues.recipientsHouseholdSize, 10),
			name: formValues.recipientsName,
			perishableFoodStorageId: formValues.canStore || YES_NO_UNKNOWN.UNKNOWN,
			phoneNumber: formValues.recipientsPhoneNumber,
			phoneNumberDescription: formValues.recipientsPhoneNumber,
			phoneNumberSmsCapable: formValues.recipientsPhoneNumberCanReceiveMessages,
			postalCode: formValues.recipientsZipCode,
			recipientPreferredSpokenLanguageIsEnglish: formValues.recipientsPreferredSpokenLanguageIsEnglish,
			recipientPreferredSpokenLanguage: formValues.recipientsPreferredSpokenLanguage,
			reheatFoodId: formValues.hasKitchen || YES_NO_UNKNOWN.UNKNOWN,
			stateId: formValues.recipientsState,
			// @ts-ignore
			healthSystems: formValues.healthSystems.map((fvhs) => {
				if (fvhs === 'OTHER') {
					return {
						institutionId: 'xxxx-xxxx-xxxx-xxxx',
						name: `Other (${formValues.otherHealthSystem})`,
					};
				}

				const currentHs = healthSystems.find((healthSystem) => fvhs === healthSystem.institutionId);

				return {
					institutionId: currentHs?.institutionId,
					name: currentHs?.name,
				};
			}),
		},
		healthBenefits: formValues.healthBenefits.map((hb) => {
			return {
				description: healthBenefits[hb].healthBenefitTitle,
				healthBenefitId: hb,
				...(hb === HEALTH_BENEFITS.OTHER ? { supplementalDescription: formValues.otherHealthBenefit } : {}),
			};
		}),
		healthConditions: formValues.healthConditions.map((hc) => {
			return {
				description: healthConditions[hc].healthConditionTitle,
				healthConditionId: hc,
				...(hc === HEALTH_CONDITIONS.OTHER ? { supplementalDescription: formValues.otherHealthCondition } : {}),
			};
		}),
		secondaryNutritionalRisks: formValues.secondaryNutritionRisks.map((snr) => {
			return {
				description: secondaryNutritionRisks[snr].secondaryNutritionRiskTitle,
				secondaryNutritionalRiskId: snr,
				shortDescription: secondaryNutritionRisks[snr].secondaryNutritionRiskAbbreviation,
			};
		}),
		dietaryRestrictions: formValues.dietaryRestrictions.map((dr) => {
			return {
				description: dietaryRestrictions[dr].dietaryRestrictionTitle,
				dietaryRestrictionId: dr,
				...(dr === DIETARY_RESTRICTIONS.OTHER_DIET ? { supplementalDescription: formValues.otherDiet } : {}),
				...(dr === DIETARY_RESTRICTIONS.FOOD_ALLERGY ? { supplementalDescription: formValues.allergy } : {}),
			};
		}),
		createdBy: {
			createdDate: String(new Date()),
			createdDateDescription: format(new Date(), 'MMM d, h:mma'),
			account,
		},
		claimedBy: undefined,
		created: String(new Date()),
		createdDescription: format(new Date(), 'MMM d, h:mma'),
		lastUpdated: String(new Date()),
		lastUpdatedDescription: format(new Date(), 'MMM d, h:mma'),
		deliveryPickupDetails: {
			name: formValues.pickupLocationName,
			phoneNumber: formValues.pickupLocationPhoneNumber,
			phoneNumberDescription: formValues.pickupLocationPhoneNumber,
			phoneNumberSmsCapable: formValues.pickupLocationPhoneNumberCanReceiveMessages,
			addressLine1: formValues.pickupLocationAddressOne,
			addressLine2: formValues.pickupLocationAddressTwo,
			city: formValues.pickupLocationCity,
			stateId: formValues.pickupLocationState,
			postalCode: formValues.pickupLocationZipCode,
			pickupWindow: formValues.pickupLocationWindow,
			notes: formValues.pickupLocationNotes,
		},
		hasFlexibleDietaryRestrictions: formValues.hasFlexibleDietaryRestrictions,
		dietaryRestrictionFlexibilityDescription: formValues.dietaryRestrictionFlexibilityDescription,
		linkedFoodRequestId: '',
	};

	return fauxFoodRequest;
}
