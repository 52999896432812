export enum TRANSPORTATION {
	PICKUP = 'PICKUP',
	DELIVERY = 'DELIVERY',
}

export const transportation = {
	[TRANSPORTATION.PICKUP]: {
		transportationId: TRANSPORTATION.PICKUP,
		transportationTitle: 'Pickup',
	},
	[TRANSPORTATION.DELIVERY]: {
		transportationId: TRANSPORTATION.DELIVERY,
		transportationTitle: 'Delivery',
	},
};
