import { TableHeader, TableRow } from '@/components/table';
import React, { FC } from 'react';

export const AccountTableHeaderRow: FC = () => {
	return (
		<TableRow>
			<TableHeader>Email address</TableHeader>
			<TableHeader>Date joined</TableHeader>
			<TableHeader>Delete account</TableHeader>
		</TableRow>
	);
};
