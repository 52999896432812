import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { useAlert, useHandleError, useQuery } from '@/hooks';

import {
	BrowseRequestsMobileRow,
	BrowseRequestsTableBodyRow,
	BrowseRequestsTableHeaderRow,
	Filters,
	PromptModal,
	RequestDetailModal,
} from '@/components';
import { SORT_DIRECTION, TableNoMatch, TablePagination, TableRenderer } from '@/components/table';

import { FoodRequestModel, FOOD_REQUEST_TYPE, YES_NO_UNKNOWN } from '@/core/models';
import { foodRequestService, GetFoodRequestQueryParameters } from '@/core/services';
import { getParsedQuery, getRestrictionsAndEligibility } from '@/core/utils';

import { ReactComponent as CookingIcon } from '@/assets/cooking.svg';

export const BrowseRequests: FC = () => {
	const { showAlert } = useAlert();
	const history = useHistory<{ typeId?: FOOD_REQUEST_TYPE }>();
	const handleError = useHandleError();
	const query = useQuery();
	const foodRequestIdFromQuery = query.get('foodRequestId');

	const [tableIsLoading, setTableIsLoading] = useState(false);
	const [filters, setFilters] = useState<GetFoodRequestQueryParameters>({
		...getParsedQuery(query),
		...(history.location.state?.typeId ? { typeId: [history.location.state.typeId] } : {}),
	});
	const [tableRowData, setTableRowData] = useState<FoodRequestModel[]>([]);
	const [showRequestDetailModal, setShowRequestDetailModal] = useState(false);
	const [sortDirection, setSortDirection] = useState<SORT_DIRECTION | null | undefined>(undefined);
	const [pageSize] = useState(30);
	const [pageIndex, setPageIndex] = useState(0);
	const [totalEntries, setTotalEntries] = useState(0);
	const [totalMatchingEntries, setTotalMatchingEntries] = useState(0);
	const [foodRequestIdToShow, setFoodRequestIdToShow] = useState('');
	const [showDeclineModal, setShowDeclineModal] = useState(false);

	useEffect(() => {
		if (!foodRequestIdFromQuery) {
			return;
		}

		setFoodRequestIdToShow(foodRequestIdFromQuery);
		setShowRequestDetailModal(true);
	}, [foodRequestIdFromQuery]);

	useEffect(() => {
		async function fetchFoodRequests() {
			setTableIsLoading(true);

			try {
				const { results, totalRequestsCount } = await foodRequestService
					.getOpenFoodRequests({
						...filters,
						page: pageIndex,
						...(sortDirection ? { sortOrder: sortDirection } : {}),
					})
					.fetch();

				setTotalEntries(totalRequestsCount);
				setTotalMatchingEntries(results.totalCount);
				setTableRowData(results.foodRequests);
			} catch (error) {
				handleError(error);
			}

			setTableIsLoading(false);
		}

		fetchFoodRequests();
	}, [filters, handleError, pageIndex, sortDirection]);

	function handleSort(_sortBy: string, sortDirection: SORT_DIRECTION) {
		setSortDirection(sortDirection);
	}

	function handleTableRowClick(rowData: FoodRequestModel) {
		setFoodRequestIdToShow(rowData.foodRequestId);
		setShowRequestDetailModal(true);
	}

	async function handleRequestDetailModalClaim(foodRequestId: string) {
		try {
			const { foodRequest } = await foodRequestService.claimFoodRequestById(foodRequestId).fetch();

			setShowRequestDetailModal(false);
			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Food request claimed!</p>;
				},
			});

			history.push(`/track-requests/${foodRequest.foodRequestId}`);
		} catch (error) {
			handleError(error);
		}
	}

	const handlePromptModalAccept = async (value: string) => {
		try {
			await foodRequestService.declineFoodRequest(foodRequestIdToShow, { declineReason: value }).fetch();

			setShowDeclineModal(false);
			setShowRequestDetailModal(false);
			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Food request declined.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		}
	};

	return (
		<>
			<PromptModal
				title="Reason"
				show={showDeclineModal}
				onHide={() => {
					setShowDeclineModal(false);
				}}
				onAccept={handlePromptModalAccept}
			/>

			<RequestDetailModal
				foodRequestId={foodRequestIdToShow}
				show={showRequestDetailModal}
				onHide={() => {
					setShowRequestDetailModal(false);
				}}
				onClaim={handleRequestDetailModalClaim}
				onDecline={() => {
					setShowDeclineModal(true);
				}}
			/>

			<Container className="pt-9 pb-9">
				<Row>
					<Col>
						<div className="mb-2">
							<h1 className="mb-0 d-inline-block mr-2">Open Requests</h1>
							<p className="mb-0 d-inline-block text-roboto-condensed">
								{totalEntries} total
								{Object.keys(filters).length > 0 && (
									<span>, {totalMatchingEntries} matching filters</span>
								)}
							</p>
						</div>
						<hr className="mb-6" />
					</Col>
				</Row>

				<Row>
					<Col lg={3}>
						<Filters showPostedBy={false} showStatus={false} filters={filters} onChange={setFilters} />
					</Col>
					<Col lg={9}>
						{totalEntries === 0 && Object.keys(filters).length === 0 ? (
							<Row className="pt-18 pb-18">
								<Col>
									<div className="text-center">
										<CookingIcon width={90} height={90} className="mb-5" />
										<h2 className="mb-1">We're fresh out of requests</h2>
										<p className="mb-4">
											We currently have no open requests for food in the system.
										</p>
										<Link to="/post-request/basics">Post A New Request</Link>
									</div>
								</Col>
							</Row>
						) : totalEntries === 0 && Object.keys(filters).length > 0 ? (
							<TableNoMatch
								message="There are currently no requests that match this combination of filters."
								showButton
								onResetClick={() => {
									setFilters({});
								}}
							/>
						) : (
							<>
								<TableRenderer<FoodRequestModel>
									className="d-none d-md-block"
									isLoading={tableIsLoading}
									tableRowsData={tableRowData}
									tableHeaderRowRenderer={() => {
										return (
											<BrowseRequestsTableHeaderRow
												sortDirection={sortDirection}
												onSort={handleSort}
											/>
										);
									}}
									tableBodyRowRenderer={(rowData) => {
										return (
											<BrowseRequestsTableBodyRow
												key={rowData.foodRequestId}
												type={rowData.foodRequestTypeId}
												posted={rowData.createdDescription}
												urgent={rowData.urgentNeedId === YES_NO_UNKNOWN.YES}
												needsSupport={rowData.requestDurationDays > 7}
												transportationMethod={rowData.recipientToPickup ? 'Pickup' : 'Delivery'}
												restrictions={getRestrictionsAndEligibility(rowData)}
												onClick={() => {
													handleTableRowClick(rowData);
												}}
											/>
										);
									}}
								/>

								<div className="d-block d-md-none">
									{tableRowData.map((rowData) => {
										return (
											<BrowseRequestsMobileRow
												key={rowData.foodRequestId}
												type={rowData.foodRequestTypeId}
												posted={rowData.createdDescription}
												urgent={rowData.urgentNeedId === YES_NO_UNKNOWN.YES}
												needsSupport={rowData.requestDurationDays > 7}
												transportationMethod={rowData.recipientToPickup ? 'Pickup' : 'Delivery'}
												restrictions={getRestrictionsAndEligibility(rowData)}
												onClick={() => {
													handleTableRowClick(rowData);
												}}
											/>
										);
									})}
								</div>

								<TablePagination
									size={pageSize}
									page={pageIndex}
									total={totalEntries}
									onClick={setPageIndex}
								/>
							</>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};
