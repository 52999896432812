import { InstitutionModel } from './institution-models';
import { ROLE_ID } from './role-models';

export enum NOTIFICATION_METHODS {
	EMAIL = 'EMAIL',
	TEXT = 'TEXT',
	BOTH = 'BOTH',
	NONE = 'NONE',
}

export interface AccountModel {
	accountId: string;
	roleId: ROLE_ID;
	name: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber?: string;
	phoneNumberDescription?: string;
	notificationTypeId: NOTIFICATION_METHODS;
	institution: InstitutionModel;
	timeZone: string;
	locale: string;
	created: string;
	createdDescription: string;
	institutionRequestsCount?: number;
	canDeliver: boolean;
	reportsCapable: boolean;
	allReports: boolean;
}
