import Cookies from 'js-cookie';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Badge, Button, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { COOKIES } from '@/core/models';
import { useAccount } from '@/hooks';

import { mediaQueries, theme } from '@/jss';

import { ReactComponent as FastLogoMobile } from '@/assets/fast-logo-mobile.svg';
import { ReactComponent as MenuIcon } from '@/assets/menu.svg';
import { ReactComponent as UserIcon } from '@/assets/user.svg';
import { ReactComponent as OrganizationIcon } from '@/assets/organization.svg';
import { ReactComponent as SettingsIcon } from '@/assets/settings.svg';
import { ReactComponent as SignOutIcon } from '@/assets/sign-out.svg';

const useStyles = createUseStyles({
	desktopHeader: {
		backgroundColor: theme.colors.blueThree,
		[mediaQueries.md]: {
			display: 'none',
		},
	},
	mobileHeader: {
		display: 'none',
		backgroundColor: theme.colors.blueThree,
		[mediaQueries.md]: {
			display: 'block',
		},
		'& .dropdown-toggle:after': {
			content: 'none',
		},
		'& .dropdown-menu': {
			border: 0,
			borderRadius: 0,
			boxShadow: '0px 2px 8px -4px rgba(0, 0, 0, 0.24)',
		},
		'& .dropdown-item': {
			padding: '15px 25px',
			color: theme.colors.blueThree,
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[19],
		},
	},
	mobileFastLogo: {
		'& path': {
			fill: theme.colors.white,
		},
	},
	icon: {
		opacity: 0.5,
		marginRight: 5,
	},
});

export const Header: FC = () => {
	const history = useHistory();
	const classes = useStyles();
	const { account } = useAccount();

	function handleSignOutButtonClick() {
		Cookies.remove(COOKIES.ACCESS_TOKEN);
		history.push('/sign-in');
	}

	return (
		<>
			<header className={classes.desktopHeader}>
				<Container>
					<Row>
						<Col>
							<div className="d-flex justify-content-between">
								<div className="d-flex">
									<Link
										className="d-flex align-items-center text-white pt-3 pb-3 mr-9"
										to="/account-settings"
									>
										<UserIcon className={classes.icon} />
										{account?.name}
									</Link>
									<Link
										className="d-flex align-items-center text-white pt-3 pb-3"
										to="/account-settings"
									>
										<OrganizationIcon className={classes.icon} />
										{account?.institution.name}
									</Link>
								</div>
								<div className="d-flex">
									<Link
										className="d-flex align-items-center text-white pt-3 pb-3 mr-9"
										to="/account-settings"
									>
										<SettingsIcon className={classes.icon} />
										Account Settings
									</Link>
									<Button
										className="d-flex align-items-center text-white pt-3 pb-3"
										variant="link"
										onClick={handleSignOutButtonClick}
									>
										<SignOutIcon className={classes.icon} />
										Sign Out
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</header>
			<header className={classes.mobileHeader}>
				<Container>
					<Row>
						<Col>
							<div className="d-flex align-items-center justify-content-between">
								<Link to="/">
									<FastLogoMobile className={classes.mobileFastLogo} />
								</Link>
								<div>
									<Dropdown>
										<Dropdown.Toggle
											as={Button}
											variant="link"
											className="d-flex align-items-center text-white pt-3 pb-3"
										>
											<MenuIcon className={classes.icon} />
											Menu
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item
												onSelect={() => {
													history.push('/browse-requests');
												}}
											>
												Browse Open Requests
											</Dropdown.Item>
											<Dropdown.Item
												onSelect={() => {
													history.push('/post-request/basics');
												}}
											>
												Post A New Request
											</Dropdown.Item>
											<Dropdown.Item
												onSelect={() => {
													history.push('/track-requests');
												}}
											>
												Track Our Requests{' '}
												<Badge pill variant="secondary" className="ml-1">
													{account?.institutionRequestsCount || 0}
												</Badge>
											</Dropdown.Item>
											<Dropdown.Item
												onSelect={() => {
													history.push('/account-settings');
												}}
											>
												Account Settings
											</Dropdown.Item>
											<Dropdown.Item onSelect={handleSignOutButtonClick}>Sign Out</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</header>
		</>
	);
};
