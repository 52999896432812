export enum REQUEST_STATUS {
	OPEN = 'OPEN',
	CLAIMED = 'CLAIMED',
	AWAITING_PICKUP = 'AWAITING_PICKUP',
	FULFILLED_PENDING_DELIVERY = 'FULFILLED_PENDING_DELIVERY',
	FULFILLED = 'FULFILLED',
	CANCELED = 'CANCELLED',
	UNCLAIMED = 'UNCLAIMED',
	UNREACHABLE = 'UNREACHABLE',
}

export const requestStatus = {
	[REQUEST_STATUS.OPEN]: {
		requestStatusId: REQUEST_STATUS.OPEN,
		requestStatusTitle: 'Open',
	},
	[REQUEST_STATUS.CLAIMED]: {
		requestStatusId: REQUEST_STATUS.CLAIMED,
		requestStatusTitle: 'Claimed',
	},
	[REQUEST_STATUS.AWAITING_PICKUP]: {
		requestStatusId: REQUEST_STATUS.AWAITING_PICKUP,
		requestStatusTitle: 'Awaiting pickup',
	},
	[REQUEST_STATUS.FULFILLED_PENDING_DELIVERY]: {
		requestStatusId: REQUEST_STATUS.FULFILLED_PENDING_DELIVERY,
		requestStatusTitle: 'Fulfilled pending delivery',
	},
	[REQUEST_STATUS.FULFILLED]: {
		requestStatusId: REQUEST_STATUS.FULFILLED,
		requestStatusTitle: 'Fulfilled',
	},
	[REQUEST_STATUS.CANCELED]: {
		requestStatusId: REQUEST_STATUS.CANCELED,
		requestStatusTitle: 'Canceled',
	},
	[REQUEST_STATUS.UNCLAIMED]: {
		requestStatusId: REQUEST_STATUS.UNCLAIMED,
		requestStatusTitle: 'Unclaimed',
	},
	[REQUEST_STATUS.UNREACHABLE]: {
		requestStatusId: REQUEST_STATUS.UNREACHABLE,
		requestStatusTitle: 'Unreachable',
	},
};
