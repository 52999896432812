import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { useAccount, useQuery } from '@/hooks';

import { AsyncPage, DeliveryInlineAlert, InlineAlert, RequestDetailTemplate } from '@/components';

import { foodRequestService } from '@/core/services';
import { FoodRequestModel, foodRequestTypes, FOOD_REQUEST_TYPE, REQUEST_STATUS } from '@/core/models';
import { constructUrl } from '@/core/utils';

export const RequestDetail: FC = () => {
	const { foodRequestId } = useParams<{ foodRequestId: string }>();
	const { account } = useAccount();
	const query = useQuery();
	const postSuccess = query.get('postSuccess') === 'true';
	const editSuccess = query.get('editSuccess') === 'true';

	const [foodRequest, setFoodRequest] = useState<FoodRequestModel>();

	const [isFromPostedByOrganization, setIsFromPostedByOrganization] = useState(false);
	const [isFromClaimedByOrganization, setIsFromClaimedByOrganization] = useState(false);
	const [isCompleteState, setIsCompleteState] = useState(false);

	const fetchData = useCallback(async () => {
		const response = await foodRequestService.getFoodRequestById(foodRequestId).fetch();
		setFoodRequest(response.foodRequest);
	}, [foodRequestId]);

	useEffect(() => {
		if (!foodRequest) {
			return;
		}

		setIsFromPostedByOrganization(
			foodRequest.createdBy.account.institution.institutionId === account?.institution.institutionId
		);
		setIsFromClaimedByOrganization(
			foodRequest.claimedBy?.account.institution.institutionId === account?.institution.institutionId
		);
		setIsCompleteState(
			foodRequest.foodRequestStatusId === REQUEST_STATUS.FULFILLED ||
				foodRequest.foodRequestStatusId === REQUEST_STATUS.FULFILLED_PENDING_DELIVERY ||
				foodRequest.foodRequestStatusId === REQUEST_STATUS.UNREACHABLE
		);
	}, [account, foodRequest]);

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-9">
				{foodRequest &&
					foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY &&
					account?.institution.institutionId !== foodRequest.createdBy.account.institution.institutionId &&
					!foodRequest.recipientToPickup && (
						<Row>
							<Col>
								<DeliveryInlineAlert />
							</Col>
						</Row>
					)}
				<Row>
					<Col>
						<div className="mb-2 d-flex justify-content-between align-items-end">
							<h1 className="mb-0">
								Request for{' '}
								{foodRequest && foodRequestTypes[foodRequest.foodRequestTypeId].requestTypeTitle}
							</h1>
							<div className="d-flex align-items-center">
								{foodRequest?.foodRequestStatusId === REQUEST_STATUS.OPEN &&
									account?.institution.institutionId === foodRequest.institutionId && (
										<Link
											className="d-block"
											to={constructUrl('/post-request/basics', {
												foodRequestId: foodRequest.foodRequestId,
											})}
										>
											Edit Request
										</Link>
									)}
								{foodRequest?.foodRequestStatusId === REQUEST_STATUS.FULFILLED_PENDING_DELIVERY &&
									!foodRequest.linkedFoodRequestId && (
										<Button
											as={Link}
											variant="secondary"
											className="d-block ml-2"
											to={constructUrl('/post-request/pickup-delivery', {
												foodRequestId: foodRequest.foodRequestId,
												repostForDeliveryOnly: true,
											})}
										>
											Repost for Delivery Only
										</Button>
									)}
								{foodRequest &&
									(isFromPostedByOrganization ||
										(isFromClaimedByOrganization && isCompleteState)) && (
										<Button
											as={Link}
											variant="secondary"
											className="d-block ml-2"
											to={constructUrl('/post-request/basics', {
												foodRequestId: foodRequest.foodRequestId,
												repostAsNew: true,
											})}
										>
											Repost as New Request
										</Button>
									)}
							</div>
						</div>
						<hr className="mb-6" />
					</Col>
				</Row>
				{postSuccess && (
					<Row className="mb-7">
						<Col>
							<InlineAlert variant="success">
								<h5 className="mb-0 text-white text-center">
									Your request was posted successfully and notifications have been sent.
								</h5>
							</InlineAlert>
						</Col>
					</Row>
				)}
				{editSuccess && (
					<Row className="mb-7">
						<Col>
							<InlineAlert variant="success">
								<h5 className="mb-0 text-white text-center">
									Your request was updated successfully and notifications have been sent.
								</h5>
							</InlineAlert>
						</Col>
					</Row>
				)}
				{foodRequest && <RequestDetailTemplate foodRequest={foodRequest} onStatusUpdate={setFoodRequest} />}
			</Container>
		</AsyncPage>
	);
};
