export enum PROVIDED_BENEFITS_INFO {
	COALITION_AGAINST_HUNGER = 'COALITION_AGAINST_HUNGER',
	BENE_PHILLY = 'BENE_PHILLY',
	BOTH = 'BOTH',
	DIRECT = 'DIRECT',
	REFUSED = 'REFUSED',
	OTHER = 'OTHER',
}

export const providedBenefitsInfo = {
	[PROVIDED_BENEFITS_INFO.COALITION_AGAINST_HUNGER]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.COALITION_AGAINST_HUNGER,
		providedBenefitsInfoTitle: 'Yes, I gave them the Coalition Against Hunger hotline',
	},
	[PROVIDED_BENEFITS_INFO.BENE_PHILLY]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.BENE_PHILLY,
		providedBenefitsInfoTitle: 'Yes, I gave them the BenePhilly hotline',
	},
	[PROVIDED_BENEFITS_INFO.BOTH]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.BOTH,
		providedBenefitsInfoTitle: 'Yes, I gave them both hotlines',
	},
	[PROVIDED_BENEFITS_INFO.DIRECT]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.DIRECT,
		providedBenefitsInfoTitle: 'No, we helped (or will help) them sign up for benefits directly',
	},
	[PROVIDED_BENEFITS_INFO.REFUSED]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.REFUSED,
		providedBenefitsInfoTitle: 'No, The recipient refused benefits assistance',
	},
	[PROVIDED_BENEFITS_INFO.OTHER]: {
		providedBenefitsInfoId: PROVIDED_BENEFITS_INFO.OTHER,
		providedBenefitsInfoTitle: 'Other',
	},
};
