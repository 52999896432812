import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

import { InstitutionModel, NOTIFICATION_METHODS, ROLE_ID } from '@/core/models';
import { useAccount, useAlert, useHandleError } from '@/hooks';
import { AsyncPage, LoaderButton } from '@/components';
import { accountService } from '@/core/services';

import { ReactComponent as InfoIcon } from '@/assets/info.svg';
import { institutionsService } from '@/core/services/institutions-service';

export const AccountSettings: FC = () => {
	const handleError = useHandleError();
	const { showAlert } = useAlert();
	const { account, setAccount } = useAccount();

	const [institutionSelectOptions, setInstitutionSelectOptions] = useState<InstitutionModel[]>([]);
	const [institutionSelectValue, setInstitutionSelectValue] = useState(account?.institution.institutionId);
	const [nameInputValue, setNameInputValue] = useState(account?.name || '');
	const [emailAddressInputValue, setEmailAddressInputValue] = useState(account?.emailAddress || '');
	const [organizationNameInputValue, setOrganizationNameInputValue] = useState(account?.institution.name);
	const [phoneNumberInputValue, setPhoneNumberInputValue] = useState(account?.phoneNumberDescription || '');
	const [notificationMethodRadioValue, setNotificationMethodRadioValue] = useState<NOTIFICATION_METHODS>(
		account?.notificationTypeId || NOTIFICATION_METHODS.NONE
	);
	const [currentPasswordInputValue, setCurrentPasswordInputValue] = useState('');
	const [newPasswordInputValue, setNewPasswordInputValue] = useState('');
	const [confirmNewPasswordInputValue, setConfirmNewPasswordInputValue] = useState('');
	const [isSaving, setIsSaving] = useState(false);

	const fetchData = useCallback(async () => {
		const response = await institutionsService.getInstitutions().fetch();
		setInstitutionSelectOptions(response.institutions);
	}, []);

	const handleInstitutionSelectChange = async ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setIsSaving(true);

			const response = await accountService.changeInstitution({ institutionId: currentTarget.value }).fetch();

			setAccount(response.account);
			setInstitutionSelectValue(response.account.institution.institutionId);
		} catch (error) {
			handleError(error);
		} finally {
			setIsSaving(false);
		}
	};

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();
		setIsSaving(true);

		try {
			if (!account) {
				throw new Error('Account not found.');
			}

			const response = await accountService
				.updateAccount({
					name: nameInputValue,
					emailAddress: emailAddressInputValue,
					organizationName: organizationNameInputValue,
					phoneNumber: phoneNumberInputValue,
					notificationTypeId: notificationMethodRadioValue,
					...(currentPasswordInputValue ? { currentPassword: currentPasswordInputValue } : {}),
					...(newPasswordInputValue ? { newPassword: newPasswordInputValue } : {}),
					...(confirmNewPasswordInputValue ? { confirmNewPassword: confirmNewPasswordInputValue } : {}),
				})
				.fetch();

			setAccount(response.account);

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Changes saved.</p>;
				},
			});
		} catch (error) {
			handleError(error);
		}

		setIsSaving(false);
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-6">
				<Row>
					<Col>
						<div className="mb-2 d-flex justify-content-between align-items-center">
							<h1 className="mb-0">Account Settings</h1>
							{account?.roleId === ROLE_ID.DYNAMIC_ADMIN && (
								<Form.Group className="d-flex align-items-center">
									<Form.Label className="mb-0 mr-2">Institution: </Form.Label>
									<Form.Control
										as="select"
										value={institutionSelectValue}
										onChange={handleInstitutionSelectChange}
									>
										{institutionSelectOptions.map((option) => {
											return (
												<option key={option.institutionId} value={option.institutionId}>
													{option.name}
												</option>
											);
										})}
									</Form.Control>
								</Form.Group>
							)}
						</div>
						<hr className="mb-6" />
					</Col>
				</Row>
				<Form onSubmit={handleFormSubmit}>
					<Row>
						<Col md={6} className="mb-10">
							<h2 className="mb-2">Basic information</h2>
							<hr className="mb-5" />
							<Form.Group className="mb-4">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									value={nameInputValue}
									onChange={(event) => {
										setNameInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Email address</Form.Label>
								<Form.Control
									type="email"
									value={emailAddressInputValue}
									onChange={(event) => {
										setEmailAddressInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-10">
							<h2 className="mb-2">Organization settings</h2>
							<hr className="mb-5" />
							<Form.Group className="mb-4">
								<Form.Label>Organization name</Form.Label>
								<Form.Control
									type="text"
									value={organizationNameInputValue}
									onChange={(event) => {
										setOrganizationNameInputValue(event.currentTarget.value);
									}}
									disabled={isSaving || account?.roleId !== ROLE_ID.ADMINISTRATOR}
								/>
							</Form.Group>
							<Form.Group className="mb-4">
								<Form.Label>FAST organization administrator</Form.Label>
								<p className="mb-4">{account?.institution.adminName}</p>
								{account?.roleId === ROLE_ID.ADMINISTRATOR && (
									<Link to="/account-settings/invitations" className="d-block text-roboto">
										<strong>Invite people from {account?.institution.name} to FAST</strong>
									</Link>
								)}
								{(account?.reportsCapable || account?.roleId === ROLE_ID.ADMINISTRATOR) && (
									<Link to="/download-reports" className="mt-2 d-block text-roboto">
										<strong>Download reports</strong>
									</Link>
								)}
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-10">
							<h2 className="mb-2">Contact information</h2>
							<hr className="mb-5" />
							<Form.Group className="mb-4">
								<Form.Label>Phone number</Form.Label>
								<p>
									Organizations that claim your requests can use this number to contact you if they
									have questions about the request.
								</p>
								<Form.Control
									as={Cleave}
									options={{ phone: true, phoneRegionCode: 'us' }}
									type="tel"
									value={phoneNumberInputValue}
									onChange={(event) => {
										setPhoneNumberInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>How do you want to receive notifications?</Form.Label>
								<p>
									Notifications will include requests for help from other organizations and updates on
									requests that you have posted.
								</p>
								{Object.values(NOTIFICATION_METHODS).map((noficationMethod) => {
									return (
										<Form.Check
											key={noficationMethod}
											className="mb-2"
											type="radio"
											name="notification-method"
											id={`notification-method--${noficationMethod}`}
											label={noficationMethod}
											value={noficationMethod}
											checked={noficationMethod === notificationMethodRadioValue}
											onChange={() => {
												setNotificationMethodRadioValue(noficationMethod);
											}}
											disabled={isSaving}
										/>
									);
								})}
							</Form.Group>
						</Col>
						<Col md={6} className="mb-10">
							<h2 className="mb-2">Password settings</h2>
							<hr className="mb-5" />
							<Form.Group className="mb-4">
								<Form.Label>Current password</Form.Label>
								<Form.Control
									type="password"
									value={currentPasswordInputValue}
									onChange={(event) => {
										setCurrentPasswordInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
							<Form.Group className="mb-4">
								<Form.Label>New password</Form.Label>
								<p>Passwords must be 8 or more characters long</p>
								<Form.Control
									type="password"
									value={newPasswordInputValue}
									onChange={(event) => {
										setNewPasswordInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
							<Form.Group className="mb-4">
								<Form.Label>Confirm your new password</Form.Label>
								<Form.Control
									type="password"
									value={confirmNewPasswordInputValue}
									onChange={(event) => {
										setConfirmNewPasswordInputValue(event.currentTarget.value);
									}}
									disabled={isSaving}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mb-6">
						<Col>
							<Link
								className="mb-2 d-flex align-items-center"
								to="/privacy-policy"
								target="_blank"
								rel="noreferrer noopener"
							>
								<InfoIcon width={16} className="mr-1" />
								Privacy Policy
							</Link>
							<Link
								className="d-flex align-items-center"
								to="/terms-of-use"
								target="_blank"
								rel="noreferrer noopener"
							>
								<InfoIcon width={16} className="mr-1" />
								Terms and Conditions of Use
							</Link>
						</Col>
					</Row>
					<Row>
						<Col>
							<hr className="mb-6" />
							<div className="text-right">
								<LoaderButton type="submit" isLoading={isSaving}>
									Save changes
								</LoaderButton>
							</div>
						</Col>
					</Row>
				</Form>
			</Container>
		</AsyncPage>
	);
};
