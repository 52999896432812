import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import Color from 'color';

import { REQUEST_STATUS } from '@/core/models';

import { theme } from '@/jss';

function getStatusColor(requestStatus: REQUEST_STATUS) {
	switch (requestStatus) {
		case REQUEST_STATUS.OPEN:
			return theme.colors.redOne;
		case REQUEST_STATUS.CLAIMED:
			return theme.colors.greenOne;
		case REQUEST_STATUS.AWAITING_PICKUP:
		case REQUEST_STATUS.FULFILLED_PENDING_DELIVERY:
			return theme.colors.blueTwo;
		case REQUEST_STATUS.FULFILLED:
			return theme.colors.blueThree;
		case REQUEST_STATUS.CANCELED:
		case REQUEST_STATUS.UNCLAIMED:
		case REQUEST_STATUS.UNREACHABLE:
			return theme.colors.grayFive;
		default:
			return theme.colors.grayFive;
	}
}

const useStyles = createUseStyles({
	statusSelectOuter: ({ value, disabled }: { value: REQUEST_STATUS; disabled: boolean }) => {
		let statusColor = getStatusColor(value);

		return {
			position: 'relative',
			'&:before, &:after': {
				width: 6,
				height: 1,
				right: 16,
				top: '50%',
				position: 'absolute',
				pointerEvents: 'none',
				backgroundColor: statusColor,
				content: disabled ? '' : '""',
			},
			'&:before': {
				transform: 'translateX(-2px) rotate(45deg)',
			},
			'&:after': {
				transform: 'translateX(2px) rotate(-45deg)',
			},
		};
	},
	statusSelect: ({ value, large }: { value: REQUEST_STATUS; large: boolean }) => {
		let statusColor = getStatusColor(value);

		return {
			height: large ? 38 : 24,
			width: '100%',
			borderRadius: 500,
			appearance: 'none',
			color: statusColor,
			padding: large ? '0 32px 0 16px' : '0 32px 0 8px',
			backgroundColor: 'transparent',
			fontSize: large ? theme.fonts.sizes[16] : theme.fonts.sizes[14],
			border: `${large ? 2 : 1}px solid ${statusColor}`,
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
			fontWeight: large ? theme.fonts.robotoCondensed.weights.bold : theme.fonts.robotoCondensed.weights.regular,
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: Color(statusColor).alpha(0.16).string(),
			},
			'&:focus': {
				outline: 'none',
				borderColor: 'inherit',
			},
			'&:disabled': {
				'&:hover': {
					cursor: 'default',
					backgroundColor: 'transparent',
				},
			},
		};
	},
});

interface StatusSelectProps {
	value: REQUEST_STATUS;
	onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
	disabled?: boolean;
	large?: boolean;
}

export const StatusSelect: FC<StatusSelectProps> = ({ value, onChange, disabled, large, children }) => {
	const classes = useStyles({
		value,
		disabled,
		large,
	});

	function handleClick(event: React.MouseEvent<HTMLSelectElement>) {
		event.stopPropagation();
	}

	return (
		<div className={classes.statusSelectOuter}>
			<Form.Control
				as="select"
				bsPrefix="no-theme"
				className={classes.statusSelect}
				value={value}
				onClick={handleClick}
				onChange={onChange}
				disabled={disabled}
			>
				{children}
			</Form.Control>
		</div>
	);
};
