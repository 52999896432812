import { cloneDeep, debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import {
	CityModel,
	dietaryRestrictions,
	foodRequestTypes,
	healthBenefits,
	InstitutionModel,
	postedBy,
	requestStatus,
} from '@/core/models';

import { useHandleError } from '@/hooks';
import { FiltersModal, Loader, ZipCodeModal } from '@/components';
import { addOrRemoveArrayFromObject, addOrRemoveValueFromSourceArray, isValidZipCode } from '@/core/utils';
import { foodRequestService, GetFoodRequestQueryParameters } from '@/core/services';

export interface FiltersListProps {
	showSearchByName?: boolean;
	showPostedBy?: boolean;
	showType?: boolean;
	showStatus?: boolean;
	showPickupOrDelivery?: boolean;
	showRecipientLocation?: boolean;
	showRestrictionsAndEligibility?: boolean;
	showRecipientHealthSystem?: boolean;
	filters: GetFoodRequestQueryParameters;
	onChange(filters: GetFoodRequestQueryParameters): void;
	isMobile?: boolean;
}

export const FiltersList: FC<FiltersListProps> = ({
	showSearchByName = false,
	showPostedBy = true,
	showType = true,
	showStatus = true,
	showPickupOrDelivery = true,
	showRecipientLocation = true,
	showRestrictionsAndEligibility = true,
	showRecipientHealthSystem = true,
	filters,
	onChange,
	isMobile,
}) => {
	const handleError = useHandleError();

	const [isLoading, setIsLoading] = useState(false);
	const [showZipCodeModal, setShowZipCodeModal] = useState(false);
	const [showFiltersModal, setShowFiltersModal] = useState(false);
	const [healthSystems, setHealthSystems] = useState<InstitutionModel[]>([]);
	const [cities, setCities] = useState<CityModel[]>([]);
	const [zipCodeInputValue, setZipCodeInputValue] = useState('');

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);

			try {
				const response = await foodRequestService.getFoodRequestFilters().fetch();
				setHealthSystems(response.filters.healthSystems);
				setCities(response.filters.cities);
			} catch (error) {
				handleError(error);
			}

			setIsLoading(false);
		}

		fetchData();
	}, [handleError]);

	const [searchInputValue, setSearchInputValue] = useState('');

	const debouncedSearch = useCallback(
		debounce((value: string) => {
			const filtersClone = cloneDeep(filters);

			if (value) {
				filtersClone.recipientNameQuery = value;
			} else {
				delete filtersClone.recipientNameQuery;
			}

			onChange(filtersClone);
		}, 300),
		[]
	);

	useEffect(() => {
		debouncedSearch(searchInputValue);
	}, [searchInputValue]);

	return (
		<>
			{!isMobile && (
				<ZipCodeModal
					zipCodes={filters.postalCode ? filters.postalCode : []}
					show={showZipCodeModal}
					onHide={() => {
						setShowZipCodeModal(false);
					}}
					onSave={(postalCodes) => {
						const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
							filters,
							'postalCode',
							postalCodes
						);

						onChange(updatedFilters);
						setShowZipCodeModal(false);
					}}
				/>
			)}

			{!isMobile && (
				<FiltersModal
					show={showFiltersModal}
					dietaryRestrictionIds={filters.dietaryRestrictionId ? filters.dietaryRestrictionId : []}
					healthBenefitIds={filters.healthBenefitId ? filters.healthBenefitId : []}
					underTwentyOne={filters.underTwentyOne !== undefined ? filters.underTwentyOne : false}
					overSixty={filters.overSixty !== undefined ? filters.overSixty : false}
					lowIncome={filters.lowIncome !== undefined ? filters.lowIncome : false}
					hasInsurance={filters.hasInsurance !== undefined ? filters.hasInsurance : false}
					onHide={() => {
						setShowFiltersModal(false);
					}}
					onSave={(parameters) => {
						onChange({
							...filters,
							...{ dietaryRestrictionId: parameters.dietaryRestrictionIds },
							...{ healthBenefitId: parameters.healthBenefitIds },
							...{ lowIncome: parameters.lowIncome },
							...{ underTwentyOne: parameters.underTwentyOne },
							...{ overSixty: parameters.overSixty },
							...{ hasInsurance: parameters.hasInsurance },
						});

						setShowFiltersModal(false);
					}}
				/>
			)}

			<Form>
				{showSearchByName && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Search by Recipient's Name</Form.Label>
						<Form.Control
							type="text"
							name="search-by-name"
							value={searchInputValue}
							onChange={(event) => {
								setSearchInputValue(event.currentTarget.value);
							}}
						/>
					</Form.Group>
				)}
				{showPostedBy && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Posted By</Form.Label>
						{Object.values(postedBy).map((pb) => {
							return (
								<Form.Check
									key={pb.postedById}
									className="mb-2"
									type="checkbox"
									name="postedById"
									id={`postedById-${pb.postedById}`}
									label={pb.postedByTitle}
									value={pb.postedById}
									checked={filters.postedById ? filters.postedById.includes(pb.postedById) : false}
									onChange={(event) => {
										event.stopPropagation();

										const updatedPostedByIds = addOrRemoveValueFromSourceArray(
											pb.postedById,
											filters.postedById || []
										);

										const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
											filters,
											'postedById',
											updatedPostedByIds
										);

										onChange(updatedFilters);
									}}
								/>
							);
						})}
					</Form.Group>
				)}

				{showType && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Type</Form.Label>
						{Object.values(foodRequestTypes).map((frt) => {
							return (
								<Form.Check
									key={frt.requestTypeId}
									className="mb-2"
									type="checkbox"
									name="typeId"
									id={`typeId-${frt.requestTypeId}`}
									label={frt.requestTypeTitle}
									value={frt.requestTypeId}
									checked={filters.typeId ? filters.typeId.includes(frt.requestTypeId) : false}
									onChange={(event) => {
										event.stopPropagation();

										const updatedTypeIds = addOrRemoveValueFromSourceArray(
											frt.requestTypeId,
											filters.typeId || []
										);

										const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
											filters,
											'typeId',
											updatedTypeIds
										);

										onChange(updatedFilters);
									}}
								/>
							);
						})}
					</Form.Group>
				)}

				{showStatus && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Status</Form.Label>
						{Object.values(requestStatus).map((rs) => {
							return (
								<Form.Check
									key={rs.requestStatusId}
									className="mb-2"
									type="checkbox"
									name="statusId"
									id={`statusId-${rs.requestStatusId}`}
									label={rs.requestStatusTitle}
									value={rs.requestStatusId}
									checked={filters.statusId ? filters.statusId.includes(rs.requestStatusId) : false}
									onChange={(event) => {
										event.stopPropagation();

										const updatedStatusIds = addOrRemoveValueFromSourceArray(
											rs.requestStatusId,
											filters.statusId || []
										);

										const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
											filters,
											'statusId',
											updatedStatusIds
										);

										onChange(updatedFilters);
									}}
								/>
							);
						})}
					</Form.Group>
				)}

				{showPickupOrDelivery && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Pickup or Delivery</Form.Label>
						<Form.Check
							className="mb-2"
							type="checkbox"
							name="transportationId"
							id="transportationId-pickup"
							label="Pickup"
							value="PICKUP"
							checked={filters.forPickup === true}
							onChange={(event) => {
								event.stopPropagation();

								const filtersClone = cloneDeep(filters);

								if (event.currentTarget.checked) {
									filtersClone.forPickup = true;
								} else {
									delete filtersClone.forPickup;
								}

								onChange(filtersClone);
							}}
						/>
						<Form.Check
							className="mb-2"
							type="checkbox"
							name="transportationId"
							id="transportationId-delivery"
							label="Delivery"
							value="DELIVERY"
							checked={filters.forPickup === false}
							onChange={(event) => {
								event.stopPropagation();

								const filtersClone = cloneDeep(filters);

								if (event.currentTarget.checked) {
									filtersClone.forPickup = false;
								} else {
									delete filtersClone.forPickup;
								}

								onChange(filtersClone);
							}}
						/>
					</Form.Group>
				)}

				{showRecipientLocation && (
					<Form.Group className="mb-6">
						<Form.Label className="mb-3">Recipient Location</Form.Label>
						{isLoading ? (
							<Loader />
						) : (
							<>
								{cities.map((city) => {
									return (
										<Form.Check
											key={city.cityId}
											className="mb-2"
											type="checkbox"
											name={`locationId-${city.cityId}`}
											id={`locationId-${city.cityId}`}
											label={city.description}
											value={city.cityId}
											checked={filters.cityId ? filters.cityId.includes(city.cityId) : false}
											onChange={(event) => {
												event.stopPropagation();

												const updatedCityIds = addOrRemoveValueFromSourceArray(
													city.cityId,
													filters.cityId || []
												);

												const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
													filters,
													'cityId',
													updatedCityIds
												);

												onChange(updatedFilters);
											}}
										/>
									);
								})}
							</>
						)}
						{isMobile ? (
							<div>
								<div className="d-flex">
									<Form.Control
										type="search"
										value={zipCodeInputValue}
										placeholder="Specific Zip Codes"
										onChange={(event) => {
											event.stopPropagation();

											setZipCodeInputValue(event.currentTarget.value);
										}}
									/>
									<Button
										className="px-3"
										variant="link"
										onClick={(event) => {
											event.stopPropagation();

											if (!isValidZipCode(zipCodeInputValue)) {
												return;
											}

											if (filters.postalCode && filters.postalCode.includes(zipCodeInputValue)) {
												return;
											}

											const updatedPostalCodes = addOrRemoveValueFromSourceArray(
												zipCodeInputValue,
												filters.postalCode || []
											);

											const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
												filters,
												'postalCode',
												updatedPostalCodes
											);

											onChange(updatedFilters);
											setZipCodeInputValue('');
										}}
									>
										Add
									</Button>
								</div>
								{filters.postalCode && (
									<Form.Group>
										<ul className="mt-3 list-unstyled">
											{filters.postalCode.map((zipCode, index) => {
												if (!filters.postalCode) {
													return null;
												}

												const isLast = index === filters.postalCode.length - 1;

												return (
													<li key={zipCode} className={isLast ? 'mb-0' : 'mb-2'}>
														<div className="d-flex align-items-center">
															<p className="mb-0 mr-3">{zipCode}</p>
															<Button
																variant="link"
																className="text-danger"
																onClick={(event) => {
																	event.stopPropagation();

																	const updatedPostalCodes = addOrRemoveValueFromSourceArray(
																		zipCode,
																		filters.postalCode || []
																	);

																	const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
																		filters,
																		'postalCode',
																		updatedPostalCodes
																	);

																	onChange(updatedFilters);
																}}
															>
																Remove
															</Button>
														</div>
													</li>
												);
											})}
										</ul>
									</Form.Group>
								)}
							</div>
						) : (
							<Form.Check
								className="mb-2"
								type="checkbox"
								name="locationId"
								id="locationId__specific-zip-codes"
							>
								<Form.Check.Input
									value="SPECIFIC_ZIP_CODES"
									checked={filters.postalCode ? filters.postalCode.length > 0 : false}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										event.stopPropagation();

										if (event.currentTarget.checked) {
											setShowZipCodeModal(true);
										} else {
											const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
												filters,
												'postalCode',
												[]
											);

											onChange(updatedFilters);
										}
									}}
								/>
								<Form.Check.Label>
									<span className="text-blue-two">Specific zip codes</span>
								</Form.Check.Label>
							</Form.Check>
						)}
					</Form.Group>
				)}

				{showRestrictionsAndEligibility && (
					<>
						{isMobile ? (
							<Form.Group className="mb-6">
								<Form.Label className="mb-3">Dietary Restrictions</Form.Label>
								<div className="mb-2">
									{Object.values(dietaryRestrictions).map((dr) => {
										return (
											<Form.Check
												key={dr.dietaryRestrictionId}
												bsPrefix="fast-form__check-badge"
												type="checkbox"
												name="dietary-restriction"
												id={`dietary-restriction-${dr.dietaryRestrictionId}`}
												label={dr.dietaryRestrictionTitle}
												value={dr.dietaryRestrictionId}
												checked={
													filters.dietaryRestrictionId
														? filters.dietaryRestrictionId.includes(dr.dietaryRestrictionId)
														: false
												}
												onChange={(event) => {
													event.stopPropagation();

													const updatedDeitaryRestrictionIds = addOrRemoveValueFromSourceArray(
														dr.dietaryRestrictionId,
														filters.dietaryRestrictionId || []
													);

													const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
														filters,
														'dietaryRestrictionId',
														updatedDeitaryRestrictionIds
													);

													onChange(updatedFilters);
												}}
												inline
											/>
										);
									})}
								</div>
								<Form.Label className="mb-3">Benifits Eligibility</Form.Label>
								<div className="mb-2">
									{Object.values(healthBenefits).map((hb) => {
										return (
											<Form.Check
												key={hb.healthBenefitId}
												bsPrefix="fast-form__check-badge"
												type="checkbox"
												name="health-benefit"
												id={`health-benefit-${hb.healthBenefitId}`}
												label={hb.healthBenefitAbbreviation}
												value={hb.healthBenefitId}
												checked={
													filters.healthBenefitId
														? filters.healthBenefitId.includes(hb.healthBenefitId)
														: false
												}
												onChange={(event) => {
													event.stopPropagation();

													const updatedHealthBenefitIds = addOrRemoveValueFromSourceArray(
														hb.healthBenefitId,
														filters.healthBenefitId || []
													);

													const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
														filters,
														'healthBenefitId',
														updatedHealthBenefitIds
													);

													onChange(updatedFilters);
												}}
												inline
											/>
										);
									})}
								</div>
								<Form.Label className="mb-3">Other Eligibility Factors</Form.Label>
								<div className="mb-2">
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="under-twenty-one"
										label="Age Under 21"
										value="underTwentyOne"
										checked={filters.underTwentyOne}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.underTwentyOne = true;
											} else {
												delete filtersClone.underTwentyOne;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="over-sixty"
										label="Age Over 60"
										value="overSixty"
										checked={filters.overSixty}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.overSixty = true;
											} else {
												delete filtersClone.overSixty;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="low-income"
										label="Low Income"
										value="lowIncome"
										checked={filters.lowIncome}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.lowIncome = true;
											} else {
												delete filtersClone.lowIncome;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="has-insurance"
										label="Has Insurance"
										value="hasInsurance"
										checked={filters.hasInsurance}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.hasInsurance = true;
											} else {
												delete filtersClone.hasInsurance;
											}

											onChange(filtersClone);
										}}
										inline
									/>
								</div>
							</Form.Group>
						) : (
							<Form.Group className="mb-6">
								<Form.Label className="mb-3">Restrictions &amp; Eligibility</Form.Label>
								<div className="mb-2">
									{Object.values(healthBenefits)
										.slice(0, 2)
										.map((hb) => {
											return (
												<Form.Check
													key={hb.healthBenefitId}
													bsPrefix="fast-form__check-badge"
													type="checkbox"
													name="health-benefit"
													id={`health-benefit-${hb.healthBenefitId}`}
													label={hb.healthBenefitAbbreviation}
													value={hb.healthBenefitId}
													checked={
														filters.healthBenefitId
															? filters.healthBenefitId.includes(hb.healthBenefitId)
															: false
													}
													onChange={(event) => {
														event.stopPropagation();

														const updatedHealthBenefitIds = addOrRemoveValueFromSourceArray(
															hb.healthBenefitId,
															filters.healthBenefitId || []
														);

														const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
															filters,
															'healthBenefitId',
															updatedHealthBenefitIds
														);

														onChange(updatedFilters);
													}}
													inline
												/>
											);
										})}
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="low-income"
										label="Low Income"
										value="lowIncome"
										checked={filters.lowIncome}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.lowIncome = true;
											} else {
												delete filtersClone.lowIncome;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="under-twenty-one"
										label="Age Under 21"
										value="underTwentyOne"
										checked={filters.underTwentyOne}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.underTwentyOne = true;
											} else {
												delete filtersClone.underTwentyOne;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									<Form.Check
										bsPrefix="fast-form__check-badge"
										type="checkbox"
										id="over-sixty-five"
										label="Age Over 60"
										value="overSixty"
										checked={filters.overSixty}
										onChange={(event) => {
											event.stopPropagation();

											const filtersClone = cloneDeep(filters);

											if (event.currentTarget.checked) {
												filtersClone.overSixty = true;
											} else {
												delete filtersClone.overSixty;
											}

											onChange(filtersClone);
										}}
										inline
									/>
									{Object.values(dietaryRestrictions)
										.slice(0, 2)
										.map((dr) => {
											return (
												<Form.Check
													key={dr.dietaryRestrictionId}
													bsPrefix="fast-form__check-badge"
													type="checkbox"
													name="dietary-restriction"
													id={`dietary-restriction-${dr.dietaryRestrictionId}`}
													label={dr.dietaryRestrictionTitle}
													value={dr.dietaryRestrictionId}
													checked={
														filters.dietaryRestrictionId
															? filters.dietaryRestrictionId.includes(
																	dr.dietaryRestrictionId
															  )
															: false
													}
													onChange={(event) => {
														event.stopPropagation();

														const updatedDeitaryRestrictionIds = addOrRemoveValueFromSourceArray(
															dr.dietaryRestrictionId,
															filters.dietaryRestrictionId || []
														);

														const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
															filters,
															'dietaryRestrictionId',
															updatedDeitaryRestrictionIds
														);

														onChange(updatedFilters);
													}}
													inline
												/>
											);
										})}
								</div>
								<Button
									variant="link"
									onClick={() => {
										setShowFiltersModal(true);
									}}
								>
									See 19 more
								</Button>
							</Form.Group>
						)}
					</>
				)}

				{showRecipientHealthSystem && (
					<Form.Group>
						<Form.Label className="mb-3">Recipient Health System</Form.Label>
						{isLoading ? (
							<Loader />
						) : (
							<>
								{healthSystems.map((hs) => {
									return (
										<Form.Check
											key={hs.institutionId}
											className="mb-2"
											type="checkbox"
											name="healthSystemId"
											id={`healthSystemId-${hs.institutionId}`}
											label={hs.name}
											value={hs.institutionId}
											checked={
												filters.healthSystemId
													? filters.healthSystemId.includes(hs.institutionId)
													: false
											}
											onChange={(event) => {
												event.stopPropagation();

												const updatedHealthSystemIds = addOrRemoveValueFromSourceArray(
													hs.institutionId,
													filters.healthSystemId || []
												);

												const updatedFilters = addOrRemoveArrayFromObject<GetFoodRequestQueryParameters>(
													filters,
													'healthSystemId',
													updatedHealthSystemIds
												);

												onChange(updatedFilters);
											}}
										/>
									);
								})}
							</>
						)}
					</Form.Group>
				)}
			</Form>
		</>
	);
};
