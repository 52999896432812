import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { theme } from '@/jss';

const useStyles = createUseStyles({
	'@keyframes countdown': {
		to: {
			strokeDashoffset: 0,
		},
	},
	baseTimer: ({ size }: { size: number }) => ({
		width: size,
		height: size,
		position: 'relative',
	}),
	svgMain: {
		display: 'block',
	},
	svgCircle: ({ circumference, durationInSeconds }: { circumference: number; durationInSeconds: number }) => ({
		transformOrigin: 'center',
		transform: 'rotate(-90deg)',
		strokeDashoffset: circumference,
		animationFillMode: 'forwards',
		animationDirection: 'reverse',
		animationTimingFunction: 'linear',
		animation: `$countdown ${durationInSeconds}s`,
	}),
	timerLabel: {
		top: '50%',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},
});

interface TimeoutCircleProps {
	className?: string;
	durationInSeconds: number;
	size: number;
	strokeColor?: string;
	strokeWidth: number;
	trackColor?: string;
}

export const TimeoutCircle: FC<TimeoutCircleProps> = ({
	className,
	durationInSeconds,
	size,
	strokeColor = theme.colors.blueTwo,
	strokeWidth,
	trackColor = theme.colors.grayThree,
}) => {
	const radius = size / 2;
	const normalizedRadius = radius - strokeWidth / 2;
	const circumference = normalizedRadius * 2 * Math.PI;

	const classes = useStyles({ circumference, durationInSeconds, size });

	return (
		<div className={classNames([classes.baseTimer, className])}>
			<svg
				className={classes.svgMain}
				width={size}
				height={size}
				viewBox={`0 0 ${size} ${size}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<circle
						fill="transparent"
						stroke={trackColor}
						strokeWidth={strokeWidth}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
					/>
					<circle
						fill="transparent"
						strokeLinecap="round"
						stroke={strokeColor}
						strokeWidth={strokeWidth}
						strokeDasharray={circumference}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
						className={classes.svgCircle}
					/>
				</g>
			</svg>
		</div>
	);
};
