import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.us';

import { dietaryRestrictions, DIETARY_RESTRICTIONS, FOOD_REQUEST_TYPE, UNPREPARED_FOOD } from '@/core/models';
import { useAlert, useRequestForm } from '@/hooks';
import { isValidPhoneNumber } from '@/core/utils';

import { ConsentModal, FadeTransition, RequiredAsterisk } from '@/components';
import { CheckboxList, FormSection, FormButtonsRow, YesNoUnsureQuestion } from '@/components/request-form';

import { ReactComponent as SaladIcon } from '@/assets/salad-outline.svg';
import { ReactComponent as AppleIcon } from '@/assets/apple-outline.svg';
import { ReactComponent as TruckIcon } from '@/assets/truck-outline.svg';

interface BasicsProps {
	initialRequestType?: FOOD_REQUEST_TYPE;
}

export const Basics: FC<BasicsProps> = ({ initialRequestType }) => {
	const history = useHistory();
	const { showAlert } = useAlert();
	const { formValues, setFormValue, setMultipleFormValues, pickupDeliveryPageIsEnabled } = useRequestForm();

	useEffect(() => {
		if (formValues.requestType) {
			return;
		}

		if (initialRequestType) {
			setFormValue('requestType', initialRequestType);
		} else {
			setFormValue('requestType', FOOD_REQUEST_TYPE.UNPREPARED_FOOD);
		}
	}, [formValues.requestType, initialRequestType, setFormValue]);

	return (
		<>
			<ConsentModal
				show={!formValues.recipientConsent}
				onContinue={() => {
					setFormValue('recipientConsent', true);
				}}
			/>

			<Row className="mb-10">
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<Form>
						<FormSection title="Basics">
							<Form.Group className="mb-6">
								<Form.Label>
									What type of request is this? <RequiredAsterisk />
								</Form.Label>
								<div className="d-md-flex">
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1 mb-1"
										id="request-type--prepared-meals"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.PREPARED_MEALS}
											checked={formValues.requestType === FOOD_REQUEST_TYPE.PREPARED_MEALS}
											onChange={() => {
												setFormValue('requestType', FOOD_REQUEST_TYPE.PREPARED_MEALS);
											}}
										/>
										<Form.Check.Label title="">
											<SaladIcon width={45} height={36} className="mb-2" />
											<div>Prepared meals</div>
											<small>(plus the option for delivery)</small>
										</Form.Check.Label>
									</Form.Check>
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1 mb-1"
										id="request-type--unprepared-food"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.UNPREPARED_FOOD}
											checked={formValues.requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD}
											onChange={() => {
												setFormValue('requestType', FOOD_REQUEST_TYPE.UNPREPARED_FOOD);
											}}
										/>
										<Form.Check.Label title="">
											<AppleIcon width={45} height={36} className="mb-2" />
											<div>Unprepared food</div>
											<small>(plus the option for delivery)</small>
										</Form.Check.Label>
									</Form.Check>
									<Form.Check
										bsPrefix="fast-form__check-request-type"
										className="flex-grow-1 mr-1"
										id="request-type--just-needs-delivery"
									>
										<Form.Check.Input
											type="radio"
											name="request-type"
											value={FOOD_REQUEST_TYPE.DELIVERY_ONLY}
											checked={formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY}
											onChange={() => {
												setFormValue('requestType', FOOD_REQUEST_TYPE.DELIVERY_ONLY);
											}}
										/>
										<Form.Check.Label title="">
											<TruckIcon width={45} height={36} className="mb-2" />
											<div>Just need delivery</div>
											<small>(you don't need food)</small>
										</Form.Check.Label>
									</Form.Check>
								</div>
							</Form.Group>

							<FadeTransition in={formValues.requestType === FOOD_REQUEST_TYPE.PREPARED_MEALS}>
								<div className="mb-4 pt-4 pb-4 pl-5 pr-5 bg-white">
									<p className="mb-0">
										<strong className="text-danger">Note</strong>: Our network's capacity to provide
										prepared meals is limited. Please select prepared meals only if the recipient
										absolutely requires it. Thank you for your understanding. Any leads on possible
										prepared meal partners please email Ana at{' '}
										<a href="mailto:Ana.BonillaMartinez@pennmedicine.upenn.edu">
											Ana.BonillaMartinez@pennmedicine.upenn.edu
										</a>
										.
									</p>
								</div>
							</FadeTransition>

							<FadeTransition in={formValues.requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD}>
								<div className="mb-4 pt-4 pb-4 pl-5 pr-5 bg-white">
									<p className="mb-0">
										<strong className="text-danger">Note</strong>: Supplemental food that may include produce, 
										nonperishables, or a mix of both. Food pantry items vary by location and frequency 
										of delivery depends on the pantry's delivery schedule.
									</p>
								</div>
							</FadeTransition>

							<FadeTransition in={formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY}>
								<div className="mb-4 pt-4 pb-4 pl-5 pr-5 bg-white">
									<p className="mb-0">
										<strong className="text-danger">Note</strong>: Delivery request for patients 
										who cannot pick-up food at pantry locations, the request is for delivery services
										only and does not include box of supplemental food. Delivery support is covered by
										FAST, there is no cost to clients or organizations
									</p>
								</div>
							</FadeTransition>

							<FadeTransition in={formValues.requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD}>
								<Form.Group className="mb-6">
									<Form.Label>
										What kind of unprepared food? <RequiredAsterisk />
									</Form.Label>
									<Form.Check
										type="radio"
										name="unprepared-food"
										id="unprepared-food--produce"
										label="Produce"
										value={UNPREPARED_FOOD.PRODUCE}
										checked={formValues.unpreparedFoodType === UNPREPARED_FOOD.PRODUCE}
										onChange={() => {
											setFormValue('unpreparedFoodType', UNPREPARED_FOOD.PRODUCE);
										}}
										inline
									/>
									<Form.Check
										type="radio"
										name="unprepared-food"
										id="unprepared-food--non-perishables"
										label="Non-perishables"
										value={UNPREPARED_FOOD.NON_PERISHABLE}
										checked={formValues.unpreparedFoodType === UNPREPARED_FOOD.NON_PERISHABLE}
										onChange={() => {
											setFormValue('unpreparedFoodType', UNPREPARED_FOOD.NON_PERISHABLE);
										}}
										inline
									/>
									<Form.Check
										type="radio"
										name="unprepared-food"
										id="unprepared-food--both"
										label="Both / a mix"
										value={UNPREPARED_FOOD.MIXED}
										checked={formValues.unpreparedFoodType === UNPREPARED_FOOD.MIXED}
										onChange={() => {
											setFormValue('unpreparedFoodType', UNPREPARED_FOOD.MIXED);
										}}
										inline
									/>
								</Form.Group>
							</FadeTransition>
							<Form.Group>
								<Form.Label>
									Your phone number (Contact for person posting this referral) <RequiredAsterisk />
								</Form.Label>
								<p>
									Organizations will use this number to contact you if more information is needed
									before they claim a request. Once claimed, organizations can contact patients
									directly for additional information.
								</p>
								<Form.Control
									as={Cleave}
									options={{ phone: true, phoneRegionCode: 'us' }}
									className="mb-2"
									type="tel"
									value={formValues.phoneNumber}
									onChange={(event) => {
										setFormValue('phoneNumber', event.currentTarget.value);
									}}
								/>
								<Form.Check
									name="receive-messages"
									id="receive-messages--yes"
									type="checkbox"
									label="This number can receive text messages"
									checked={formValues.phoneNumberCanReceiveMessages}
									onChange={(event) => {
										setFormValue('phoneNumberCanReceiveMessages', event.currentTarget.checked);
									}}
								/>
							</Form.Group>
						</FormSection>

						<FormSection
							title="Quantity &amp; Duration"
							description="Food is requested by the week, with enough food for each household member picked up or delivered on
					a weekly basis."
						>
							<Form.Group className="mb-6">
								<Form.Label>
									Recipient's household size <RequiredAsterisk />
									<p className="d-inline"> If you don't know, enter "0"</p>
								</Form.Label>
								<Form.Control
									type="number"
									min="0"
									value={formValues.recipientsHouseholdSize}
									onChange={(event) => {
										setFormValue('recipientsHouseholdSize', event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<Form.Label>
									How long is food support being requested (in weeks)? <RequiredAsterisk />{' '}
									<p className="d-inline">
										For example, if 2 weeks worth of food is needed, enter "2".
									</p>
								</Form.Label>
								<Form.Control
									type="number"
									min="0"
									value={formValues.foodSupportDuration}
									onChange={(event) => {
										setFormValue('foodSupportDuration', event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>
									Does the recipient have a place to store perishable food? <RequiredAsterisk />
								</Form.Label>
								<YesNoUnsureQuestion
									name="food-storage"
									value={formValues.canStore}
									onChange={(value) => {
										setFormValue('canStore', value);
									}}
								/>
							</Form.Group>
							<FadeTransition in={formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY}>
								<Form.Group className="mt-6">
									<Form.Label>
										Does the recipient have access to the resources needed to cook the food?{' '}
										<RequiredAsterisk />
									</Form.Label>
									<YesNoUnsureQuestion
										name="has-kitchen"
										value={formValues.hasKitchen}
										onChange={(value) => {
											setFormValue('hasKitchen', value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
							<FadeTransition in={formValues.requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD}>
								<Form.Group className="mt-6">
									<Form.Label>
										Do they wish to have ready-to-eat items only (e.g. hand fruit, canned foods)?{' '}
										<RequiredAsterisk />
									</Form.Label>
									<YesNoUnsureQuestion
										name="ready-to-eat"
										value={formValues.readyToEat}
										onChange={(value) => {
											setFormValue('readyToEat', value);
										}}
									/>
								</Form.Group>
							</FadeTransition>
						</FormSection>

						<FormSection title="Request Details" isLast>
							<Form.Group>
								<Form.Label>
									Is this an urgent need? <RequiredAsterisk />
								</Form.Label>
								<p>i.e. the recipient has no access to food in the next 48 hours.</p>
								<YesNoUnsureQuestion
									name="is-urgent"
									value={formValues.isUrgent}
									onChange={(value) => {
										setFormValue('isUrgent', value);
									}}
								/>
							</Form.Group>

							<FadeTransition in={formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY}>
								<Form.Group className="mt-6">
									<Form.Label>
										Does the recipient have dietary restrictions or allergies? <RequiredAsterisk />
									</Form.Label>
									<Form.Check
										inline
										name="has-dietary-restrictions-or-allergies"
										id="has-dietary-restrictions-or-allergies--yes"
										type="radio"
										label="Yes"
										checked={formValues.recipientHasDietaryRestrictions === true}
										onChange={() => {
											setFormValue('recipientHasDietaryRestrictions', true);
										}}
									/>
									<Form.Check
										inline
										name="has-dietary-restrictions-or-allergies"
										id="has-dietary-restrictions-or-allergies--no"
										type="radio"
										label="No"
										checked={formValues.recipientHasDietaryRestrictions === false}
										onChange={() => {
											setMultipleFormValues({
												recipientHasDietaryRestrictions: false,
												dietaryRestrictions: [],
												allergy: '',
												otherDiet: '',
												hasFlexibleDietaryRestrictions: undefined,
												dietaryRestrictionFlexibilityDescription: '',
											});
										}}
									/>
								</Form.Group>
							</FadeTransition>

							<FadeTransition
								in={
									formValues.recipientHasDietaryRestrictions === true &&
									formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY
								}
							>
								<>
									<Form.Group className="mt-6">
										<Form.Label>
											Does the recipient have dietary restrictions?{' '}
											<p className="d-inline">Check all that apply.</p>
										</Form.Label>
										<div className="mb-4 pt-4 pb-4 pl-5 pr-5 bg-white">
											<p className="mb-0">
												<strong className="text-danger">Note</strong>: Because our network’s
												capacity to accommodate dietary restrictions is limited, it may be
												challenging to fulfill requests with strict dietary requirements, so
												please identify restrictions that are absolutely necessary.
											</p>
										</div>
										<CheckboxList
											name="dietary-restrictions"
											options={Object.values(dietaryRestrictions).map((dr) => {
												return {
													value: dr.dietaryRestrictionId,
													title: dr.dietaryRestrictionTitle,
												};
											})}
											values={formValues.dietaryRestrictions}
											onChange={(values) => {
												setFormValue('dietaryRestrictions', values as DIETARY_RESTRICTIONS[]);
											}}
											hasTwoColumns
										/>
									</Form.Group>
									<FadeTransition
										in={formValues.dietaryRestrictions.includes(DIETARY_RESTRICTIONS.FOOD_ALLERGY)}
									>
										<Form.Group className="mt-6">
											<Form.Label>
												What food is the recipient allergic to? <RequiredAsterisk />
											</Form.Label>
											<Form.Control
												type="text"
												value={formValues.allergy}
												onChange={(event) => {
													setFormValue('allergy', event.currentTarget.value);
												}}
											/>
										</Form.Group>
									</FadeTransition>
									<FadeTransition
										in={formValues.dietaryRestrictions.includes(DIETARY_RESTRICTIONS.OTHER_DIET)}
									>
										<Form.Group className="mt-6">
											<Form.Label>
												What is the diet? <RequiredAsterisk />
											</Form.Label>
											<Form.Control
												type="text"
												value={formValues.otherDiet}
												onChange={(event) => {
													setFormValue('otherDiet', event.currentTarget.value);
												}}
											/>
										</Form.Group>
									</FadeTransition>
									<Form.Group className="mt-6">
										<Form.Label>
											Would the recipient be able to accept food that does not meet one or more of
											the selected restrictions? <RequiredAsterisk />
										</Form.Label>
										<Form.Check
											inline
											name="has-flexible-dietary-restrictions"
											id="has-flexible-dietary-restrictions--yes"
											type="radio"
											label="Yes"
											checked={formValues.hasFlexibleDietaryRestrictions === true}
											onChange={() => {
												setFormValue('hasFlexibleDietaryRestrictions', true);
											}}
										/>
										<Form.Check
											inline
											name="has-flexible-dietary-restrictions"
											id="has-flexible-dietary-restrictions--no"
											type="radio"
											label="No"
											checked={formValues.hasFlexibleDietaryRestrictions === false}
											onChange={() => {
												setMultipleFormValues({
													hasFlexibleDietaryRestrictions: false,
													dietaryRestrictionFlexibilityDescription: '',
												});
											}}
										/>
									</Form.Group>
									<FadeTransition in={formValues.hasFlexibleDietaryRestrictions === true}>
										<Form.Group className="mt-6">
											<Form.Label>
												Please indicate which are flexible and which are absolute restrictions.
												<RequiredAsterisk />
											</Form.Label>
											<Form.Control
												type="text"
												value={formValues.dietaryRestrictionFlexibilityDescription}
												onChange={(event) => {
													setFormValue(
														'dietaryRestrictionFlexibilityDescription',
														event.currentTarget.value
													);
												}}
											/>
										</Form.Group>
									</FadeTransition>
									<div className="mt-6 pt-4 pb-4 pl-5 pr-5 bg-white">
										<p className="text-success">
											<strong>Food education resources</strong>
										</p>
										<p>
											If the recipient is interested in resources relating to food education, here
											are some helpful links you could provide.
										</p>
										<p>
											The Food Trust online leaning hub:{' '}
											<a
												href="http://thefoodtrust.org/online-learning"
												target="_blank"
												rel="noopener noreferrer"
											>
												thefoodtrust.org/online-learning
											</a>
										</p>
										<p>
											Choose My Plate:{' '}
											<a
												href="https://www.myplate.gov/"
												target="_blank"
												rel="noopener noreferrer"
											>
												myplate.gov
											</a>
										</p>
										<p className="mb-0">
											Recipes &amp; Produce Storage Tips:{' '}
											<a
												href="https://greenerpartners.org/what-we-do/recipes-food-storage/"
												target="_blank"
												rel="noopener noreferrer"
											>
												greenerpartners.org/what-we-do/recipes-food-storage
											</a>
										</p>
									</div>
								</>
							</FadeTransition>
						</FormSection>
					</Form>
				</Col>
			</Row>
			<FormButtonsRow
				showLeftButton={false}
				rightButtonTitle="Next"
				rightButtonOnClick={() => {
					if (isValidPhoneNumber(formValues.phoneNumber)) {
						history.push(`/post-request/pickup-delivery${history.location.search}`);
					} else {
						showAlert({
							variant: 'danger',
							children: () => {
								return (
									<p className="mb-0 text-white">
										<strong>Error:</strong> Phone number is invalid.
									</p>
								);
							},
							isDismissible: false,
						});
					}
				}}
				rightButtonDisabled={!pickupDeliveryPageIsEnabled}
			/>
		</>
	);
};
