import Cookies from 'js-cookie';
import React, { FC, useCallback, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { useAccount } from '@/hooks';
import { AsyncPage, FadeTransition } from '@/components';
import {
	COOKIES,
	InstitutionModel,
	reportTypes,
	REPORT_TYPE,
	REPORT_TYPE_ID,
	timePeriods,
	TIME_PERIODS,
} from '@/core/models';
import { institutionsService } from '@/core/services/institutions-service';
import { constructUrl } from '@/core/utils';
import config from '@/core/config';

const hoursArray = Array.from({ length: 12 }, (_value, index) => index + 1);

export const DownloadReports: FC = () => {
	const { account } = useAccount();
	const [reportTypeOptions, setReportTypeOptions] = useState<REPORT_TYPE[]>([]);
	const [reportTypeValue, setReportTypeValue] = useState<REPORT_TYPE_ID | string>('');
	const [timePeriodValue, setTimePeriodValue] = useState<TIME_PERIODS>(TIME_PERIODS.WEEK);
	const [organizationValue, setOrganizationValue] = useState('');
	const [requestIdValue, setRequestIdValue] = useState('');
	const [dateValue, setDateValue] = useState('');
	const [hourValue, setHourValue] = useState('12');
	const [meridiemValue, setMeridiemValue] = useState('AM');
	const [organizationOptions, setOrganizationOptions] = useState<InstitutionModel[]>([]);

	const fetchData = useCallback(async () => {
		if (!account) {
			throw new Error('account not found.');
		}

		if (account.allReports) {
			const response = await institutionsService.getInstitutions().fetch();

			setReportTypeOptions(Object.values(reportTypes));
			setOrganizationOptions(response.institutions);
		} else {
			setReportTypeOptions([reportTypes[REPORT_TYPE_ID.REQUESTS], reportTypes[REPORT_TYPE_ID.ON_FLEET]]);
			setOrganizationOptions([account.institution]);
		}
	}, [account]);

	function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();

		switch (reportTypeValue) {
			case REPORT_TYPE_ID.REQUESTS:
				const requestsUtcTime = new Date(`${dateValue} ${hourValue}:00 ${meridiemValue}`).toISOString();
				window.open(
					constructUrl(`${config.FAST_API_BASE_URL}/report/food-requests`, {
						...(requestsUtcTime ? { date: requestsUtcTime } : {}),
						...(organizationValue ? { institutionId: organizationValue } : {}),
						'X-Fast-Access-Token': Cookies.get(COOKIES.ACCESS_TOKEN),
					}),
					'Fast Report Download'
				);
				break;
			case REPORT_TYPE_ID.USER_ENGAGEMENTS:
				window.open(
					constructUrl(`${config.FAST_API_BASE_URL}/report/user-engagement`, {
						...(timePeriodValue ? { timeFrame: timePeriodValue } : {}),
						'X-Fast-Access-Token': Cookies.get(COOKIES.ACCESS_TOKEN),
					}),
					'Fast Report Download'
				);
				break;
			case REPORT_TYPE_ID.INSTITUTIONS:
				window.open(
					constructUrl(`${config.FAST_API_BASE_URL}/report/institutions`, {
						...(timePeriodValue ? { timeFrame: timePeriodValue } : {}),
						'X-Fast-Access-Token': Cookies.get(COOKIES.ACCESS_TOKEN),
					}),
					'Fast Report Download'
				);
				break;
			case REPORT_TYPE_ID.REQUEST_STATUS_HISTORY:
				window.open(
					constructUrl(`${config.FAST_API_BASE_URL}/report/food-request-status-history`, {
						...(requestIdValue ? { foodRequestId: requestIdValue } : {}),
						'X-Fast-Access-Token': Cookies.get(COOKIES.ACCESS_TOKEN),
					}),
					'Fast Report Download'
				);
				break;
			case REPORT_TYPE_ID.ON_FLEET:
				const utcTime = new Date(`${dateValue} ${hourValue}:00 ${meridiemValue}`).toISOString();

				window.open(
					constructUrl(`${config.FAST_API_BASE_URL}/report/on-fleet-import`, {
						...(utcTime ? { date: utcTime } : {}),
						...(organizationValue ? { institutionId: organizationValue } : {}),
						'X-Fast-Access-Token': Cookies.get(COOKIES.ACCESS_TOKEN),
					}),
					'Fast Report Download'
				);
				break;
			default:
				return;
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-6">
				<Row>
					<Col>
						<h1 className="mb-2">Download Reports</h1>
						<hr className="mb-6" />
					</Col>
				</Row>
				<Row>
					<Col md={6} className="mb-10">
						<h2 className="mb-2">Generate a report (.csv)</h2>
						<hr className="mb-5" />
						<Form onSubmit={handleFormSubmit}>
							<Form.Group className="mb-5">
								<Form.Label>Report type</Form.Label>
								<Form.Control
									as="select"
									value={reportTypeValue}
									onChange={(event) => {
										setReportTypeValue(event.currentTarget.value as REPORT_TYPE_ID);
									}}
									required
								>
									<option value={''} disabled>
										Select report type...
									</option>
									{Object.values(reportTypeOptions).map((reportType) => {
										return (
											<option key={reportType.reportTypeId} value={reportType.reportTypeId}>
												{reportType.reportTypeDescription}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>

							<FadeTransition
								in={
									![REPORT_TYPE_ID.ON_FLEET.toString(), REPORT_TYPE_ID.REQUESTS.toString()].includes(
										reportTypeValue
									)
								}
							>
								<Form.Group className="mb-5">
									<Form.Label>Time period</Form.Label>
									{Object.values(timePeriods).map((timePeriod) => {
										return (
											<Form.Check
												key={timePeriod.timePeriodId}
												type="radio"
												label={timePeriod.timePeriodDescription}
												name="time-period"
												id={`time-period--${timePeriod.timePeriodId}`}
												value={timePeriod.timePeriodId}
												checked={timePeriodValue === timePeriod.timePeriodId}
												onChange={(event) => {
													if (event.currentTarget.checked) {
														setTimePeriodValue(event.currentTarget.value as TIME_PERIODS);
													}
												}}
												inline
												required
											/>
										);
									})}
								</Form.Group>
							</FadeTransition>

							<FadeTransition
								in={[REPORT_TYPE_ID.ON_FLEET.toString(), REPORT_TYPE_ID.REQUESTS.toString()].includes(
									reportTypeValue
								)}
							>
								<Form.Row className="mb-6">
									<Col xs={6}>
										<Form.Group>
											<Form.Label>From Date</Form.Label>
											<Form.Control
												type="date"
												max={new Date().toISOString().substring(0, 10)}
												value={dateValue}
												onChange={(event) => {
													setDateValue(event.currentTarget.value);
												}}
											/>
										</Form.Group>
									</Col>
									<Col xs={3}>
										<Form.Group>
											<Form.Label>Hour</Form.Label>
											<Form.Control
												as="select"
												value={hourValue}
												onChange={(event) => {
													setHourValue(event.currentTarget.value);
												}}
											>
												{hoursArray.map((num, idx) => {
													return (
														<option key={num + idx} value={num}>
															{num}
														</option>
													);
												})}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col xs={3}>
										<Form.Group>
											<Form.Label>AM / PM</Form.Label>
											<Form.Control
												as="select"
												value={meridiemValue}
												onChange={(event) => {
													setMeridiemValue(event.currentTarget.value);
												}}
											>
												<option value="AM">AM</option>
												<option value="PM">PM</option>
											</Form.Control>
										</Form.Group>
									</Col>
								</Form.Row>
							</FadeTransition>

							<FadeTransition
								in={
									reportTypeValue === REPORT_TYPE_ID.REQUESTS ||
									reportTypeValue === REPORT_TYPE_ID.ON_FLEET
								}
							>
								<Form.Group className="mb-6">
									<Form.Label>Organization</Form.Label>
									<Form.Control
										as="select"
										value={organizationValue}
										onChange={(event) => {
											setOrganizationValue(event.currentTarget.value);
										}}
									>
										{reportTypeValue === REPORT_TYPE_ID.REQUESTS && account?.allReports && (
											<option value={''}>All</option>
										)}
										{organizationOptions.map((organizationOption) => {
											return account?.allReports ||
												account?.institution.institutionId ===
													organizationOption.institutionId ? (
												<option
													key={organizationOption.institutionId}
													value={organizationOption.institutionId}
												>
													{organizationOption.name}
												</option>
											) : null;
										})}
									</Form.Control>
								</Form.Group>
							</FadeTransition>

							<FadeTransition in={reportTypeValue === REPORT_TYPE_ID.REQUEST_STATUS_HISTORY}>
								<Form.Group className="mb-6">
									<Form.Label>Request ID</Form.Label>
									<Form.Control
										type="text"
										value={requestIdValue}
										onChange={(event) => {
											setRequestIdValue(event.currentTarget.value);
										}}
									/>
								</Form.Group>
							</FadeTransition>

							<Button type="submit" variant="primary">
								Download
							</Button>
						</Form>
					</Col>
					<Col md={6} className="mb-10">
						<h2 className="mb-2">Report types, explained</h2>
						<hr className="mb-5" />
						{Object.values(reportTypeOptions).map((reportType) => {
							return (
								<p key={reportType.reportTypeId}>
									<strong>{reportType.reportTypeDescription}:</strong>{' '}
									{reportType.reportTypeDefinition}
								</p>
							);
						})}
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
