import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import Color from 'color';

import { theme } from '@/jss';

const useStyles = createUseStyles({
	alert: {
		padding: '15px 25px',
		'&.outline': {
			borderRadius: 4,
			border: `1px solid ${theme.colors.grayFour}`,
		},
		'&.success': {
			backgroundColor: theme.colors.greenOne,
			'&.outline': {
				backgroundColor: Color(theme.colors.greenOne).lighten(0.54).string(),
				borderColor: theme.colors.greenOne,
			},
		},
		'&.warning': {
			backgroundColor: theme.colors.yellowOne,
			'&.outline': {
				backgroundColor: Color(theme.colors.yellowOne).lighten(0.54).string(),
				borderColor: theme.colors.yellowOne,
			},
		},
		'&.danger': {
			backgroundColor: theme.colors.redOne,
			'&.outline': {
				backgroundColor: Color(theme.colors.redOne).lighten(0.54).string(),
				borderColor: theme.colors.redOne,
			},
		},
	},
});

interface InlineAlertProps {
	variant: 'success' | 'warning' | 'danger' | undefined;
	outline?: boolean;
	className?: string;
}

export const InlineAlert: FC<InlineAlertProps> = ({ variant, outline, className, children }) => {
	const classes = useStyles();

	return (
		<div
			className={classNames([
				classes.alert,
				variant,
				{
					outline: outline,
				},
				className,
			])}
		>
			{children}
		</div>
	);
};
