export const povertyGuideline: Record<number, number> = {
	1: 12760,
	2: 17240,
	3: 21720,
	4: 26200,
	5: 30680,
	6: 35160,
	7: 39640,
	8: 44120,
};

const sizeLimit = 8;
const incomeOffsetPerPersonOverEight = 4480;

export function getPercentageOfPovertyLine(householdSize: number, householdIncome: number) {
	const householdSizeDifference = householdSize - sizeLimit;
	const initialPovertyLine = povertyGuideline[householdSize > sizeLimit ? sizeLimit : householdSize];
	const additionalPovertyLine =
		householdSizeDifference > 0 ? householdSizeDifference * incomeOffsetPerPersonOverEight : 0;
	const povertyLine = initialPovertyLine + additionalPovertyLine;
	const percentage = Math.round((householdIncome / povertyLine) * 100);

	if (isNaN(percentage)) {
		return '--';
	}

	return percentage;
}
