import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAccount, useHandleError, useQuery, useRequestForm } from '@/hooks';
import { FOOD_REQUEST_TYPE, InstitutionModel, TRANSPORTATION, UNPREPARED_FOOD, YES_NO_UNKNOWN } from '@/core/models';

import { AsyncPage, InlineAlert, RequestDetailTemplate } from '@/components';
import { FormButtonsRow } from '@/components/request-form';

import { foodRequestService } from '@/core/services/food-request-service';
import { getPercentageOfPovertyLine, mapFormValuesToFoodRequest } from '@/core/utils';

export const Review: FC = () => {
	const history = useHistory();
	const query = useQuery();
	const foodRequestId = query.get('foodRequestId');
	const repostAsNew = query.get('repostAsNew') === 'true';
	const repostForDeliveryOnly = query.get('repostForDeliveryOnly') === 'true';
	const { account } = useAccount();
	const handleError = useHandleError();
	const {
		formValues,
		setFormValue,
		setBasicsPageIsEnabled,
		setPickupDeliveryPageIsEnabled,
		setEligibilityAssessmentPageIsEnabled,
		setReviewPageIsEnabled,
	} = useRequestForm();

	const [healthSystems, setHealthSystems] = useState<InstitutionModel[]>([]);
	const [confirmingRequest, setConfirmingRequest] = useState(false);

	const fetchData = useCallback(async () => {
		const response = await foodRequestService.getFoodRequestFilters().fetch();
		setHealthSystems(response.filters.healthSystems);
	}, []);

	async function handleConfirmRequestButtonClick() {
		try {
			setConfirmingRequest(true);

			const requestBody = {
				foodRequestTypeId: formValues.requestType || FOOD_REQUEST_TYPE.UNPREPARED_FOOD,
				unpreparedFoodTypeId: formValues.unpreparedFoodType || UNPREPARED_FOOD.MIXED,
				contactPhoneNumber: formValues.phoneNumber,
				contactPhoneNumberSmsCapable: formValues.phoneNumberCanReceiveMessages,
				householdSize: parseInt(formValues.recipientsHouseholdSize, 10),
				requestDurationDays: parseInt(formValues.foodSupportDuration, 10) * 7,
				perishableFoodStorageId: formValues.canStore || YES_NO_UNKNOWN.UNKNOWN,
				reheatFoodId: formValues.hasKitchen || YES_NO_UNKNOWN.UNKNOWN,
				readyToEatId: formValues.readyToEat || YES_NO_UNKNOWN.UNKNOWN,
				urgentNeedId: formValues.isUrgent || YES_NO_UNKNOWN.UNKNOWN,
				dietaryRestrictionIds: formValues.dietaryRestrictions,
				foodAllergyDescription: formValues.allergy,
				dietaryRestrictionOtherDescription: formValues.otherDiet,
				hasFlexibleDietaryRestrictions: formValues.hasFlexibleDietaryRestrictions,
				dietaryRestrictionFlexibilityDescription: formValues.dietaryRestrictionFlexibilityDescription,
				recipientToPickup: formValues.transportationMethod === TRANSPORTATION.PICKUP,
				name: formValues.recipientsName,
				phoneNumber: formValues.recipientsPhoneNumber,
				phoneNumberSmsCapable: formValues.recipientsPhoneNumberCanReceiveMessages,
				recipientPreferredSpokenLanguageIsEnglish: formValues.recipientsPreferredSpokenLanguageIsEnglish,
				recipientPreferredSpokenLanguage: formValues.recipientsPreferredSpokenLanguage,
				addressLine1: formValues.recipientsAddressOne,
				addressLine2: formValues.recipientsAddressTwo,
				city: formValues.recipientsCity,
				stateId: formValues.recipientsState,
				postalCode: formValues.recipientsZipCode,
				notes: formValues.additionalNotes,
				healthConditionIds: formValues.healthConditions,
				healthConditionOtherDescription: formValues.otherHealthCondition,
				secondaryNutritionalRiskIds: formValues.secondaryNutritionRisks,
				receivingBenefitId: formValues.receivingBenefits || YES_NO_UNKNOWN.UNKNOWN,
				healthBenefitIds: formValues.healthBenefits,
				healthBenefitOtherDescription: formValues.otherHealthBenefit,
				providedBenefitsInfoTypeId: formValues.providedBenefitsInfoTypeId,
				providedBenefitsInfoOtherDescription: formValues.providedBenefitsInfoOtherDescription,
				recipientsUnderTwentyOne: formValues.under21 || YES_NO_UNKNOWN.UNKNOWN,
				recipientsOverSixty: formValues.over60 || YES_NO_UNKNOWN.UNKNOWN,
				householdAnnualIncome: parseFloat(formValues.recipientsAnnualIncome.replace(/[^0-9.-]+/g, '')),
				recipientHasHealthInsurance: formValues.hasHealthInsurance || YES_NO_UNKNOWN.UNKNOWN,
				healthInsuranceProvider: formValues.healthInsuranceProvider,
				healthSystemIds: formValues.healthSystems.filter((hs) => hs !== 'OTHER'),
				healthSystemOtherDescription: formValues.otherHealthSystem,
				deliveryPickupName: formValues.pickupLocationName,
				deliveryPickupPhoneNumber: formValues.pickupLocationPhoneNumber,
				deliveryPickupPhoneNumberSmsCapable: formValues.pickupLocationPhoneNumberCanReceiveMessages,
				deliveryPickupAddressLine1: formValues.pickupLocationAddressOne,
				deliveryPickupAddressLine2: formValues.pickupLocationAddressTwo,
				deliveryPickupCity: formValues.pickupLocationCity,
				deliveryPickupStateId: formValues.pickupLocationState,
				deliveryPickupPostalCode: formValues.pickupLocationZipCode,
				deliveryPickupWindow: formValues.pickupLocationWindow,
				deliveryPickupNotes: formValues.pickupLocationNotes,
				...(formValues.recipientsHouseholdSize && formValues.recipientsAnnualIncome
					? {
							lowIncome:
								getPercentageOfPovertyLine(
									parseInt(formValues.recipientsHouseholdSize, 10),
									parseInt(formValues.recipientsAnnualIncome, 10)
								) < 200,
					  }
					: {}),
			};

			let foodRequest;

			if (repostAsNew) {
				const response = await foodRequestService.createFoodRequest(requestBody).fetch();
				foodRequest = response.foodRequest;
			} else if (repostForDeliveryOnly) {
				const response = await foodRequestService
					.createFoodRequest({ ...requestBody, linkedFoodRequestId: foodRequestId })
					.fetch();
				foodRequest = response.foodRequest;
			} else if (foodRequestId) {
				const response = await foodRequestService.updateFoodRequest(foodRequestId, requestBody).fetch();
				foodRequest = response.foodRequest;
			} else {
				const response = await foodRequestService.createFoodRequest(requestBody).fetch();
				foodRequest = response.foodRequest;
			}

			setFormValue('requestConfirmed', true);
			setBasicsPageIsEnabled(false);
			setPickupDeliveryPageIsEnabled(false);
			setEligibilityAssessmentPageIsEnabled(false);
			setReviewPageIsEnabled(false);

			history.push({
				pathname: '/post-request/send',
				state: {
					foodRequestId: foodRequest.foodRequestId,
					isEdit: foodRequestId ? true : false,
					isRepost: repostAsNew,
					claimedBy: foodRequest.claimedBy,
				},
			});
		} catch (error) {
			handleError(error);
			setConfirmingRequest(false);
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<InlineAlert variant="warning" className="mb-7">
				<h2 className="text-center">Review</h2>
				<p className="mb-0 text-center">
					Please verify the information entered before confirming your request.
				</p>
			</InlineAlert>

			{account && (
				<RequestDetailTemplate
					foodRequest={mapFormValuesToFoodRequest(formValues, account, healthSystems)}
					onStatusUpdate={() => {
						return;
					}}
				/>
			)}

			<FormButtonsRow
				leftButtonTitle="Back"
				leftButtonOnClick={() => {
					history.push(`/post-request/eligibility-assessment${history.location.search}`);
				}}
				rightButtonTitle="Confirm Request"
				rightButtonIsLoading={confirmingRequest}
				rightButtonOnClick={handleConfirmRequestButtonClick}
			/>
		</AsyncPage>
	);
};
