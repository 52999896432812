import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { theme } from '@/jss';

const useStyles = createUseStyles({
	statusDot: ({ size, urgent, needsSupport }: { size: number; urgent: boolean; needsSupport: boolean }) => {
		let backgroundColor = theme.colors.grayThree;

		if (urgent) {
			backgroundColor = theme.colors.redOne;
		}

		if (needsSupport) {
			backgroundColor = theme.colors.blueTwo;
		}

		return {
			flexShrink: 0,
			backgroundColor,
			borderRadius: 500,
			width: size ? size : 14,
			height: size ? size : 14,
		};
	},
});

interface RequestStatusDotProps {
	size?: number;
	className?: string;
	urgent?: boolean;
	needsSupport?: boolean;
}

export const RequestStatusDot: FC<RequestStatusDotProps> = ({ size, className, urgent, needsSupport }) => {
	const classes = useStyles({
		size,
		urgent,
		needsSupport,
	});

	return <div className={classNames([classes.statusDot, className])} />;
};
