export enum HEALTH_BENEFITS {
	WIC = 'WIC',
	SNAP = 'SNAP',
	SWP = 'SWP',
	SSI = 'SSI',
	OTHER = 'OTHER',
}

export const healthBenefits = {
	[HEALTH_BENEFITS.WIC]: {
		healthBenefitId: HEALTH_BENEFITS.WIC,
		healthBenefitTitle: 'Supplemental Nutrition Program for Women, Infants, and Children (WIC)',
		healthBenefitAbbreviation: 'WIC',
	},
	[HEALTH_BENEFITS.SNAP]: {
		healthBenefitId: HEALTH_BENEFITS.SNAP,
		healthBenefitTitle: 'Supplemental Nutrition Assistance Program (SNAP)',
		healthBenefitAbbreviation: 'SNAP',
	},
	[HEALTH_BENEFITS.SWP]: {
		healthBenefitId: HEALTH_BENEFITS.SWP,
		healthBenefitTitle: 'State Waiver Program',
		healthBenefitAbbreviation: 'SWP',
	},
	[HEALTH_BENEFITS.SSI]: {
		healthBenefitId: HEALTH_BENEFITS.SSI,
		healthBenefitTitle: 'SSI / SSDI',
		healthBenefitAbbreviation: 'SSI / SSDI',
	},
	[HEALTH_BENEFITS.OTHER]: {
		healthBenefitId: HEALTH_BENEFITS.OTHER,
		healthBenefitTitle: 'Other ',
		healthBenefitAbbreviation: 'Other',
	},
};
