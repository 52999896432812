export enum INSTITUTION_TYPE_ID {
	CBO = 'CBO',
	HEALTH_SYSTEM = 'HEALTH_SYSTEM',
	NON_PROFIT = 'NON_PROFIT',
	DELIVERY = 'DELIVERY',
}

export interface InstitutionModel {
	institutionId: string;
	institutionTypeId: INSTITUTION_TYPE_ID;
	name: string;
	adminName: string;
}
