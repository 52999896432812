import { SORT_DIRECTION, TableHeader, TableRow } from '@/components/table';
import React, { FC } from 'react';

interface BrowseRequestsTableHeaderRowProps {
	sortDirection: SORT_DIRECTION | null | undefined;
	onSort(sortBy: string, sortDirection: SORT_DIRECTION): void;
}

export const BrowseRequestsTableHeaderRow: FC<BrowseRequestsTableHeaderRowProps> = ({ sortDirection, onSort }) => {
	function handlePostedSort(sortDirection: SORT_DIRECTION) {
		onSort('POSTED', sortDirection);
	}

	return (
		<TableRow>
			<TableHeader>Type</TableHeader>
			<TableHeader sortable onSort={handlePostedSort} sortDirection={sortDirection}>
				Posted
			</TableHeader>
			<TableHeader>Pick./Del.</TableHeader>
			<TableHeader>Restrictions &amp; Eligibility</TableHeader>
		</TableRow>
	);
};
