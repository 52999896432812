import React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { Table } from './table';
import { TableHead } from './table-head';
import { TableBody } from './table-body';

const useStyles = createUseStyles({
	table: ({ isLoading }: { isLoading: boolean }) => ({
		opacity: isLoading ? 0.5 : 1,
	}),
});

interface TableRendererProps<T> {
	isLoading?: boolean;
	tableRowsData: T[];
	tableHeaderRowRenderer(): JSX.Element;
	tableBodyRowRenderer(data: T): JSX.Element | null;
	className?: string;
}

export function TableRenderer<T>({
	isLoading,
	tableRowsData,
	tableHeaderRowRenderer,
	tableBodyRowRenderer,
	className,
}: TableRendererProps<T>) {
	const classes = useStyles({
		isLoading,
	});

	return (
		<Table className={classNames(classes.table, className)}>
			<TableHead>{tableHeaderRowRenderer()}</TableHead>

			<TableBody>
				{tableRowsData.map((tableRowData) => {
					return tableBodyRowRenderer(tableRowData);
				})}
			</TableBody>
		</Table>
	);
}
