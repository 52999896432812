import Cookies from 'js-cookie';

import config from '@/core/config';
import { COOKIES } from '@/core/models';
import { HttpClient } from '@/core/http/http-client';

export * from './errors';

export const httpService = new HttpClient({
	baseUrl: config.FAST_API_BASE_URL,
	defaultHeaders: {
		'Content-Type': 'application/json',
	},
	tokenHeaderKey: 'X-Fast-Access-Token',
	getToken: async () => {
		try {
			return Cookies.get(COOKIES.ACCESS_TOKEN);
		} catch (error) {
			return undefined;
		}
	},
});
