import { cloneDeep } from 'lodash';

export function addOrRemoveValueFromSourceArray(value: any, source: any[]) {
	const sourceArray = cloneDeep(source);

	if (sourceArray.includes(value)) {
		const indexToRemove = sourceArray.findIndex((id) => id === value);

		if (indexToRemove > -1) {
			sourceArray.splice(indexToRemove, 1);
		}
	} else {
		sourceArray.push(value);
	}

	return sourceArray;
}

export function addOrRemoveArrayFromObject<T>(object: T, key: keyof T, value: any) {
	const clone = cloneDeep(object);

	clone[key] = value;

	if (value.length === 0) {
		delete clone[key];
	}

	return clone;
}
