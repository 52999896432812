import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';

import { useAccount, useAlert, useHandleError } from '@/hooks';

import { TableNoMatch, TableRenderer } from '@/components/table';
import {
	AccountTableBodyRow,
	AccountTableHeaderRow,
	AsyncPage,
	InvitationTableBodyRow,
	InvitationTableHeaderRow,
	LoaderButton,
} from '@/components';

import { accountService } from '@/core/services';
import { AccountModel, InvitationModel } from '@/core/models';

export const Invitations: FC = () => {
	const handleError = useHandleError();
	const { showAlert } = useAlert();

	const { account } = useAccount();
	const [isSending, setIsSending] = useState(false);
	const [emailListTextareaValue, setEmailListTextareaValue] = useState('');
	const [invites, setInvites] = useState<InvitationModel[]>([]);
	const [accounts, setAccounts] = useState<AccountModel[]>([]);

	const fetchData = useCallback(async () => {
		const [invitesResponse, accountsResponse] = await Promise.all([
			accountService.getAccountInvites().fetch(),
			accountService.getAccounts().fetch(),
		]);

		setInvites(invitesResponse.invitations);
		setAccounts(accountsResponse.accounts);
	}, []);

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();
		setIsSending(true);

		try {
			const { invitations } = await accountService
				.sendAccountInvites(
					emailListTextareaValue.split(',').map((email) => {
						return email.trim();
					})
				)
				.fetch();

			showAlert({
				variant: 'success',
				children: () => {
					return <p className="mb-0 text-white">Invitations sent.</p>;
				},
			});

			setInvites(invitations);
			setEmailListTextareaValue('');
		} catch (error) {
			handleError(error);
		}

		setIsSending(false);
	}

	async function handleInvitationRevoke(invitationId: string) {
		if (window.confirm('Are you sure you want to revoke this invitation? This action cannot be undone.')) {
			try {
				const { invitations } = await accountService.revokeAccountInvite(invitationId).fetch();
				setInvites(invitations);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Invitation revoked.</p>;
					},
				});
			} catch (error) {
				handleError(error);
			}
		}
	}

	async function handleAccountDelete(accountId: string) {
		if (window.confirm('Are you sure you want to delete this account? This action cannot be undone.')) {
			try {
				const { accounts } = await accountService.deleteAccountById(accountId).fetch();
				setAccounts(accounts);

				showAlert({
					variant: 'success',
					children: () => {
						return <p className="mb-0 text-white">Account deleted.</p>;
					},
				});
			} catch (error) {
				handleError(error);
			}
		}
	}

	return (
		<AsyncPage fetchData={fetchData}>
			<Container className="pt-9 pb-6">
				<Row>
					<Col>
						<div className="mb-2">
							<h5 className="mb-0 d-inline">
								<Link to="/account-settings" className="text-roboto">
									Account Settings /{' '}
								</Link>
							</h5>
							<h1 className="mb-0 d-inline">Invitations</h1>
						</div>
						<hr className="mb-6" />
					</Col>
				</Row>
				<Row>
					<Col lg={4}>
						<h2 className="mb-2">Invite people to FAST</h2>
						<hr className="mb-5" />
						<Form onSubmit={handleFormSubmit}>
							<Form.Group className="mb-5">
								<Form.Label>Enter email addresses separated by a comma</Form.Label>
								<p className="mb-4">
									The people you invite will join FAST as representatives of{' '}
									{account?.institution.name} and will be able to post and claim requests on behalf of
									the organization.
								</p>
								<Form.Control
									as="textarea"
									value={emailListTextareaValue}
									onChange={(event) => {
										setEmailListTextareaValue(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<div className="text-right">
								<LoaderButton isLoading={isSending} type="submit">
									Send
								</LoaderButton>
							</div>
						</Form>
					</Col>
					<Col lg={8}>
						<h2 className="mb-2">Sent invitations</h2>
						<hr className="mb-5" />
						{invites.length === 0 ? (
							<TableNoMatch className="mb-7" message="There are currently no sent invitations." />
						) : (
							<TableRenderer<InvitationModel>
								className="mb-7"
								tableRowsData={invites}
								tableHeaderRowRenderer={() => {
									return <InvitationTableHeaderRow />;
								}}
								tableBodyRowRenderer={(rowData) => {
									return (
										<InvitationTableBodyRow
											key={rowData.invitationId}
											emailAddress={rowData.recipient}
											dateInvited={rowData.createdDescription}
											status={
												rowData.invitationExpired
													? 'Expired'
													: rowData.invitationAccepted
													? 'Accepted'
													: 'Sent'
											}
											onRevoke={() => {
												handleInvitationRevoke(rowData.invitationId);
											}}
										/>
									);
								}}
							/>
						)}

						<h2 className="mb-2">Institution Accounts</h2>
						<hr className="mb-5" />
						{accounts.length - 1 === 0 ? (
							<TableNoMatch message="There are currently no accounts other than yours." />
						) : (
							<TableRenderer<AccountModel>
								tableRowsData={accounts}
								tableHeaderRowRenderer={() => {
									return <AccountTableHeaderRow />;
								}}
								tableBodyRowRenderer={(rowData) => {
									if (rowData.accountId === account?.accountId) {
										return null;
									}

									return (
										<AccountTableBodyRow
											key={rowData.accountId}
											emailAddress={rowData.emailAddress}
											dateCreated={rowData.createdDescription}
											onDelete={() => {
												handleAccountDelete(rowData.accountId);
											}}
										/>
									);
								}}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</AsyncPage>
	);
};
