import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

import { RenderJson } from '@/components';

interface ErrorDisplayProps {
	error: any;
	showBackButton?: boolean;
	showRetryButton?: boolean;
	onRetryButtonClick?(): void;
}

export const ErrorDisplay: FC<ErrorDisplayProps> = ({ error, showBackButton, showRetryButton, onRetryButtonClick }) => {
	const history = useHistory();

	function handleGoBackButtonClick() {
		history.goBack();
	}

	function handleRetryClick() {
		if (onRetryButtonClick) onRetryButtonClick();
	}

	return (
		<>
			<Container className="py-10">
				<Row>
					<Col>
						<h1 className="mb-1 text-center">Error</h1>
						<p className="mb-6 text-center">{error.message}</p>
						<div className="d-flex justify-content-center">
							{showBackButton && (
								<Button onClick={handleGoBackButtonClick} className="mr-2">
									Go back
								</Button>
							)}
							{showRetryButton && <Button onClick={handleRetryClick}>Retry</Button>}
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<RenderJson json={error} />
					</Col>
				</Row>
			</Container>
		</>
	);
};
