import React, { FC } from 'react';
import { Button } from 'react-bootstrap';

import { TableCell, TableRow } from '@/components/table';

interface AccountTableBodyRowProps {
	emailAddress: string;
	dateCreated: string;
	onDelete(): void;
}

export const AccountTableBodyRow: FC<AccountTableBodyRowProps> = ({ emailAddress, dateCreated, onDelete }) => {
	return (
		<TableRow>
			<TableCell>
				<p className="mb-0 text-roboto-condensed">{emailAddress}</p>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{dateCreated}</small>
			</TableCell>
			<TableCell>
				<Button variant="link" className="text-danger" onClick={onDelete}>
					Delete
				</Button>
			</TableCell>
		</TableRow>
	);
};
