import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { dietaryRestrictions, DIETARY_RESTRICTIONS, healthBenefits, HEALTH_BENEFITS } from '@/core/models';

const useStyles = createUseStyles({
	filtersModal: {
		width: '90%',
		maxWidth: 650,
		margin: '0 auto',
	},
});

interface OnSaveParameters {
	dietaryRestrictionIds: DIETARY_RESTRICTIONS[];
	healthBenefitIds: HEALTH_BENEFITS[];
	underTwentyOne: boolean;
	overSixty: boolean;
	lowIncome: boolean;
	hasInsurance: boolean;
}

interface FiltersModalProps extends OnSaveParameters, ModalProps {
	onSave(parameters: OnSaveParameters): void;
}

export const FiltersModal: FC<FiltersModalProps> = ({
	dietaryRestrictionIds,
	healthBenefitIds,
	underTwentyOne,
	overSixty,
	lowIncome,
	hasInsurance,
	onSave,
	...props
}) => {
	const classes = useStyles();

	const [internalDietaryRestrictionIds, setInternalDietaryRestrictionIds] = useState<DIETARY_RESTRICTIONS[]>([]);
	const [internalHealthBenefitIds, setInternalHealthBenefitIds] = useState<HEALTH_BENEFITS[]>([]);
	const [internalAgeUnder21, setInternalAgeUnder21] = useState(false);
	const [internalAgeOver60, setInternalAgeOver60] = useState(false);
	const [internalLowIncome, setInternalLowIncome] = useState(false);
	const [internalHasInsurance, setInternalHasInsurance] = useState(false);

	useEffect(() => {
		if (props.show) {
			setInternalDietaryRestrictionIds(dietaryRestrictionIds);
			setInternalHealthBenefitIds(healthBenefitIds);
			setInternalAgeUnder21(underTwentyOne);
			setInternalAgeOver60(overSixty);
			setInternalLowIncome(lowIncome);
			setInternalHasInsurance(hasInsurance);
		}
	}, [props.show, dietaryRestrictionIds, hasInsurance, healthBenefitIds, lowIncome, overSixty, underTwentyOne]);

	function handleSaveButtonClick() {
		onSave({
			dietaryRestrictionIds: internalDietaryRestrictionIds,
			healthBenefitIds: internalHealthBenefitIds,
			underTwentyOne: internalAgeUnder21,
			overSixty: internalAgeOver60,
			lowIncome: internalLowIncome,
			hasInsurance: internalHasInsurance,
		});
	}

	return (
		<Modal dialogClassName={classes.filtersModal} centered {...props}>
			<Modal.Header>
				<Modal.Title>Restrictions &amp; Eligibility</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group className="mb-6">
					<Form.Label>Dietary Restrictions</Form.Label>
					{Object.values(dietaryRestrictions).map((dietaryRestriction) => {
						return (
							<Form.Check
								key={dietaryRestriction.dietaryRestrictionId}
								bsPrefix="fast-form__check-badge"
								type="checkbox"
								id={dietaryRestriction.dietaryRestrictionId}
								label={dietaryRestriction.dietaryRestrictionTitle}
								value={dietaryRestriction.dietaryRestrictionId}
								checked={internalDietaryRestrictionIds.includes(
									dietaryRestriction.dietaryRestrictionId
								)}
								onChange={(event) => {
									const internalDietaryRestrictionIdsClone = cloneDeep(internalDietaryRestrictionIds);

									if (!event.currentTarget.checked) {
										const indexToRemove = internalDietaryRestrictionIdsClone.findIndex(
											(internalFilter) => internalFilter === event.currentTarget.value
										);

										internalDietaryRestrictionIdsClone.splice(indexToRemove, 1);
									} else {
										internalDietaryRestrictionIdsClone.push(
											event.currentTarget.value as DIETARY_RESTRICTIONS
										);
									}

									setInternalDietaryRestrictionIds(internalDietaryRestrictionIdsClone);
								}}
								inline
							/>
						);
					})}
				</Form.Group>
				<Form.Group className="mb-6">
					<Form.Label>Benefits Eligibility</Form.Label>
					{Object.values(healthBenefits).map((healthBenefit) => {
						return (
							<Form.Check
								key={healthBenefit.healthBenefitId}
								bsPrefix="fast-form__check-badge"
								type="checkbox"
								id={healthBenefit.healthBenefitId}
								label={healthBenefit.healthBenefitTitle}
								value={healthBenefit.healthBenefitId}
								checked={internalHealthBenefitIds.includes(healthBenefit.healthBenefitId)}
								onChange={(event) => {
									const internalHealthBenefitIdsClone = cloneDeep(internalHealthBenefitIds);

									if (!event.currentTarget.checked) {
										const indexToRemove = internalHealthBenefitIdsClone.findIndex(
											(internalFilter) => internalFilter === event.currentTarget.value
										);

										internalHealthBenefitIdsClone.splice(indexToRemove, 1);
									} else {
										internalHealthBenefitIdsClone.push(
											event.currentTarget.value as HEALTH_BENEFITS
										);
									}

									setInternalHealthBenefitIds(internalHealthBenefitIdsClone);
								}}
								inline
							/>
						);
					})}
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Other Eligibility Factors</Form.Label>
					<Form.Check
						bsPrefix="fast-form__check-badge"
						type="checkbox"
						id="other--age-under-21"
						label="Age Under 21"
						value="underTwentyOne"
						checked={internalAgeUnder21}
						onChange={(event) => {
							setInternalAgeUnder21(event.currentTarget.checked);
						}}
						inline
					/>
					<Form.Check
						bsPrefix="fast-form__check-badge"
						type="checkbox"
						id="other--age-over-60"
						label="Age Over 60"
						value="overSixty"
						checked={internalAgeOver60}
						onChange={(event) => {
							setInternalAgeOver60(event.currentTarget.checked);
						}}
						inline
					/>
					<Form.Check
						bsPrefix="fast-form__check-badge"
						type="checkbox"
						id="other--low-income"
						label="Low Income*"
						value="lowIncome"
						checked={internalLowIncome}
						onChange={(event) => {
							setInternalLowIncome(event.currentTarget.checked);
						}}
						inline
					/>
					<Form.Check
						bsPrefix="fast-form__check-badge"
						type="checkbox"
						id="other--has-insurance"
						label="Has Insurance"
						value="hasInsurance"
						checked={internalHasInsurance}
						onChange={(event) => {
							setInternalHasInsurance(event.currentTarget.checked);
						}}
						inline
					/>
				</Form.Group>
				<p className="mb-0">
					<small>
						* Low Income is defined as a household income of 200% of the federal poverty level or less.
					</small>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-between align-items-center">
					<Button variant="link" onClick={props.onHide}>
						Close
					</Button>
					<Button size="sm" onClick={handleSaveButtonClick}>
						Save
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
