import React, { FC, useState } from 'react';
import { Button } from 'react-bootstrap';

import { InlineAlert, RepostInstructionsModal } from '@/components';

export const DeliveryInlineAlert: FC = () => {
	const [showModel, setShowModal] = useState(false);

	function handleSeeHowItWorksButtonClick() {
		setShowModal(true);
	}

	return (
		<>
			<RepostInstructionsModal
				show={showModel}
				onHide={() => {
					setShowModal(false);
				}}
			/>

			<InlineAlert className="mt-3 mb-5" variant="warning" outline>
				<strong>The recipient has asked to have the food delivered to them.</strong> If your organization can
				provide the food but not deliver it, you can repost as a Delivery Only request. One of our delivery
				partners can then pick it up from your organization.{' '}
				<Button variant="link" onClick={handleSeeHowItWorksButtonClick}>
					See how it works.
				</Button>
			</InlineAlert>
		</>
	);
};
