import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';

import { RequestStatusDot, RequestTypeBadge } from '@/components';
import { TableCell, TableRow } from '@/components/table';
import { FOOD_REQUEST_TYPE } from '@/core/models';

interface BrowseRequestsTableBodyRowProps {
	type: FOOD_REQUEST_TYPE;
	posted: string;
	urgent?: boolean;
	needsSupport?: boolean;
	transportationMethod: string;
	restrictions: string[];
	onClick?(): void;
}

export const BrowseRequestsTableBodyRow: FC<BrowseRequestsTableBodyRowProps> = ({
	type,
	posted,
	urgent,
	needsSupport,
	transportationMethod,
	restrictions,
	onClick,
}) => {
	return (
		<TableRow tabIndex={0} onClick={onClick}>
			<TableCell>
				<div className="d-flex align-items-center justify-content-between">
					<RequestTypeBadge requestType={type} />
					<div className="d-flex">
						<>
							{urgent && <RequestStatusDot className="ml-1" urgent />}
							{needsSupport && <RequestStatusDot className="ml-1" needsSupport />}
						</>
					</div>
				</div>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{posted}</small>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{transportationMethod}</small>
			</TableCell>
			<TableCell>
				{restrictions.map((restriction, index) => {
					return (
						<Badge key={`${restriction}-${index}`} pill className="inline">
							{restriction}
						</Badge>
					);
				})}
			</TableCell>
		</TableRow>
	);
};
