import React, { FC, useState } from 'react';
import { Button, Form, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	modal: {
		width: '90%',
		maxWidth: 450,
		margin: '0 auto',
	},
});

interface PromptModalProps extends ModalProps {
	title: string;
	onAccept(value: string): void;
}

export const PromptModal: FC<PromptModalProps> = ({ title, onAccept, ...props }) => {
	const classes = useStyles();
	const [inputValue, setInputValue] = useState('');

	const handleOnEnter = () => {
		setInputValue('');
	};

	const handleFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		onAccept(inputValue);
	};

	return (
		<Modal dialogClassName={classes.modal} centered {...props} onEnter={handleOnEnter}>
			<Modal.Header>
				<div className="mb-2 d-flex justify-content-between">
					<Modal.Title>{title}</Modal.Title>
					<Button variant="link" onClick={props.onHide}>
						Close
					</Button>
				</div>
			</Modal.Header>
			<Form onSubmit={handleFormSubmit}>
				<Modal.Body>
					<Form.Control
						type="text"
						value={inputValue}
						onChange={({ currentTarget }) => {
							setInputValue(currentTarget.value);
						}}
					/>
				</Modal.Body>
				<Modal.Footer className="text-right">
					<Button type="submit">Submit</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};
