import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import { LoaderButton } from '@/components';

interface FormButtonsRowProps {
	showLeftButton?: boolean;
	leftButtonTitle?: string;
	leftButtonDisabled?: boolean;
	leftButtonIsLoading?: boolean;
	leftButtonOnClick?(event: React.MouseEvent<HTMLButtonElement>): void;
	showRightButton?: boolean;
	rightButtonTitle?: string;
	rightButtonDisabled?: boolean;
	rightButtonIsLoading?: boolean;
	rightButtonOnClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export const FormButtonsRow: FC<FormButtonsRowProps> = ({
	showLeftButton = true,
	leftButtonTitle,
	leftButtonDisabled,
	leftButtonIsLoading,
	leftButtonOnClick,
	showRightButton = true,
	rightButtonTitle,
	rightButtonDisabled,
	rightButtonIsLoading,
	rightButtonOnClick,
}) => {
	return (
		<Row>
			<Col>
				<hr className="mb-6" />
				<div className="d-flex justify-content-between">
					<div>
						{showLeftButton && (
							<LoaderButton
								variant="secondary"
								onClick={leftButtonOnClick}
								disabled={leftButtonDisabled}
								isLoading={leftButtonIsLoading}
							>
								{leftButtonTitle}
							</LoaderButton>
						)}
					</div>
					<div>
						{showRightButton && (
							<LoaderButton
								onClick={rightButtonOnClick}
								disabled={rightButtonDisabled}
								isLoading={rightButtonIsLoading}
							>
								{rightButtonTitle}
							</LoaderButton>
						)}
					</div>
				</div>
			</Col>
		</Row>
	);
};
