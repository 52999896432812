import React, { FC } from 'react';

interface FormSectionProps {
	title: string;
	description?: string;
	isLast?: boolean;
}

export const FormSection: FC<FormSectionProps> = ({ title, description, isLast, children }) => {
	return (
		<section className={isLast ? '' : 'mb-10'}>
			<h2 className={description ? 'mb-2' : 'mb-6'}>{title}</h2>
			{description && <p className="mb-6">{description}</p>}
			{children}
		</section>
	);
};
