import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	tdBankLogoOuter: {
		width: 125,
		'& img': {
			maxWidth: '100%',
		},
	},
	pennMedLogoOuter: {
		width: 272,
		marginLeft: 36,
		'& img': {
			maxWidth: '100%',
		},
	},
});

export const Footer: FC = () => {
	const classes = useStyles();

	return (
		<footer>
			<Container className="pt-11 pb-11">
				<Row>
					<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
						<p className="mb-2 text-white text-center">Made possible by:</p>
						<div className="d-flex align-items-center justify-content-center">
							<div className={classes.tdBankLogoOuter}>
								<img src="/static/td-bank@2x.png" alt="TD Bank" />
							</div>
							<div className={classes.pennMedLogoOuter}>
								<img src="/static/chea@2x.png" alt="CHEA" />
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};
