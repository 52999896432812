export enum SECONDARY_NUTRITION_RISKS {
	NEW_DIAGNOSIS = 'NEW_DIAGNOSIS',
	MEDICAL_TREATMENT = 'MEDICAL_TREATMENT',
	WEIGHT_LOSS = 'WEIGHT_LOSS',
	HOSPITALIZATION = 'HOSPITALIZATION',
	SURGERY = 'SURGERY',
}

export const secondaryNutritionRisks = {
	[SECONDARY_NUTRITION_RISKS.NEW_DIAGNOSIS]: {
		secondaryNutritionRiskId: SECONDARY_NUTRITION_RISKS.NEW_DIAGNOSIS,
		secondaryNutritionRiskTitle: 'New diagnosis with disease-related complications',
		secondaryNutritionRiskAbbreviation: 'New diagnosis',
	},
	[SECONDARY_NUTRITION_RISKS.MEDICAL_TREATMENT]: {
		secondaryNutritionRiskId: SECONDARY_NUTRITION_RISKS.MEDICAL_TREATMENT,
		secondaryNutritionRiskTitle: 'Start of medical treatments (e.g. hemodialysis, chemotherapy, wound care)',
		secondaryNutritionRiskAbbreviation: 'New medical treatments',
	},
	[SECONDARY_NUTRITION_RISKS.WEIGHT_LOSS]: {
		secondaryNutritionRiskId: SECONDARY_NUTRITION_RISKS.WEIGHT_LOSS,
		secondaryNutritionRiskTitle: 'Recent, unintentional weight loss',
		secondaryNutritionRiskAbbreviation: 'Recent weight loss',
	},
	[SECONDARY_NUTRITION_RISKS.HOSPITALIZATION]: {
		secondaryNutritionRiskId: SECONDARY_NUTRITION_RISKS.HOSPITALIZATION,
		secondaryNutritionRiskTitle: 'Hospitalization for at least 3 days within the last month',
		secondaryNutritionRiskAbbreviation: 'Hospitalization',
	},
	[SECONDARY_NUTRITION_RISKS.SURGERY]: {
		secondaryNutritionRiskId: SECONDARY_NUTRITION_RISKS.SURGERY,
		secondaryNutritionRiskTitle: 'Recovery from a recent surgery',
		secondaryNutritionRiskAbbreviation: 'Recovery from surgery',
	},
};
