import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

const useTableBodyStyles = createUseStyles({
	tableBody: {},
});

export const TableBody: FC = React.memo((props) => {
	const classes = useTableBodyStyles();

	return <tbody className={classes.tableBody}>{props.children}</tbody>;
});

TableBody.displayName = 'TableBody';
