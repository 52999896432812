import { createUseStyles } from 'react-jss';
import Color from 'color';

import { theme } from '@/jss';

export const useGlobalStyles = createUseStyles({
	'@global': {
		html: {
			fontSize: 10,
			height: '100%',
		},
		body: {
			minHeight: '100%',
			position: 'relative',
			color: theme.colors.graySeven,
			fontSize: theme.fonts.sizes[16],
			backgroundColor: theme.colors.grayOne,
			lineHeight: theme.fonts.lineHeights[19],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.regular,
		},
		h1: {
			fontSize: theme.fonts.sizes[32],
			lineHeight: theme.fonts.lineHeights[38],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		h2: {
			fontSize: theme.fonts.sizes[20],
			lineHeight: theme.fonts.lineHeights[24],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		h3: {
			fontSize: theme.fonts.sizes[18],
			lineHeight: theme.fonts.lineHeights[21],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		h4: {
			letterSpacing: 0.4,
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[24],
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
			fontWeight: theme.fonts.robotoCondensed.weights.bold,
		},
		h5: {
			letterSpacing: 0.2,
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[24],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		h6: {
			fontSize: theme.fonts.sizes[14],
			lineHeight: theme.fonts.lineHeights[19],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		p: {
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[24],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.regular,
		},
		small: {
			fontSize: theme.fonts.sizes[14],
			lineHeight: theme.fonts.lineHeights[19],
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
			fontWeight: theme.fonts.robotoCondensed.weights.regular,
		},
		a: {
			letterSpacing: 0.1,
			color: theme.colors.blueTwo,
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
			'&:hover': {
				color: theme.colors.blueTwo,
				textDecoration: 'underline',
			},
			'&:active': {
				color: Color(theme.colors.blueTwo).darken(0.16).hex(),
			},
		},
		blockquote: {
			paddingLeft: 23,
			borderLeft: `3px solid ${Color(theme.colors.blueTwo).alpha(0.5).string()}`,
		},

		'.opacity-transition-enter': {
			opacity: 0,
		},
		'.opacity-transition-enter-active': {
			opacity: 1,
			transition: 'opacity 100ms',
		},
		'.opacity-transition-exit': {
			opacity: 1,
		},
		'.opacity-transition-exit-active': {
			opacity: 0,
			transition: 'opacity 100ms',
		},
	},
});
