import { v4 as uuid } from 'uuid';
import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { useAlert } from '@/hooks';
import { theme } from '@/jss';
import { TimeoutCircle } from '@/components';

const transitionDuration = 200;

const useAlertStyles = createUseStyles({
	alertOuter: {
		top: 20,
		left: '50%',
		zIndex: 1051, // gotta be above Bootstraps shadow
		position: 'fixed',
		transition: `all ${transitionDuration}ms`,
	},
	alert: ({ alertIsDismissible }: { alertIsDismissible: boolean }) => ({
		display: 'flex',
		alignItems: 'center',
		transition: `background-color ${transitionDuration}ms`,
		padding: `15px ${alertIsDismissible ? '40px' : '25px'} 15px 25px`,
		'&.success': {
			backgroundColor: theme.colors.greenOne,
		},
		'&.warning': {
			backgroundColor: theme.colors.yellowOne,
		},
		'&.danger': {
			backgroundColor: theme.colors.redOne,
		},
	}),
	hideAlertButton: {
		right: 0,
		border: 0,
		width: 44,
		height: 44,
		top: '50%',
		appearance: 'none',
		position: 'absolute',
		transform: 'translateY(-50%)',
		backgroundColor: 'transparent',
		'&:before, &:after': {
			top: '50%',
			width: 20,
			height: 3,
			left: '50%',
			content: '""',
			position: 'absolute',
			backgroundColor: theme.colors.white,
		},
		'&:before': {
			transform: 'translate(-50%, -50%) rotate(-45deg)',
		},
		'&:after': {
			transform: 'translate(-50%, -50%) rotate(45deg)',
		},
	},
	'@global': {
		'.alert-enter': {
			opacity: 0,
			transform: 'translate(-50%, -100%)',
		},
		'.alert-enter-active': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-enter-done': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-exit': {
			opacity: 1,
			transform: 'translate(-50%, 0)',
		},
		'.alert-exit-active': {
			opacity: 0,
			transform: 'translate(-50%, -100%)',
		},
	},
});

export const Alert: FC = () => {
	const { alertIsShowing, alertChildren, alertVariant, alertDuration, alertIsDismissible, hideAlert } = useAlert();
	const classes = useAlertStyles({ alertIsDismissible });

	const [timeoutCircleId, setTimeoutCircleId] = useState(uuid());

	useEffect(() => {
		setTimeoutCircleId(uuid());
	}, [alertChildren]);

	return (
		<CSSTransition
			in={alertIsShowing}
			timeout={transitionDuration}
			classNames="alert"
			mountOnEnter={true}
			unmountOnExit={true}
		>
			<div className={classes.alertOuter}>
				<div className={classNames([classes.alert, alertVariant])}>
					<TimeoutCircle
						key={timeoutCircleId}
						size={20}
						strokeWidth={3}
						durationInSeconds={alertDuration / 1000}
						className={'mr-2'}
						strokeColor={'white'}
						trackColor={'rgba(0, 0, 0, 0.24)'}
					/>
					{alertChildren}
					{alertIsDismissible && <button className={classes.hideAlertButton} onClick={hideAlert} />}
				</div>
			</div>
		</CSSTransition>
	);
};
