import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useAccount, useHandleError, useQuery } from '@/hooks';

import { DeliveryInlineAlert, FadeTransition, RequestDetailTemplate } from '@/components';

import { foodRequestService } from '@/core/services';
import { FoodRequestModel, FOOD_REQUEST_TYPE } from '@/core/models';
import { Loader } from './loader';
import { InlineAlert } from './inline-alert';

const useStyles = createUseStyles({
	requestDetailModal: {
		width: '90%',
		maxWidth: 1200,
		margin: '0 auto',
	},
});

interface RequestDetailModalProps extends ModalProps {
	foodRequestId: string;
	onClaim(foodRequestId: string): void;
	onDecline?(foodRequestId: string): void;
}

export const RequestDetailModal: FC<RequestDetailModalProps> = ({ foodRequestId, onClaim, onDecline, ...props }) => {
	const classes = useStyles();
	const handleError = useHandleError();
	const { account } = useAccount();

	const query = useQuery();
	const showDeclineQuery = (query.get('showDecline') ?? '') === 'true';

	const [isLoading, setIsLoading] = useState(true);
	const [foodRequest, setFoodRequest] = useState<FoodRequestModel>();
	const [showDeclineButton, setShowDeclineButton] = useState(false);

	useEffect(() => {
		async function fetchFoodRequest() {
			setIsLoading(true);

			try {
				const response = await foodRequestService.getFoodRequestById(foodRequestId).fetch();
				setFoodRequest(response.foodRequest);

				if (typeof response.foodRequest.previouslyDeclined === 'boolean') {
					setShowDeclineButton(showDeclineQuery && response.foodRequest.previouslyDeclined === false);
				}
			} catch (error) {
				handleError(error);
			}

			setIsLoading(false);
		}

		if (props.show) {
			fetchFoodRequest();
		}
	}, [foodRequestId, handleError, props.show, showDeclineQuery]);

	function handleOnExited() {
		setIsLoading(true);
		setFoodRequest(undefined);
	}

	return (
		<Modal dialogClassName={classes.requestDetailModal} centered onExited={handleOnExited} {...props}>
			<Modal.Header>
				<Button variant="link" className="mb-1" onClick={props.onHide}>
					Close
				</Button>
				{foodRequest &&
					foodRequest.foodRequestTypeId !== FOOD_REQUEST_TYPE.DELIVERY_ONLY &&
					account?.institution.institutionId !== foodRequest.createdBy.account.institution.institutionId &&
					!foodRequest.recipientToPickup && <DeliveryInlineAlert />}
				{foodRequest && foodRequest.dietaryRestrictions && foodRequest.dietaryRestrictions.length > 0 && (
					<InlineAlert className="mt-3 mb-5" variant="warning" outline>
						<strong>This recipient has requested food that accommodates their dietary restrictions.</strong>{' '}
						Please review the restriction details before claiming.
					</InlineAlert>
				)}
				<div className="mb-2 d-lg-flex justify-content-between align-items-end">
					<h1 className="mb-2 mg-lg-2">Request for {foodRequest?.foodRequestTypeIdDescription}</h1>
					<div className="d-flex align-items-center">
						{showDeclineButton && (
							<Button
								variant="outline-danger"
								className="mr-2"
								onClick={() => {
									if (onDecline) {
										onDecline(foodRequestId);
									}
								}}
							>
								Decline
							</Button>
						)}
						{foodRequest?.institutionId !== account?.institution.institutionId && (
							<Button
								onClick={() => {
									onClaim(foodRequestId);
								}}
							>
								Claim this request
							</Button>
						)}
					</div>
				</div>
				<hr />
			</Modal.Header>
			<Modal.Body>
				<>
					<FadeTransition in={isLoading}>
						<div className="d-flex justify-content-center">
							<Loader />
						</div>
					</FadeTransition>
					<FadeTransition in={!!foodRequest}>
						{foodRequest && (
							<RequestDetailTemplate foodRequest={foodRequest} onStatusUpdate={setFoodRequest} />
						)}
					</FadeTransition>
				</>
			</Modal.Body>
		</Modal>
	);
};
