import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

import { FOOD_REQUEST_TYPE } from '@/core/models';
import { RequestStatusDot, RequestTypeBadge } from '@/components';
import { theme } from '@/jss';

const useStyles = createUseStyles({
	browseRequestMobileRow: {
		padding: 15,
		marginBottom: 5,
		backgroundColor: theme.colors.white,
	},
	restrictionBadge: {
		margin: '2.5px 5px 2.5px 0',
	},
});

interface BrowseRequestsMobileRowProps {
	className?: string;
	type: FOOD_REQUEST_TYPE;
	posted: string;
	urgent?: boolean;
	needsSupport?: boolean;
	transportationMethod: string;
	restrictions: string[];
	onClick?(): void;
}

export const BrowseRequestsMobileRow: FC<BrowseRequestsMobileRowProps> = ({
	type,
	posted,
	urgent,
	needsSupport,
	transportationMethod,
	restrictions,
	className,
	onClick,
}) => {
	const classes = useStyles();

	return (
		<div className={classNames(classes.browseRequestMobileRow, className)} tabIndex={0} onClick={onClick}>
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<RequestTypeBadge requestType={type} />
					{urgent && <RequestStatusDot className="ml-1" urgent />}
					{needsSupport && <RequestStatusDot className="ml-1" needsSupport />}
				</div>
				<div className="text-right">
					<small className="d-block text-muted">{posted}</small>
					<small className="d-block text-muted">{transportationMethod}</small>
				</div>
			</div>
			{restrictions.length > 0 && (
				<div className="mt-2">
					{restrictions.map((restriction, index) => {
						return (
							<Badge key={`${restriction}-${index}`} pill className={classes.restrictionBadge}>
								{restriction}
							</Badge>
						);
					})}
				</div>
			)}
		</div>
	);
};
