import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import config from '@/core/config';
import { theme } from '@/jss';

const useRenderJsonStyles = createUseStyles({
	renderJson: {
		padding: 16,
		marginTop: 24,
		borderRadius: 4,
		fontSize: theme.fonts.sizes[14],
		lineHeight: theme.fonts.lineHeights[19],
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.grayFour}`,
	},
});

interface RenderJsonProps {
	json: any;
}

export const RenderJson: FC<RenderJsonProps> = (props) => {
	const classes = useRenderJsonStyles();

	if (config.FAST_SHOW_DEBUG === 'true') {
		return <pre className={classes.renderJson}>{JSON.stringify(props.json, null, 4)}</pre>;
	}

	return null;
};
