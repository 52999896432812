import React, { FC } from 'react';

import { RequestStatusDot, RequestTypeBadge, StatusSelect } from '@/components';
import { TableCell, TableRow } from '@/components/table';
import { REQUEST_STATUS, FoodRequestModel, YES_NO_UNKNOWN } from '@/core/models';

interface TrackRequestsTableBodyRowProps {
	request: FoodRequestModel;
	onClick(): void;
	onStatusChange(newStatus: REQUEST_STATUS): void;
}

export const TrackRequestsTableBodyRow: FC<TrackRequestsTableBodyRowProps> = ({ request, onClick, onStatusChange }) => {
	return (
		<TableRow tabIndex={0} onClick={onClick}>
			<TableCell>
				<div className="d-flex align-items-center justify-content-between">
					<RequestTypeBadge requestType={request.foodRequestTypeId} />
					<div className="d-flex">
						<>
							{request.urgentNeedId === YES_NO_UNKNOWN.YES && <RequestStatusDot className="ml-1" urgent />}
							{request.requestDurationDays > 7 && <RequestStatusDot className="ml-1" needsSupport />}
						</>
					</div>
				</div>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{request.createdBy.account.institution.name}</small>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{request.claimedBy?.account.institution.name}</small>
			</TableCell>
			<TableCell>
				<small className="d-block text-muted">{request.lastUpdatedDescription}</small>
			</TableCell>
			<TableCell>
				<StatusSelect
					value={request.foodRequestStatusId as REQUEST_STATUS}
					onChange={(event) => {
						onStatusChange(event.currentTarget.value as REQUEST_STATUS);
					}}
				>
					<option value={request.foodRequestStatusId} disabled>
						{request.foodRequestStatusDescription}
					</option>
					{request.availableStatuses.map((availableStatus) => {
						return (
							<option
								key={availableStatus.foodRequestStatusId}
								value={availableStatus.foodRequestStatusId}
							>
								{availableStatus.description}
							</option>
						);
					})}
				</StatusSelect>
			</TableCell>
		</TableRow>
	);
};
