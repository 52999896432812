import Color from 'color';
import { theme } from '@/jss';

export const modal = {
	'.modal-content': {
		border: 0,
		borderRadius: 0,
		backgroundColor: theme.colors.grayOne,
	},
	'.fast-modal': {
		'&__title': {
			marginBottom: 0,
			fontSize: theme.fonts.sizes[20],
			lineHeight: theme.fonts.lineHeights[24],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		'&__header': {
			padding: '30px 30px 0',
			'& > button.close': {
				display: 'block',
			},
		},
		'&__body': {
			padding: 30,
		},
		'&__footer': {
			'&:before': {
				content: '""',
				display: 'block',
				marginBottom: 24,
				borderTop: `1px solid ${Color(theme.colors.grayFive).alpha(0.2).string()}`,
			},
			padding: '0px 30px 30px',
		},
	},
};
