export enum HEALTH_CONDITIONS {
	CANCER = 'CANCER',
	DIABETES = 'DIABETES',
	RENAL_DISEASE = 'RENAL_DISEASE',
	HYPERTENSION = 'HYPERTENSION',
	HEART_DISEASE_FAILURE = 'HEART_DISEASE_FAILURE',
	ASTHMA = 'ASTHMA',
	HEP_C_LIVER_DISEASE = 'HEP_C_LIVER_DISEASE',
	HIV_AIDS = 'HIV_AIDS',
	DISABILITY = 'DISABILITY',
	OTHER = 'OTHER',
}

export const healthConditions = {
	[HEALTH_CONDITIONS.CANCER]: {
		healthConditionId: HEALTH_CONDITIONS.CANCER,
		healthConditionTitle: 'Cancer',
	},
	[HEALTH_CONDITIONS.DIABETES]: {
		healthConditionId: HEALTH_CONDITIONS.DIABETES,
		healthConditionTitle: 'Diabetes',
	},
	[HEALTH_CONDITIONS.RENAL_DISEASE]: {
		healthConditionId: HEALTH_CONDITIONS.RENAL_DISEASE,
		healthConditionTitle: 'Renal Disease',
	},
	[HEALTH_CONDITIONS.HYPERTENSION]: {
		healthConditionId: HEALTH_CONDITIONS.HYPERTENSION,
		healthConditionTitle: 'Hypertension',
	},
	[HEALTH_CONDITIONS.HEART_DISEASE_FAILURE]: {
		healthConditionId: HEALTH_CONDITIONS.HEART_DISEASE_FAILURE,
		healthConditionTitle: 'Heart Disease/Heart Failure',
	},
	[HEALTH_CONDITIONS.ASTHMA]: {
		healthConditionId: HEALTH_CONDITIONS.ASTHMA,
		healthConditionTitle: 'Asthma',
	},
	[HEALTH_CONDITIONS.HEP_C_LIVER_DISEASE]: {
		healthConditionId: HEALTH_CONDITIONS.HEP_C_LIVER_DISEASE,
		healthConditionTitle: 'Hep C or Liver Disease',
	},
	[HEALTH_CONDITIONS.HIV_AIDS]: {
		healthConditionId: HEALTH_CONDITIONS.HIV_AIDS,
		healthConditionTitle: 'HIV/AIDS',
	},
	[HEALTH_CONDITIONS.DISABILITY]: {
		healthConditionId: HEALTH_CONDITIONS.DISABILITY,
		healthConditionTitle: 'Disability',
	},
	[HEALTH_CONDITIONS.OTHER]: {
		healthConditionId: HEALTH_CONDITIONS.OTHER,
		healthConditionTitle: 'Other',
	},
};
