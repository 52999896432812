import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import Color from 'color';

import { foodRequestTypes, FOOD_REQUEST_TYPE } from '@/core/models';

import { theme } from '@/jss';

import { ReactComponent as SaladIcon } from '@/assets/salad.svg';
import { ReactComponent as AppleIcon } from '@/assets/apple.svg';
import { ReactComponent as TruckIcon } from '@/assets/truck.svg';

const useStyles = createUseStyles({
	requestTypeBadge: ({ requestType }: { requestType: FOOD_REQUEST_TYPE }) => {
		let backgroundColor = theme.colors.grayThree;

		switch (requestType) {
			case FOOD_REQUEST_TYPE.DELIVERY_ONLY:
				backgroundColor = theme.colors.grayFour;
				break;
			case FOOD_REQUEST_TYPE.PREPARED_MEALS:
				backgroundColor = theme.colors.yellowOne;
				break;
			case FOOD_REQUEST_TYPE.UNPREPARED_FOOD:
				backgroundColor = theme.colors.greenOne;
				break;
			default:
				backgroundColor = theme.colors.grayThree;
				break;
		}

		return {
			flexShrink: 0,
			display: 'flex',
			borderRadius: 500,
			padding: '8px 10px',
			alignItems: 'center',
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[19],
			fontFamily: theme.fonts.robotoCondensed.fontFamily,
			fontWeight: theme.fonts.robotoCondensed.weights.regular,
			backgroundColor: Color(backgroundColor).alpha(0.2).string(),
		};
	},
	iconOuter: {
		width: 20,
		height: 20,
		marginRight: 6,
	},
});

interface RequestTypeBadgeProps {
	requestType: FOOD_REQUEST_TYPE;
}

export const RequestTypeBadge: FC<RequestTypeBadgeProps> = ({ requestType }) => {
	const classes = useStyles({
		requestType,
	});

	return (
		<div className={classes.requestTypeBadge}>
			<div className={classes.iconOuter}>
				{requestType === FOOD_REQUEST_TYPE.PREPARED_MEALS && <SaladIcon />}
				{requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD && <AppleIcon />}
				{requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY && <TruckIcon />}
			</div>
			<span>{foodRequestTypes[requestType].requestTypeTitle}</span>
		</div>
	);
};
