import React, { FC } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

import { theme } from '@/jss';

const useStyles = createUseStyles({
	'@keyframes rotate': {
		from: {
			transform: 'rotate(0deg)',
		},
		to: {
			transform: 'rotate(360deg)',
		},
	},
	loader: ({ size }: { size: number }) => ({
		width: size,
		height: size,
	}),
	svgMain: {
		display: 'block',
	},
	svgTrack: ({ trackStroke }: { trackStroke: string }) => ({
		stroke: trackStroke,
	}),
	svgCircle: ({ stroke }: { stroke: string }) => ({
		stroke,
		transformOrigin: 'center',
		animation: '$rotate 1s linear infinite',
	}),
});

interface LoaderProps {
	className?: string;
	size?: number;
	stroke?: string;
	strokeWidth?: number;
	strokeLengthPercent?: number;
	trackStroke?: string;
}

export const Loader: FC<LoaderProps> = ({
	size = 50,
	stroke = theme.colors.blueTwo,
	strokeWidth = 5,
	strokeLengthPercent = 0.25,
	trackStroke = theme.colors.grayThree,
	className,
}) => {
	const radius = size / 2;
	const normalizedRadius = radius - strokeWidth / 2;
	const circumference = normalizedRadius * 2 * Math.PI;

	const classes = useStyles({
		size,
		stroke,
		trackStroke,
	});

	return (
		<div className={classNames([classes.loader, className])}>
			<svg
				className={classes.svgMain}
				width={size}
				height={size}
				viewBox={`0 0 ${size} ${size}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<circle
						fill="transparent"
						strokeWidth={strokeWidth}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
						className={classes.svgTrack}
					/>
					<circle
						fill="transparent"
						strokeLinecap="round"
						strokeWidth={strokeWidth}
						strokeDasharray={circumference}
						strokeDashoffset={circumference * (1 - strokeLengthPercent)}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
						className={classes.svgCircle}
					/>
				</g>
			</svg>
		</div>
	);
};
