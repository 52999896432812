const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export function isValidZipCode(string: string) {
	const regEx = /^\d{5}(-\d{4})?$/;

	return regEx.test(string);
}

export function isValidPhoneNumber(string: string) {
	try {
		const parsedString = phoneUtil.parse(string, 'US');
		return phoneUtil.isValidNumberForRegion(parsedString, 'US');
	} catch (error) {
		return false;
	}
}
