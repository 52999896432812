import Color from 'color';
import { theme } from '@/jss';

export const form = {
	'.fast-form': {
		'&__label': {
			marginBottom: 8,
			display: 'block',
			fontSize: theme.fonts.sizes[16],
			lineHeight: theme.fonts.lineHeights[19],
			fontFamily: theme.fonts.roboto.fontFamily,
			fontWeight: theme.fonts.roboto.weights.bold,
		},
		'&__control': {
			width: '100%',
			borderRadius: 0,
			display: 'block',
			appearance: 'none',
			lineHeight: 'normal',
			fontSize: theme.fonts.sizes[16],
			fontFamily: theme.fonts.roboto.fontFamily,
			border: `1px solid ${theme.colors.grayFour}`,
			fontWeight: theme.fonts.roboto.weights.regular,
			'&[type=date], &[type=datetime-local], &[type=email], &[type=month], &[type=number], &[type=password], &[type=search], &[type=tel], &[type=text], &[type=time], &[type=url], &[type=week]': {
				height: 46,
				padding: '0 16px',
			},
			'&:hover': {
				borderColor: theme.colors.grayFive,
			},
			'&:focus': {
				outline: 'none',
				borderColor: theme.colors.blueTwo,
			},
			'&:disabled': {
				cursor: 'default',
				backgroundColor: `rgba(118, 123, 133, 0.1)`,
				'&:hover': {
					borderColor: theme.colors.grayFour,
				},
			},
		},
		'&__check': {
			'&-inline': {
				marginRight: 36,
				display: 'inline-block',
			},
			'& input[type=checkbox], & input[type=radio]': {
				width: 0,
				height: 0,
				opacity: 0,
				'& + label': {
					paddingLeft: 23,
					cursor: 'pointer',
					position: 'relative',
					color: theme.colors.graySeven,
					fontSize: theme.fonts.sizes[16],
					lineHeight: theme.fonts.lineHeights[19],
					fontFamily: theme.fonts.roboto.fontFamily,
					fontWeight: theme.fonts.roboto.weights.regular,
					'&:before': {
						top: 2,
						left: 0,
						width: 14,
						height: 14,
						content: '""',
						position: 'absolute',
						backgroundColor: 'transparent',
						border: `1px solid ${theme.colors.grayFive}`,
					},
					'&:hover:before': {
						backgroundColor: Color(theme.colors.grayFive).alpha(0.16).string(),
					},
				},
				'&:checked + label': {
					'&:before': {
						backgroundPosition: 'center',
						borderColor: theme.colors.blueOne,
						backgroundColor: theme.colors.blueOne,
						backgroundImage: 'url(/static/checkmark.svg)',
					},
					'&:hover:before': {
						borderColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
						backgroundColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
					},
				},
				'&:focus + label': {
					'&:before': {
						border: `1px solid ${theme.colors.blueOne}`,
					},
				},
			},
			'& input[type=radio]': {
				'& + label': {
					'&:before': {
						borderRadius: 7,
					},
				},
				'&:checked + label': {
					'&:before': {
						backgroundColor: 'transparent !important',
					},
					'&:after': {
						top: 6,
						left: 4,
						width: 6,
						height: 6,
						content: '""',
						borderRadius: 3,
						position: 'absolute',
						backgroundColor: theme.colors.blueOne,
					},
					'&:hover:after': {
						backgroundColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
					},
				},
			},
		},
		'&__check-badge': {
			'&-inline': {
				marginRight: 5,
				marginBottom: 5,
				display: 'inline-block',
			},
			'& input[type=checkbox]': {
				width: 0,
				height: 0,
				opacity: 0,
				'& + label': {
					cursor: 'pointer',
					borderRadius: 19,
					padding: '8px 12px',
					color: theme.colors.graySix,
					fontSize: theme.fonts.sizes[16],
					lineHeight: theme.fonts.lineHeights[19],
					fontFamily: theme.fonts.robotoCondensed.fontFamily,
					fontWeight: theme.fonts.robotoCondensed.weights.regular,
					border: `1px solid ${Color(theme.colors.grayFive).lighten(0.65).hex()}`,
					'&:hover': {
						backgroundColor: Color(theme.colors.grayFive).alpha(0.16).string(),
					},
				},
				'&:checked + label': {
					color: theme.colors.white,
					borderColor: theme.colors.blueOne,
					backgroundColor: theme.colors.blueOne,
					'&:hover': {
						borderColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
						backgroundColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
					},
				},
				'&:focus + label': {
					border: `1px solid ${theme.colors.blueOne}`,
				},
			},
		},
		'&__check-request-type': {
			'& input[type=radio]': {
				width: 0,
				height: 0,
				opacity: 0,
				'& + label': {
					width: '100%',
					cursor: 'pointer',
					padding: '18px 8px',
					textAlign: 'center',
					color: theme.colors.blueOne,
					fontSize: theme.fonts.sizes[16],
					lineHeight: theme.fonts.lineHeights[19],
					border: `1px solid ${theme.colors.blueOne}`,
					fontFamily: theme.fonts.robotoCondensed.fontFamily,
					fontWeight: theme.fonts.robotoCondensed.weights.regular,
					'&:hover': {
						backgroundColor: Color(theme.colors.blueOne).lighten(0.65).hex(),
					},
				},
				'&:checked + label': {
					color: theme.colors.white,
					backgroundColor: theme.colors.blueOne,
					'& svg': {
						'& path': {
							fill: theme.colors.white,
						},
					},
					'&:hover': {
						borderColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
						backgroundColor: Color(theme.colors.blueOne).lighten(0.16).hex(),
					},
				},
			},
		},
	},
	'textarea.fast-form__control': {
		height: 143,
		resize: 'none',
		padding: '12px 16px',
	},
	'select.fast-form__control': {
		height: 46,
		cursor: 'pointer',
		padding: '0 16px',
		backgroundImage: `url(${encodeURI(
			`data:image/svg xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="292.4" height="292.4"><path fill=${theme.colors.grayFour} d="M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z"/></svg>`
		)})`,
		backgroundRepeat: 'no-repeat, repeat',
		backgroundPosition: 'right 16px top 50%',
		backgroundSize: '.65em auto',

		'&:invalid': {
			color: theme.colors.grayFive,
		},
	},
};
