import { cloneDeep } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';

interface CheckboxListOption {
	value: string;
	title: string;
}

interface CheckboxListProps {
	name: string;
	options: CheckboxListOption[];
	values: string[];
	onChange(values: string[]): void;
	hasTwoColumns?: boolean;
}

export function CheckboxList({ name, options, hasTwoColumns, values, onChange }: CheckboxListProps) {
	function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
		const dataClone = cloneDeep(values);

		if (event.currentTarget.checked) {
			dataClone.push(event.currentTarget.value);
		} else {
			const targetIndex = dataClone.findIndex((selectedValue) => {
				return selectedValue === event.currentTarget.value;
			});

			dataClone.splice(targetIndex, 1);
		}

		onChange(dataClone);
	}

	return (
		<div className={hasTwoColumns ? 'column-count-2' : ''}>
			{options.map((option) => {
				return (
					<Form.Check
						key={option.value}
						className="mb-3"
						type="checkbox"
						name={name}
						id={`${name}--${option.value}`}
						label={option.title}
						value={option.value}
						checked={values.includes(option.value)}
						onChange={handleCheckboxChange}
					/>
				);
			})}
		</div>
	);
}
