import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useHandleError } from '@/hooks';

import { mediaQueries, theme } from '@/jss';
import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';
import { LoaderButton } from '@/components';
import { accountService } from '@/core/services';

const useStyles = createUseStyles({
	outer: {
		padding: 80,
		backgroundColor: theme.colors.grayOne,
		[mediaQueries.md]: {
			padding: 50,
		},
	},
});

export const ForgotPassword: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const handleError = useHandleError();

	const [email, setEmail] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();

		try {
			setIsSubmitting(true);

			await accountService.sendForgotPasswordEmail(email).fetch();
			history.push('/sign-in?passwordEmailSent=true');
		} catch (error) {
			setIsSubmitting(false);
			handleError(error);
		}
	}

	return (
		<Container className="pt-20">
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<div className={classes.outer}>
						<div className="mb-2 d-flex justify-content-center">
							<FastLogo />
						</div>
						<h4 className="mb-8 text-center text-blue-three">
							<em>Food Access Support Technology</em>
						</h4>
						<h5 className="mb-2">Forgot Password</h5>
						<hr className="mb-2" />
						<p className="mb-6">
							To reset your password, enter the email address associated with your FAST account. We will
							send you a link to reset your password.
						</p>
						<Form onSubmit={handleFormSubmit}>
							<Form.Group className="mb-6">
								<Form.Label>Your email</Form.Label>
								<Form.Control
									type="email"
									value={email}
									onChange={(event) => {
										setEmail(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<div className="text-center">
								<LoaderButton type="submit" isLoading={isSubmitting}>
									Submit
								</LoaderButton>
							</div>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
