import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useTableStyles = createUseStyles({
	tableOuter: {
		flex: 1,
		width: '100%',
		overflowX: 'auto',
	},
	table: {
		width: '100%',
	},
});

interface TableProps {
	className?: string;
}

export const Table: FC<TableProps> = React.memo(({ className, children }) => {
	const classes = useTableStyles();

	return (
		<div className={classNames(className, classes.tableOuter)}>
			<table className={classes.table}>{children}</table>
		</div>
	);
});

Table.displayName = 'Table';
