import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import React, { FC, useState, useCallback } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { COOKIES, DecodedAccessToken } from '@/core/models';
import { accountService } from '@/core/services';

import { useAccount } from '@/hooks';

import { AsyncPage } from '@/components';

const PrivateView: FC = ({ children }) => {
	const history = useHistory();
	const accessToken = Cookies.get(COOKIES.ACCESS_TOKEN);
	const { setAccount } = useAccount();
	const [routeIsInitialized, setRouteIsInitialized] = useState<boolean>(false);

	const fetchData = useCallback(async () => {
		if (routeIsInitialized) {
			return;
		}

		if (!accessToken) {
			Cookies.set(COOKIES.TARGET_URL, `${window.location.pathname}${window.location.search}`);
			history.replace('/sign-in');
			return;
		}

		const decodedAccessToken: DecodedAccessToken = jwtDecode(accessToken);
		const accountResponse = await accountService.getAccount(decodedAccessToken.sub).fetch();

		setRouteIsInitialized(true);
		setAccount(accountResponse.account);
	}, [accessToken, history, routeIsInitialized, setAccount]);

	return <AsyncPage fetchData={fetchData}>{children}</AsyncPage>;
};

export const PrivateRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
	return (
		<Route
			{...routeProps}
			render={() => {
				return <PrivateView>{children}</PrivateView>;
			}}
		/>
	);
};
