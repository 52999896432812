import Cookies from 'js-cookie';
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useAlert, useHandleError, useQuery } from '@/hooks';

import { accountService } from '@/core/services';
import { COOKIES } from '@/core/models';

import { LoaderButton } from '@/components';

import { mediaQueries, theme } from '@/jss';
import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

const useStyles = createUseStyles({
	imageOuter: {
		height: '100%',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundColor: theme.colors.grayThree,
		backgroundImage: 'url(/static/fabalish.jpg)',
	},
	formOuter: {
		padding: 80,
		backgroundColor: theme.colors.grayOne,
		[mediaQueries.md]: {
			padding: 50,
		},
	},
});

export const SignIn: FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const handleError = useHandleError();

	const query = useQuery();
	const accountCreated = query.get('accountCreated');
	const passwordEmailSent = query.get('passwordEmailSent');
	const passwordReset = query.get('passwordReset');
	const { showAlert } = useAlert();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [fetchingAccessToken, setFetchingAccessToken] = useState(false);

	useEffect(() => {
		if (accountCreated === 'true') {
			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							<strong>Your account has been created successfully.</strong> You can now sign in to FAST.
						</p>
					);
				},
			});
		}

		if (passwordEmailSent === 'true') {
			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							<strong>Success!</strong> Your password reset email has been sent.
						</p>
					);
				},
			});
		}

		if (passwordReset === 'true') {
			showAlert({
				variant: 'success',
				children: () => {
					return (
						<p className="mb-0 text-white">
							<strong>Success!</strong> You can now sign in with your new password.
						</p>
					);
				},
			});
		}
	}, [accountCreated, passwordEmailSent, passwordReset, showAlert]);

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();

		try {
			setFetchingAccessToken(true);
			const { accessToken } = await accountService
				.getAccessToken({
					emailAddress: email,
					password,
				})
				.fetch();
			Cookies.set(COOKIES.ACCESS_TOKEN, accessToken);

			const targetUrl = Cookies.get(COOKIES.TARGET_URL) || '/';

			history.push(targetUrl);
			Cookies.remove(COOKIES.TARGET_URL);
		} catch (error) {
			if (error.apiError?.metadata?.ACCOUNT_DELETED) {
				history.push('/account-deleted');
				return;
			}

			handleError(error);
			setFetchingAccessToken(false);
		}
	}

	return (
		<Container className="pt-20">
			<Row noGutters>
				<Col xs={12} md={4} lg={5} xl={6}>
					<div className={classes.imageOuter} />
				</Col>
				<Col xs={12} md={8} lg={7} xl={6}>
					<div className={classes.formOuter}>
						<div className="mb-2 d-flex justify-content-center">
							<FastLogo />
						</div>
						<h4 className="mb-6 text-center text-blue-three">
							<em>Food Access Support Technology</em>
						</h4>
						<p className="mb-8 text-center">Supporting a coordinated response to food insecurity</p>
						<h5 className="mb-2">Sign In</h5>
						<hr className="mb-5" />
						<Form onSubmit={handleFormSubmit}>
							<Form.Group className="mb-3">
								<Form.Label>Your email</Form.Label>
								<Form.Control
									type="email"
									value={email}
									onChange={(event) => {
										setEmail(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-6">
								<div className="d-flex justify-content-between">
									<Form.Label>Password</Form.Label>
									<p>
										<Link to="/forgot-password">Forgot password?</Link>
									</p>
								</div>
								<Form.Control
									type="password"
									value={password}
									onChange={(event) => {
										setPassword(event.currentTarget.value);
									}}
								/>
							</Form.Group>
							<div className="text-center">
								<LoaderButton type="submit" isLoading={fetchingAccessToken}>
									Sign In
								</LoaderButton>
							</div>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
