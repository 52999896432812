import React, { FC } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { Loader } from '@/components';
import { theme } from '@/jss';

const useStyles = createUseStyles({
	loaderButton: {
		position: 'relative',
	},
	childrenOuter: ({ isLoading }: { isLoading: boolean }) => ({
		opacity: isLoading ? 0 : 1,
	}),
	loader: {
		top: '50%',
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},
});

interface LoaderButtonProps extends ButtonProps {
	isLoading?: boolean;
}

export const LoaderButton: FC<LoaderButtonProps> = ({ isLoading, children, ...props }) => {
	const classes = useStyles({
		isLoading,
	});

	return (
		<Button className={classes.loaderButton} {...props}>
			<div className={classes.childrenOuter}>{children}</div>
			{isLoading && (
				<Loader
					size={20}
					strokeWidth={3}
					className={classes.loader}
					stroke={theme.colors.white}
					trackStroke={'transparent'}
				/>
			)}
		</Button>
	);
};
