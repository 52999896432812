import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

export const PrivacyPolicy: FC = () => {
	return (
		<Container className="pt-20 pb-20">
			<Row>
				<Col>
					<div className="mb-10 d-flex justify-content-center">
						<FastLogo />
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<h1 className="mb-4">Privacy Policy of the Food Access Support Technology (FAST) Platform</h1>
					<p className="mb-4">Effective: December 5, 2022</p>
				</Col>
			</Row>
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<p className="mb-5">
						The FAST App Program at Penn Medicine values privacy and the confidentiality of the information
						we collect. This statement describes what information may be collected when you use the FAST App
						about you (the user), your organization, and the individuals you are assisting who need food
						support; how this information may be used by the FAST App Program; and how we protect against
						the unauthorized access or use of this information.
					</p>

					<h5>What Data We Collect</h5>
					<p className="mb-5">
						We collect information about individuals who need food support and the organizations that may
						help them obtain it. This includes collecting contact information to facilitate communications
						among organizations and individuals involved and additional information needed to provide the
						right food support to the food recipient. The information we collect may include but is not
						limited to the items below, and note that some fields are not required:
					</p>
					<ul className="mb-5">
						<li>
							<p>User's contact information (phone number, email) to register to use the FAST App</p>
						</li>
						<li>
							<p>Address for pick-up or delivery orders</p>
						</li>
						<li>
							<p>Information about the Client:</p>
							<ul>
								<li>
									<p>Client's name</p>
								</li>
								<li>
									<p>Phone number</p>
								</li>
								<li>
									<p>Address for delivery</p>
								</li>
								<li>
									<p>Preferred language</p>
								</li>
								<li>
									<p>Dietary restrictions</p>
								</li>
								<li>
									<p>Whether or not the recipient can reheat and store foods</p>
								</li>
								<li>
									<p>If any recipients are under 21 or over 60</p>
								</li>
								<li>
									<p>Benefits eligibility</p>
								</li>
								<li>
									<p>Household income</p>
								</li>
								<li>
									<p>Health system</p>
								</li>
								<li>
									<p>Insurance</p>
								</li>
							</ul>
						</li>
					</ul>
					<p className="mb-5">
						In addition, when users download and use the FAST App, the FAST App Program and our third-party
						service providers may track and collect App usage data in order to improve our services.{' '}
					</p>

					<h5>How We Use the Information Collected</h5>
					<p>
						We will post information about a food request on the FAST platform in order to find an
						organization to claim the request. Identifying information about the food recipient (e.g. name,
						contact information) will only be disclosed to the organization that claims the request.
					</p>
					<p>
						We use information collected and data on user engagement to evaluate and improve the quality of
						the FAST App and our services. The FAST App Program may also use information collected, but with
						no direct identifiers, in connection with an IRB-approved research protocol.
					</p>
					<p className="mb-5">
						Once the FAST app shares information with participating organizations, it is no longer subject
						to this privacy policy. Each organization has its own procedures and policies regarding privacy,
						intake, communication and coordination.
					</p>

					<h5>How We Use Cookies</h5>
					<p className="mb-5">
						Cookies are text files located in browser directories used to track visits to a website over
						time. We use cookies to store login authentication tokens, so if users return to the FAST App on
						the same browser at a later time, they do not have to login again. We do not use cookies to
						track users for analytics or marketing purposes. There is no analytics tracking installed on the
						FAST App. All data reporting is done via information stored in the database.
					</p>

					<h5>Security</h5>
					<p className="mb-5">
						We have in place reasonable physical, technical, and administrative safeguards designed to
						prevent unauthorized access to, or use of, the personal information collected.
					</p>

					<h5>Business Associate and Data Use Terms</h5>
					<p>
						We are in some cases serving healthcare providers that may be covered by HIPAA (covered
						entities). In this role and where applicable, we agree to be bound by the following HIPAA
						Business Associate and Data Use Terms:
					</p>
					<p>
						We will use, access and share protected health information (PHI) received for the purpose of
						supporting the FAST application services and will limit these activities to the extent
						practicable to the minimum necessary PHI to accomplish the intended purpose. We will also use
						PHI for quality assessment and improvement purposes, for the proper management and
						administration of our functions, and to carry out legal responsibilities provided that
						disclosures are required by law or we obtain reasonable assurances from recipients that
						information will remain confidential and used or disclosed only as required by law or for the
						purposes for which it was disclosed and that they will notify us of any possible confidentiality
						incident or breach. We will only use, access and share PHI as described in these Terms.
					</p>
					<p>
						We will require any subcontractors that create, receive, maintain, or transmit PHI on our behalf
						to agree in writing to restrictions and conditions regarding PHI that are as strict as these
						Terms. We will make books and records available to the HHS Secretary and other regulatory
						authorities as they pertain to compliance regarding the use and disclosure of PHI.
					</p>
					<p>
						We will use appropriate security measures including by implementing administrative, physical and
						technical safeguards to prevent PHI use or disclosure other than these Terms provide. We will
						report to covered entities if we become aware of any use or disclosure of their PHI not provided
						for by these Terms. In the event of a Breach of Unsecured PHI accessed or maintained on behalf
						of covered entities, we will report such Breach to the impacted covered entities no later than
						10 business days. This section constitutes notice of the ongoing existence of attempted but
						Unsuccessful Security Incidents that do not result in unauthorized access, use or disclosure,
						for example “pings” on system firewall, port scans, attempted log ons with invalid credentials,
						denial of service attacks that don't result in a service being taken offline, and malware,
						provided they do not result in unauthorized access, use or disclosure of PHI.
					</p>
					<p>
						We agree to make available to covered entities PHI necessary for them to meet HIPAA obligations
						to provide access, amendment, and accounting of disclosures to their patients. To the extent we
						are to carry out a covered entity obligation under the HIPAA Privacy Rule, we will comply with
						the Privacy Rule requirements that apply to the covered entity in the performance of such
						obligation.
					</p>
					<p>
						Covered entities will obtain any and all necessary consents or other permissions under
						applicable law for the disclosure of PHI to the FAST App.
					</p>
					<p>
						Covered entities shall not request us to use or disclose PHI in any way that would violate
						applicable laws, agreements, or notices if done by the covered entity.
					</p>
					<p>
						Where either party is aware of a material breach by the other party, the non-breaching party
						shall provide an opportunity to cure. If not cured within 20 business days, the non-breaching
						party shall if feasible terminate this agreement and covered entity's use of the FAST app.
					</p>
					<p>
						These Terms are effective with regard to covered entities upon their acceptance of the FAST app
						Terms and Conditions of Use and this Privacy Policy and shall terminate upon our receipt of
						notice from the covered entity that it will no longer use the FAST app services.
					</p>
					<p>
						Upon termination of the agreement, we will destroy any directly identifiable PHI and only
						maintain indirect identifiers under HIPAA for purposes of quality improvement and research. We
						will not attempt to reidentify information or contact individuals and will extend protections to
						the maintained data.
					</p>
					<p>
						Nothing in this agreement is intended to confer rights, remedies, obligations or liabilities on
						anyone other than us and the relevant covered entities.
					</p>
					<p className="mb-5">
						Covered entities shall provide us any notices by email to{' '}
						<a href="mailto:healthequity@uphs.upenn.edu">healthequity@uphs.upenn.edu</a> and also by mail to
						Center for Health Equity Advancement RE: FAS App, 3600 Civic Center Blvd 3d Floor, Philadelphia
						19104.
					</p>

					<h5>Changes to Our Privacy Policy</h5>
					<p className="mb-5">
						We reserve the right to modify this Privacy Policy at any time. Any changes will become
						effective when we post the revised Privacy Policy on the Services. Your use of the Services
						following these changes means that you accept the revised Privacy Policy.
					</p>

					<h5>Contact Us</h5>
					<p>
						You can email us with any questions or comments at{' '}
						<a href="mailto:healthequity@uphs.upenn.edu">healthequity@uphs.upenn.edu</a>.
					</p>
				</Col>
			</Row>
		</Container>
	);
};
