import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { mediaQueries, theme } from '@/jss';
import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

const useStyles = createUseStyles({
	outer: {
		padding: 80,
		backgroundColor: theme.colors.grayOne,
		[mediaQueries.md]: {
			padding: 50,
		},
	},
});

export const AccountDeleted: FC = () => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Container className="pt-20 pb-20">
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<div className={classes.outer}>
						<div className="mb-2 d-flex justify-content-center">
							<FastLogo />
						</div>
						<h4 className="mb-6 text-center text-blue-three">
							<em>Food Access Support Technology</em>
						</h4>
						<h4 className="mb-2 text-center">We encountered a problem</h4>
						<p className="mb-10 text-center">
							This account has been deleted. Please contact your organization administrator for help.
						</p>
						<div className="text-center">
							<Button
								onClick={() => {
									history.push('/sign-in');
								}}
							>
								Back to sign in
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};
