import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

export const TermsOfUse: FC = () => {
	return (
		<Container className="pt-20 pb-20">
			<Row>
				<Col>
					<div className="mb-10 d-flex justify-content-center">
						<FastLogo />
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<h1 className="mb-4">Food Access Support Technology (FAST) Terms and Conditions of Use</h1>
					<p className="mb-4">
						Acceptance of the following Terms and Conditions is required for all users to use and manage a
						referral team in FAST
					</p>
				</Col>
			</Row>
			<Row>
				<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
					<p>
						The Food Access Support Technology (FAST) is an Internet website that allows secure web-based
						referrals to food access partners like food pantries, prepared meal providers, food banks, and
						delivery organizations.
					</p>
					<p className="mb-5">
						Access to and use of FAST is subject to the following Terms and Conditions of Use. Access may be
						restricted and/or terminated at any time at the sole discretion of Penn Medicine, the managing
						organization:
					</p>
					<ul className="mb-5">
						<li>
							<p>
								You must be 18 years of age or older to request an account and must be a member of a
								food access partner, community based organization, health system, or small business
								account previously vetted and confirmed by FAST staff.
							</p>
							<ul>
								<li>
									<p>
										Organizations interested in joining FAST should submit an inquiry to{' '}
										<a href="mailto:healthequity@uphs.upenn.edu">healthequity@uphs.upenn.edu</a>
									</p>
								</li>
								<li>
									<p>
										Organizations' administrators complete an intake form. If accepted, the
										administrator will receive a confirmation email to schedule the next steps in
										the onboarding process which includes: FAST demonstration, walk through user
										guide, and check-ins.
									</p>
								</li>
							</ul>
						</li>
						<li>
							<p>
								Keep your password confidential. <strong>DO NOT SHARE YOUR PASSWORD WITH ANYONE</strong>
								, including anyone at FAST. No one at FAST knows your password, and no one at FAST will
								ever ask you for your FAST password. You may change your password online at any time.
							</p>
						</li>
						<li>
							<p>
								You will receive a notification when there is an update to a request you are managing.
								FAST will send both text message and email notifications to you, unless you tell us your
								preference is to receive only email or only text. You can change your notification
								preferences at any time.
							</p>
						</li>
						<li>
							<p>
								In order to receive notifications from FAST you are responsible for making sure your
								contact information is accurate and up-to-date.
							</p>
						</li>
						<li>
							<p>
								All communications through FAST must be limited to food access requests. Communication
								about topics other than food requests must not be made through FAST.
							</p>
						</li>
						<li>
							<p>
								Users who are members of entities fulfilling food access requests agree to keep
								information about food access recipients confidential, using it only as needed to
								support fulfillment of requests, quality improvement purposes, and required reporting.
								Non-identifiable information may be included in summary reports.
							</p>
						</li>
						<li>
							<p className="mb-0">
								FAST/Penn Medicine does not assume any responsibility for the condition of the food
								donation or interactions with member organization's staff and volunteers. FAST does not
								have control over the contents of services provided by organizations completing food
								requests and services through FAST platform. FAST does not accept any responsibility for
								them or for any loss or damage that may arise from your use of food services provided by
								organizations in FAST.
							</p>
							<p className="text-danger">
								By accepting the terms and conditions of use, the user agrees to hold Penn Medicine and
								any of its subsidiaries and affiliates harmless from any damages, liability and expenses
								resulting from their usage, access and interactions with participating food providers.
								The user further agrees to hold Penn Medicine and its affiliates harmless from any
								damages, liability and expenses resulting from their consumption of food products
								received from external providers participating in FAST.
							</p>
						</li>
						<li>
							<p>
								All food requests managed through FAST will be available for download by your program
								administrator and the administrator of the food access organization that claimed your
								request, regardless of whether the request was completed or not.
							</p>
						</li>
						<li>
							<p>
								The FAST user posting a request on behalf of the food recipient must obtain consent from
								the recipient to do so. The prompt to confirm or decline verbal consent reads as
								follows:
							</p>
							<blockquote>
								<p>
									Please read the following statement to the recipient: You must obtain the
									recipient's consent to enter their personal information into the FAST platform. The
									FAST application can be used to help connect you with food support services, and
									your personal information will need to be entered into the FAST application in order
									to use it. The FAST application is a secure platform designed to protect the privacy
									of your information. Information that identifies you, like your address, and phone
									number, will be shared only with the organizations that will use it to provide the
									food resources you need as well as the organizations involved in maintaining the
									application. Do you agree to the following statement? “I understand and consent to
									provide my personal information to the FAST platform.”
								</p>
							</blockquote>
						</li>
						<li>
							<p>
								By accessing or using FAST, you agree to comply with these Terms and Conditions and
								accept the FAST app Privacy Policy that is available here [insert link]. FAST reserves
								the right to modify these Terms and Conditions at any time, and without prior notice.
								Users will be notified of any changes. Said changes, when made, will be effective
								immediately, unless otherwise stated.
							</p>
						</li>
					</ul>

					<h5>Discontinuing Use of FAST Access:</h5>
					<p>
						Administrators may un-enroll your organization from FAST at any time. An organization must
						provide notice that it is no longer using FAST services by emailing{' '}
						<a href="mailto:healthequity@uphs.upenn.edu">healthequity@uphs.upenn.edu</a>.
					</p>
					<p>
						If, for any reason, it is determined at our sole discretion that you have violated the FAST
						Terms and Conditions of Use and/or abused the use of this service, we have the right to restrict
						and/or terminate your access to FAST. You will be notified in writing via the email address on
						file if conduct that may result in restriction or termination of your access to FAST has
						occurred. Such conduct may include, but is not limited to, the use of profanity or abusive
						language, sending repetitive or harassing messages, and failing to otherwise abide by the FAST
						Terms and Conditions of Use. If conduct in violation of these Terms and Conditions continues, no
						additional notice will be provided before restriction or termination of your FAST access.
					</p>
					<p className="mb-5">
						The decision to restrict or terminate access will be made at the sole discretion of FAST/Penn
						Medicine.
					</p>

					<h5>If signed electronically</h5>
					<p>
						By clicking “Accept”, I, as the FAST administrator/user, agree to the FAST Terms and Conditions
						of Use and I intend this to be my legally binding signature and the equivalent of my handwritten
						signature.
					</p>
				</Col>
			</Row>
		</Container>
	);
};
