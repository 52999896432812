import React, { FC } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { AccountModel } from '@/core/models';

const useStyles = createUseStyles({
	contactModal: {
		width: '90%',
		maxWidth: 412,
		margin: '0 auto',
	},
});

interface ContactModalProps extends ModalProps {
	account: AccountModel;
	phoneNumber?: string;
	phoneNumberSmsCapable?: boolean;
}

export const ContactModal: FC<ContactModalProps> = ({ account, phoneNumber, phoneNumberSmsCapable, ...props }) => {
	const classes = useStyles();

	return (
		<Modal dialogClassName={classes.contactModal} centered {...props}>
			<Modal.Header>
				<Modal.Title>Contact Request Poster</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5 className="mb-0">Name</h5>
				<p className="mb-4">{account.name}</p>

				<h5 className="mb-0">Organization Name</h5>
				<p className="mb-4">{account.institution.name}</p>

				<h5 className="mb-0">Email</h5>
				<p className={phoneNumber ? 'mb-4' : 'mb-0'}>
					<a href={`mailto:${account.emailAddress}`}>{account.emailAddress}</a>
				</p>

				{phoneNumber && (
					<>
						<h5 className="mb-0">Phone Number</h5>
						<p className="mb-0">{phoneNumber}</p>
						<p className="mb-0">
							{phoneNumberSmsCapable ? '(receives calls & texts)' : '(receives calls only)'}
						</p>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end align-items-center">
					<Button onClick={props.onHide}>Close</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
