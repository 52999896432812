import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

import { useHandleError, useQuery } from '@/hooks';

import { AsyncPage, LoaderButton } from '@/components';

import { InvitationModel } from '@/core/models';
import { accountService } from '@/core/services';

import { mediaQueries, theme } from '@/jss';

import { ReactComponent as FastLogo } from '@/assets/fast-logo.svg';

const useStyles = createUseStyles({
	outer: {
		padding: 80,
		backgroundColor: theme.colors.grayOne,
		[mediaQueries.md]: {
			padding: 50,
		},
	},
});

export const SignUp: FC = () => {
	const handleError = useHandleError();
	const classes = useStyles();
	const history = useHistory();
	const query = useQuery();
	const invitationId = query.get('invitationId');

	const [invitation, setInvitation] = useState<InvitationModel>();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [accept, setAccept] = useState(false);
	const [isCreatingAccount, setIsCreatingAccount] = useState(false);

	const fetchData = useCallback(async () => {
		if (!invitationId) {
			history.replace('/sign-in');
			return;
		}

		const response = await accountService.getAccountInviteById(invitationId).fetch();

		if (response.invitation.invitationExpired) {
			history.replace('/invitation-expired');
			return;
		}

		if (response.invitation.invitationAccepted) {
			history.replace('/');
			return;
		}

		setInvitation(response.invitation);
		setEmail(response.invitation.recipient);
	}, [history, invitationId]);

	async function handleFormSubmit(event: React.FormEvent) {
		event.preventDefault();
		setIsCreatingAccount(true);

		try {
			if (!invitation) {
				throw new Error('Invitation not found.');
			}

			await accountService
				.createAccount({
					invitationId: invitation.invitationId,
					name,
					password,
					confirmPassword,
				})
				.fetch();

			history.push('/sign-in?accountCreated=true');
		} catch (error) {
			handleError(error);
		}

		setIsCreatingAccount(false);
	}

	return (
		<>
			<AsyncPage fetchData={fetchData}>
				<Container className="pt-20">
					<Row>
						<Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
							<div className={classes.outer}>
								<div className="mb-2 d-flex justify-content-center">
									<FastLogo />
								</div>
								<h4 className="mb-8 text-center text-blue-three">
									<em>Food Access Support Technology</em>
								</h4>
								<h5 className="mb-2">Welcome!</h5>
								<hr className="mb-2" />
								<p className="mb-6">
									To create your account, we just need a little information about you.
								</p>
								<Form onSubmit={handleFormSubmit}>
									<Form.Group className="mb-3">
										<Form.Label>Your name</Form.Label>
										<Form.Control
											type="text"
											value={name}
											onChange={(event) => {
												setName(event.currentTarget.value);
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Your email</Form.Label>
										<Form.Control
											type="email"
											value={email}
											onChange={(event) => {
												setEmail(event.currentTarget.value);
											}}
											disabled
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="mb-0">Create a password</Form.Label>
										<p className="mb-1">At least 8 characters long</p>
										<Form.Control
											type="password"
											value={password}
											onChange={(event) => {
												setPassword(event.currentTarget.value);
											}}
										/>
									</Form.Group>
									<Form.Group className="mb-6">
										<Form.Label>Confirm your password</Form.Label>
										<Form.Control
											type="password"
											value={confirmPassword}
											onChange={(event) => {
												setConfirmPassword(event.currentTarget.value);
											}}
										/>
									</Form.Group>
									<p className="mb-2">
										By clicking “Accept”, I, as the FAST administrator/user, agree to the FAST{' '}
										<a href="/privacy-policy" target="_blank" rel="noreferrer noopener">
											Privacy Policy
										</a>{' '}
										and{' '}
										<a href="/terms-of-use" target="_blank" rel="noreferrer noopener">
											Terms and Conditions of Use
										</a>
										. I intend this to be my legally binding signature and the equivalent of my
										handwritten signature.
									</p>

									<Form.Check
										className="mb-6"
										id="pp-and-tou-accept"
										label="Accept"
										checked={accept}
										onChange={({ currentTarget }) => {
											setAccept(currentTarget.checked);
										}}
									/>
									<div className="text-center">
										<LoaderButton type="submit" isLoading={isCreatingAccount} disabled={!accept}>
											Create my account
										</LoaderButton>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</AsyncPage>
		</>
	);
};
