export enum POSTED_BY {
	ME = 'ME',
	MY_ORGANIZATION = 'MY_ORGANIZATION',
	OTHER = 'OTHER',
}

export const postedBy = {
	[POSTED_BY.ME]: {
		postedById: POSTED_BY.ME,
		postedByTitle: 'Me',
	},
	[POSTED_BY.MY_ORGANIZATION]: {
		postedById: POSTED_BY.MY_ORGANIZATION,
		postedByTitle: 'Anyone at my organization',
	},
	[POSTED_BY.OTHER]: {
		postedById: POSTED_BY.OTHER,
		postedByTitle: 'Another organization',
	},
};
