import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';

const useTableCellStyles = createUseStyles({
	tableCell: (props: any) => ({
		padding: 0,
		backgroundColor: 'inherit',
		width: props.width ? props.width : 'auto',
		'&.fixed-column': {
			zIndex: 1,
			position: 'sticky',
			left: props.fixedOffset ? props.fixedOffset : 0,
		},
		'&:first-child': {
			paddingLeft: 8,
		},
		'&:last-child': {
			paddingRight: 8,
		},
	}),
	tableCellContent: (props: any) => ({
		overflow: 'hidden',
		padding: '13px 8px',
		width: typeof props.width === 'string' ? 'auto' : props.width,
	}),
});

interface TableCellProps {
	width?: number | string;
	fixed?: boolean;
	fixedOffset?: number;
	className?: string;
}

export const TableCell: FC<TableCellProps> = React.memo(({ width, fixed, fixedOffset, className, children }) => {
	const classes = useTableCellStyles({
		width,
		fixedOffset,
	});

	return (
		<td
			className={classNames({
				[classes.tableCell]: true,
				'fixed-column': fixed,
			})}
		>
			<div className={classNames(classes.tableCellContent, className)}>{children}</div>
		</td>
	);
});

TableCell.displayName = 'TableCell';
