import Color from 'color';
import { theme } from '@/jss';

export const badge = {
	'.fast-badge': {
		padding: '2px 8px',
		display: 'inline-block',
		color: theme.colors.graySix,
		fontSize: theme.fonts.sizes[14],
		lineHeight: theme.fonts.lineHeights[19],
		fontFamily: theme.fonts.robotoCondensed.fontFamily,
		fontWeight: theme.fonts.robotoCondensed.weights.regular,
		border: `1px solid ${Color(theme.colors.grayFive).lighten(0.65).hex()}`,
		'&-pill': {
			borderRadius: 500,
		},
		'&-secondary': {
			color: theme.colors.blueThree,
			borderColor: theme.colors.blueThree,
			fontWeight: theme.fonts.robotoCondensed.weights.bold,
		},
		'&.inline': {
			margin: '2.5px 5px 2.5px 0',
		},
	},
};
