import React, { FC } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
	repostInstructionsModal: {
		width: '90%',
		maxWidth: 842,
		margin: '0 auto',
		'& .modal-content': {
			padding: 30,
		},
	},
});

export const RepostInstructionsModal: FC<ModalProps> = ({ ...props }) => {
	const classes = useStyles();

	return (
		<Modal dialogClassName={classes.repostInstructionsModal} centered {...props}>
			<Modal.Header>
				<div className="mb-2 d-flex justify-content-between">
					<Modal.Title>How to fulfull a request and repost it for delivery</Modal.Title>
					<Button variant="link" onClick={props.onHide}>
						Close
					</Button>
				</div>
			</Modal.Header>
			<Modal.Body>
				<h5 className="mb-5">1. Claim the request and prepare the food.</h5>
				<h5 className="mb-5">
					2. When the food is ready, set the status to “Fulfilled Pending Delivery,” then click the button
					“Repost for Delivery Only.”
				</h5>
				<h5 className="mb-5">3. Add the information needed for delivery.</h5>
				<h5 className="mb-2">4. Review and post the request.</h5>
				<p className="mb-5">You can also edit any other details before posting.</p>
				<h5 className="mb-5">
					5. Post the request and send a notification to the suggested delivery partners.{' '}
				</h5>
			</Modal.Body>
		</Modal>
	);
};
