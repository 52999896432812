import { cloneDeep } from 'lodash';
import React, { FC, createContext, useState, useEffect, useCallback } from 'react';
import {
	DIETARY_RESTRICTIONS,
	HEALTH_BENEFITS,
	HEALTH_CONDITIONS,
	FOOD_REQUEST_TYPE,
	TRANSPORTATION,
	UNPREPARED_FOOD,
	YES_NO_UNKNOWN,
	SECONDARY_NUTRITION_RISKS,
	NOTIFICATION_METHODS,
	PROVIDED_BENEFITS_INFO,
} from '@/core/models';
import { useAccount } from '@/hooks';

export interface RequestFormSchema {
	showBreadcrumbs: boolean;

	/* Basics */
	recipientConsent: boolean;
	requestType: FOOD_REQUEST_TYPE | undefined;
	unpreparedFoodType: UNPREPARED_FOOD | undefined;
	phoneNumber: string;
	phoneNumberCanReceiveMessages: boolean;
	recipientsHouseholdSize: string;
	foodSupportDuration: string;
	canStore: YES_NO_UNKNOWN | undefined;
	hasKitchen: YES_NO_UNKNOWN | undefined;
	readyToEat: YES_NO_UNKNOWN | undefined;
	isUrgent: YES_NO_UNKNOWN | undefined;
	recipientHasDietaryRestrictions: boolean | undefined;
	dietaryRestrictions: DIETARY_RESTRICTIONS[];
	allergy: string;
	otherDiet: string;
	hasFlexibleDietaryRestrictions: boolean | undefined;
	dietaryRestrictionFlexibilityDescription: string;

	/* Pickup / Delivery */
	transportationMethod: TRANSPORTATION | undefined;
	pickupLocationName: string;
	pickupLocationPhoneNumber: string;
	pickupLocationPhoneNumberCanReceiveMessages: boolean;
	pickupLocationAddressOne: string;
	pickupLocationAddressTwo: string;
	pickupLocationCity: string;
	pickupLocationState: string;
	pickupLocationZipCode: string;
	pickupLocationWindow: string;
	pickupLocationNotes: string;
	recipientsName: string;
	recipientsPhoneNumber: string;
	recipientsPhoneNumberCanReceiveMessages: boolean;
	recipientsPreferredSpokenLanguageIsEnglish: boolean;
	recipientsPreferredSpokenLanguage: string;
	recipientsAddressOne: string;
	recipientsAddressTwo: string;
	recipientsCity: string;
	recipientsState: string;
	recipientsZipCode: string;
	additionalNotes: string;

	/* Eligibility Assessment */
	healthConditions: HEALTH_CONDITIONS[];
	otherHealthCondition: string;
	secondaryNutritionRisks: SECONDARY_NUTRITION_RISKS[];
	receivingBenefits: YES_NO_UNKNOWN | undefined;
	healthBenefits: HEALTH_BENEFITS[];
	otherHealthBenefit: string;
	providedBenefitsInfoTypeId: PROVIDED_BENEFITS_INFO | undefined;
	providedBenefitsInfoOtherDescription: string;
	under21: YES_NO_UNKNOWN | undefined;
	over60: YES_NO_UNKNOWN | undefined;
	recipientsAnnualIncome: string;
	hasHealthInsurance: YES_NO_UNKNOWN | undefined;
	healthInsuranceProvider: string;
	healthSystems: string[];
	otherHealthSystem: string;
	requestConfirmed: boolean;
}

interface ReqeustFormContextConfig {
	formValues: RequestFormSchema;
	setFormValue<K extends keyof RequestFormSchema>(key: K, value: RequestFormSchema[K]): void;
	setMultipleFormValues(values: Partial<RequestFormSchema>): void;
	basicsPageIsEnabled: boolean;
	setBasicsPageIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	pickupDeliveryPageIsEnabled: boolean;
	setPickupDeliveryPageIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	eligibilityAssessmentPageIsEnabled: boolean;
	setEligibilityAssessmentPageIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	reviewPageIsEnabled: boolean;
	setReviewPageIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	sendPageIsEnabled: boolean;
	setSendPageIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RequestFormContext = createContext({} as ReqeustFormContextConfig);

export const RequestFormProvider: FC = (props) => {
	const { account } = useAccount();

	/* ---------------------------------------------------------- */
	/* Default form values */
	/* ---------------------------------------------------------- */
	const [formValues, setFormValues] = useState<RequestFormSchema>({
		showBreadcrumbs: true,

		/* Basics */
		recipientConsent: false,
		requestType: undefined,
		unpreparedFoodType: undefined,
		phoneNumber: account?.phoneNumberDescription || '',
		phoneNumberCanReceiveMessages:
			account?.notificationTypeId === NOTIFICATION_METHODS.BOTH ||
			account?.notificationTypeId === NOTIFICATION_METHODS.TEXT ||
			false,
		recipientsHouseholdSize: '',
		foodSupportDuration: '',
		canStore: undefined,
		hasKitchen: undefined,
		readyToEat: undefined,
		isUrgent: undefined,
		recipientHasDietaryRestrictions: undefined,
		dietaryRestrictions: [],
		allergy: '',
		otherDiet: '',
		hasFlexibleDietaryRestrictions: undefined,
		dietaryRestrictionFlexibilityDescription: '',

		/* Pickup / Delivery */
		transportationMethod: undefined,
		pickupLocationName: '',
		pickupLocationPhoneNumber: '',
		pickupLocationPhoneNumberCanReceiveMessages: false,
		pickupLocationAddressOne: '',
		pickupLocationAddressTwo: '',
		pickupLocationCity: '',
		pickupLocationState: '',
		pickupLocationZipCode: '',
		pickupLocationWindow: '',
		pickupLocationNotes: '',
		recipientsName: '',
		recipientsPhoneNumber: '',
		recipientsPhoneNumberCanReceiveMessages: false,
		recipientsPreferredSpokenLanguageIsEnglish: true,
		recipientsPreferredSpokenLanguage: '',
		recipientsAddressOne: '',
		recipientsAddressTwo: '',
		recipientsCity: '',
		recipientsState: '',
		recipientsZipCode: '',
		additionalNotes: '',

		/* Eligibility Assessment */
		healthConditions: [],
		otherHealthCondition: '',
		secondaryNutritionRisks: [],
		receivingBenefits: undefined,
		healthBenefits: [],
		otherHealthBenefit: '',
		providedBenefitsInfoTypeId: undefined,
		providedBenefitsInfoOtherDescription: '',
		under21: undefined,
		over60: undefined,
		recipientsAnnualIncome: '',
		hasHealthInsurance: undefined,
		healthInsuranceProvider: '',
		healthSystems: [],
		otherHealthSystem: '',
		requestConfirmed: false,
	});

	const setFormValue = useCallback(
		<K extends keyof RequestFormSchema>(key: K, value: RequestFormSchema[K]) => {
			const valuesClone = cloneDeep(formValues);
			valuesClone[key] = value;
			setFormValues(valuesClone);
		},
		[formValues, setFormValues]
	);

	const setMultipleFormValues = useCallback(
		(values: Partial<RequestFormSchema>) => {
			setFormValues({ ...formValues, ...values });
		},
		[formValues, setFormValues]
	);

	/* ---------------------------------------------------------- */
	/* Validation */
	/* ---------------------------------------------------------- */
	const [basicsPageIsEnabled, setBasicsPageIsEnabled] = useState(true);
	const [pickupDeliveryPageIsEnabled, setPickupDeliveryPageIsEnabled] = useState(false);
	const [eligibilityAssessmentPageIsEnabled, setEligibilityAssessmentPageIsEnabled] = useState(false);
	const [reviewPageIsEnabled, setReviewPageIsEnabled] = useState(false);
	const [sendPageIsEnabled, setSendPageIsEnabled] = useState(false);

	const checkRequiredFields = useCallback(
		(requiredFields: (keyof RequestFormSchema)[]) => {
			return requiredFields
				.map((fieldName) => {
					if (formValues[fieldName] !== undefined && formValues[fieldName] !== '') {
						return true;
					} else {
						return false;
					}
				})
				.every((value) => value === true);
		},
		[formValues]
	);

	const enablePickupDeliveryPage = useCallback(() => {
		const requestTypeIsUnpreparedFood = formValues.requestType === FOOD_REQUEST_TYPE.UNPREPARED_FOOD;
		const requestTypeIsNotJustNeedsDelivery = formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY;
		const hasDietaryRestrictions = formValues.recipientHasDietaryRestrictions === true;
		const foodAlleryIsChecked =
			formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY &&
			formValues.dietaryRestrictions.includes(DIETARY_RESTRICTIONS.FOOD_ALLERGY);
		const otherDietIsChecked =
			formValues.requestType !== FOOD_REQUEST_TYPE.DELIVERY_ONLY &&
			formValues.dietaryRestrictions.includes(DIETARY_RESTRICTIONS.OTHER_DIET);
		const hasFlexibleDietaryRestrictionsIsChecked = formValues.hasFlexibleDietaryRestrictions === true;

		const requirementsMet = checkRequiredFields([
			'recipientConsent',
			'requestType',
			'phoneNumber',
			'recipientsHouseholdSize',
			'foodSupportDuration',
			'canStore',
			'isUrgent',
			...(requestTypeIsUnpreparedFood
				? (['unpreparedFoodType', 'readyToEat'] as (keyof RequestFormSchema)[])
				: []),
			...(requestTypeIsNotJustNeedsDelivery
				? (['hasKitchen', 'recipientHasDietaryRestrictions'] as (keyof RequestFormSchema)[])
				: []),
			...(foodAlleryIsChecked ? ['allergy' as keyof RequestFormSchema] : []),
			...(otherDietIsChecked ? ['otherDiet' as keyof RequestFormSchema] : []),
			...(hasDietaryRestrictions ? ['hasFlexibleDietaryRestrictions' as keyof RequestFormSchema] : []),
			...(hasFlexibleDietaryRestrictionsIsChecked
				? ['dietaryRestrictionFlexibilityDescription' as keyof RequestFormSchema]
				: []),
		]);

		if (basicsPageIsEnabled && requirementsMet) {
			setPickupDeliveryPageIsEnabled(true);
		} else {
			setPickupDeliveryPageIsEnabled(false);
		}
	}, [
		basicsPageIsEnabled,
		checkRequiredFields,
		formValues.dietaryRestrictions,
		formValues.requestType,
		formValues.hasFlexibleDietaryRestrictions,
		formValues.recipientHasDietaryRestrictions,
	]);

	const enableEligibilityAssessmentAndReviewPage = useCallback(() => {
		const requestTypeIsJustNeedsDelivery = formValues.requestType === FOOD_REQUEST_TYPE.DELIVERY_ONLY;
		const transportationMethodIsDelivery = formValues.transportationMethod === TRANSPORTATION.DELIVERY;

		const requirementsMet = checkRequiredFields([
			'transportationMethod',
			...(requestTypeIsJustNeedsDelivery
				? ([
						'pickupLocationName',
						'pickupLocationPhoneNumber',
						'pickupLocationAddressOne',
						'pickupLocationCity',
						'pickupLocationState',
						'pickupLocationZipCode',
				  ] as (keyof RequestFormSchema)[])
				: []),
			'recipientsName',
			'recipientsPhoneNumber',
			...(formValues.recipientsPreferredSpokenLanguageIsEnglish === false
				? ['recipientsPreferredSpokenLanguage' as keyof RequestFormSchema]
				: []),
			...(transportationMethodIsDelivery
				? (['recipientsAddressOne', 'recipientsCity', 'recipientsState'] as (keyof RequestFormSchema)[])
				: []),
			'recipientsZipCode',
		]);

		if (basicsPageIsEnabled && pickupDeliveryPageIsEnabled && requirementsMet) {
			setEligibilityAssessmentPageIsEnabled(true);
		} else {
			setEligibilityAssessmentPageIsEnabled(false);
		}
	}, [
		basicsPageIsEnabled,
		pickupDeliveryPageIsEnabled,
		checkRequiredFields,
		formValues.requestType,
		formValues.recipientsPreferredSpokenLanguageIsEnglish,
		formValues.transportationMethod,
	]);

	const enableReviewPage = useCallback(() => {
		const otherHealthConditionIsChecked = formValues.healthConditions.includes(HEALTH_CONDITIONS.OTHER);
		const otherHealthBenefitIsChecked =
			formValues.receivingBenefits === YES_NO_UNKNOWN.YES &&
			formValues.healthBenefits.includes(HEALTH_BENEFITS.OTHER);
		const otherHealthSystemIsChecked = formValues.healthSystems.includes('OTHER');

		const requirementsMet = checkRequiredFields([
			...(otherHealthConditionIsChecked ? ['otherHealthCondition' as keyof RequestFormSchema] : []),
			...(otherHealthBenefitIsChecked ? ['otherHealthBenefit' as keyof RequestFormSchema] : []),
			...(otherHealthSystemIsChecked ? ['otherHealthSystem' as keyof RequestFormSchema] : []),
		]);

		if (
			basicsPageIsEnabled &&
			pickupDeliveryPageIsEnabled &&
			eligibilityAssessmentPageIsEnabled &&
			requirementsMet
		) {
			setReviewPageIsEnabled(true);
		} else {
			setReviewPageIsEnabled(false);
		}
	}, [
		basicsPageIsEnabled,
		pickupDeliveryPageIsEnabled,
		eligibilityAssessmentPageIsEnabled,
		checkRequiredFields,
		formValues.healthBenefits,
		formValues.healthConditions,
		formValues.healthSystems,
		formValues.receivingBenefits,
	]);

	const enableSendPage = useCallback(() => {
		if (formValues.requestConfirmed) {
			setSendPageIsEnabled(true);
		}
	}, [formValues.requestConfirmed]);

	/* ---------------------------------------------------------- */
	/* When formValues change, check for page validations */
	/* ---------------------------------------------------------- */
	useEffect(() => {
		enablePickupDeliveryPage();
		enableEligibilityAssessmentAndReviewPage();
		enableReviewPage();
		enableSendPage();
	}, [
		enableEligibilityAssessmentAndReviewPage,
		enablePickupDeliveryPage,
		enableReviewPage,
		enableSendPage,
		formValues,
	]);

	return (
		<RequestFormContext.Provider
			value={{
				formValues,
				setFormValue,
				setMultipleFormValues,
				basicsPageIsEnabled,
				setBasicsPageIsEnabled,
				pickupDeliveryPageIsEnabled,
				setPickupDeliveryPageIsEnabled,
				eligibilityAssessmentPageIsEnabled,
				setEligibilityAssessmentPageIsEnabled,
				reviewPageIsEnabled,
				setReviewPageIsEnabled,
				sendPageIsEnabled,
				setSendPageIsEnabled,
			}}
		>
			{props.children}
		</RequestFormContext.Provider>
	);
};
