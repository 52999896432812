export enum DIETARY_RESTRICTIONS {
	FOOD_ALLERGY = 'FOOD_ALLERGY',
	LACTOSE_INTOLERANCE = 'LACTOSE_INTOLERANCE',
	GLUTEN_FREE_DIET = 'GLUTEN_FREE_DIET',
	DIABETIC_FRIENDLY_DIET = 'DIABETIC_FRIENDLY_DIET',
	NEUTROPENIC_DIET = 'NEUTROPENIC_DIET',
	RENAL_DIET = 'RENAL_DIET',
	LOW_SODIUM_DIET = 'LOW_SODIUM_DIET',
	KOSHER_DIET = 'KOSHER_DIET',
	HALAL_DIET = 'HALAL_DIET',
	VEGETARIAN_DIET = 'VEGETARIAN_DIET',
	VEGAN_DIET = 'VEGAN_DIET',
	OTHER_DIET = 'OTHER_DIET',
}

export const dietaryRestrictions = {
	[DIETARY_RESTRICTIONS.FOOD_ALLERGY]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.FOOD_ALLERGY,
		dietaryRestrictionTitle: 'Food allergy',
	},
	[DIETARY_RESTRICTIONS.LACTOSE_INTOLERANCE]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.LACTOSE_INTOLERANCE,
		dietaryRestrictionTitle: 'Lactose intolerance',
	},
	[DIETARY_RESTRICTIONS.GLUTEN_FREE_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.GLUTEN_FREE_DIET,
		dietaryRestrictionTitle: 'Gluten-free diet',
	},
	[DIETARY_RESTRICTIONS.DIABETIC_FRIENDLY_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.DIABETIC_FRIENDLY_DIET,
		dietaryRestrictionTitle: 'Diabetic-friendly diet',
	},
	[DIETARY_RESTRICTIONS.NEUTROPENIC_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.NEUTROPENIC_DIET,
		dietaryRestrictionTitle: 'Neutropenic diet',
	},
	[DIETARY_RESTRICTIONS.RENAL_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.RENAL_DIET,
		dietaryRestrictionTitle: 'Renal diet',
	},
	[DIETARY_RESTRICTIONS.LOW_SODIUM_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.LOW_SODIUM_DIET,
		dietaryRestrictionTitle: 'Low sodium diet',
	},
	[DIETARY_RESTRICTIONS.KOSHER_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.KOSHER_DIET,
		dietaryRestrictionTitle: 'Kosher diet',
	},
	[DIETARY_RESTRICTIONS.HALAL_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.HALAL_DIET,
		dietaryRestrictionTitle: 'Halal diet',
	},
	[DIETARY_RESTRICTIONS.VEGETARIAN_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.VEGETARIAN_DIET,
		dietaryRestrictionTitle: 'Vegetarian diet',
	},
	[DIETARY_RESTRICTIONS.VEGAN_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.VEGAN_DIET,
		dietaryRestrictionTitle: 'Vegan diet',
	},
	[DIETARY_RESTRICTIONS.OTHER_DIET]: {
		dietaryRestrictionId: DIETARY_RESTRICTIONS.OTHER_DIET,
		dietaryRestrictionTitle: 'Other diet',
	},
};
