import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorConfig } from '@/core/http';
import { useAlert } from '@/hooks';

function isErrorConfig(error: ErrorConfig | unknown): error is ErrorConfig {
	return (error as ErrorConfig).code !== undefined;
}

export function useHandleError() {
	const history = useHistory();
	const { showAlert } = useAlert();

	const handleError = useCallback(
		(error: ErrorConfig | unknown) => {
			if (!isErrorConfig(error)) {
				return window.alert(error);
			}

			if (error.code === 'AUTHENTICATION_REQUIRED') {
				return history.replace('/sign-in');
			}

			showAlert({
				variant: 'danger',
				children: () => {
					return (
						<p className="mb-0 text-white">
							<strong>Error:</strong> {error.message}
						</p>
					);
				},
				isDismissible: false,
			});
		},
		[history, showAlert]
	);

	return handleError;
}
